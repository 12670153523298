import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { update_breadcrumb } from "../../redux/action";
import { FaGlobe, FaUser, FaUserFriends } from "react-icons/fa";
import instance from "../../service/axiosInstane";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";


const AdminHome = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState<any>({});

    useEffect(() => {
        const breadcrumb = [{ path: "/admin_home", name: "Dashboard" }];
        dispatch(update_breadcrumb(breadcrumb));
        getDetails();
    }, []);

    const getDetails = async () => {
        setIsLoading(true);
        await instance
            .get(`/Admin/DashBord`)
            .then((response) => {
                if (response.status === 200) {
                    setState(response.data);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("error", err);
                setIsLoading(false);
            });
    };
    const cardsData = [
        {
            lable: "Guide",
            value: state.totalguide,
            icon: <FaUserFriends size={25} />,
        },

        {
            lable: "PHD Students",
            value: state.totalguide,
            icon: <FaUser size={25} />,
        },
        {
            lable: "PDF Students",
            value: state.totalpdfStudent,
            icon: <FaGlobe size={25} />,
        },
        {
            lable: "DSC Students",
            value: state.totaldscStudent,
            icon: <FaGlobe size={25} />,
        },
    ];

    return (
        <div className="bg-white shadow-lg p-4">
            {isLoading ? <ActivityIndicator /> : null}
            <div className="sm:flex items-center justify-between">
                <div className="font-semibold text-[24px] text-sky-700 uppercase">
                    Welcome Admin,
                </div>
            </div>

            <div className="flex items-center space-x-4 ">
                {cardsData.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={` flex bg-white items-center w-full space-x-4 rounded-sm justify-between p-1 shadow-md`}
                        >
                            <div className="p-1">
                                <div className="text-sm text-blue-900">{item.lable}</div>
                                <div className="text-2xl">{item.value}</div>
                            </div>
                            {item.icon}
                        </div>
                    );
                })}
            </div>

            <div className="mt-4">
                <div className="bg-[#02497f] p-2 font-semibold text-white text-lg">
                    Notification
                </div>
                <div>
                    <li className="font-semibold my-1">
                        International Conference on Pervasive Computing and Social
                        Networking on June 20
                    </li>
                    <li className="font-semibold my-1">
                        International Yoga Day on June 23
                    </li>
                    <li className="font-semibold my-1">
                        Recruit popular speakers or host TED Talks on July 03
                    </li>
                    <li className="font-semibold my-1">
                        Throw an event to kick off a mentorship program on July 13
                    </li>
                    <li className="font-semibold my-1">
                        Help students relax with free de-stressing activities on Aug 09
                    </li>
                </div>
            </div>
        </div>
    );
};

export default AdminHome;
