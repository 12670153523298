import React from "react";

const Footer = () => {
  return (
    <div className=" h-10 w-full bottom-0 flex items-center justify-end text-sm text-[gray] pr-5">
      Copyright © 2020 Srinivas University
    </div>
  );
};

export default Footer;
