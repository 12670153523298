import { error } from "console";
import React from "react";

interface InputProps {
    lable: string;
    placeholder?: string;
    value?: string;
    type?: string;
    onChange?: any;
    name?: string;
    error?: string;
    width?: any
    height?: any
}

const TextArea = (props: InputProps) => {
    const { lable, placeholder, value, type, onChange, name, error, width, height } = props;
    return (
        <div className="my-1">
            <div className="text-sky-900  mb-1 text-sm font-semibold">{lable}</div>
            <textarea
                required
                className={`${width ? width : 'w-[300px]'} ${height ? height : 'h-16'} focus:border-[#83b8f5] outline-none border pl-2 text-sm p-1`}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                name={name}

            />
            <div className="text-[red] text-sm">{error}</div>
        </div>
    );
};

export default TextArea;
