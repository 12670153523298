import React, { useEffect, useState } from "react";
import "../ActivityIndicator/activityIndicator.css";


const SweetAlert = (props: any) => {
    const { message, isOpen, onClose, status } = props
    const [autoHide, setAutoHide] = useState(isOpen);

    useEffect(() => {
        setTimeout(() => {
            setAutoHide(false)
        }, 4000);
    }, [])

    return (
        <>
            {autoHide ? (
                <div className="backdrop flex items-center justify-center p-4">
                    <div className="bg-white w-[400px]  rounded-sm shadow-lg m-4 border-2 p-2">
                        <div className="flex flex-col items-center justify-center h-[100px] shadow-lg border  border-sky-400">
                            <div className="text-[20px] font-semibold text-[#02497f] ">
                                {message}
                            </div>

                            <div className="border w-fit cursor-pointer text-sm mt-2 bg-blue-500 text-white  rounded-sm border-[blue] px-2 py-1 uppercase font-semibold" onClick={onClose}>Close</div>

                        </div>


                    </div>
                </div>
            ) : null}
        </>
    );
};

export default SweetAlert;
