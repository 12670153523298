export const colors = { primary: "bg-[#02497f]" };

export const snackBarColors = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};


export const baseURL = "http://api.suresearchsoftware.com/api"

export const sweetAlertStatus = {
  SUCCESS: "success",
  ERROR: "error",
}