import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import instance from "../../service/axiosInstane";
import { useDispatch, useSelector } from "react-redux";
import { updateSnackbar, update_breadcrumb } from "../../redux/action";
import { Capitalization } from "../../config/helper";
import { snackBarColors } from "../../config/constants";

const PublicationCreate = () => {
    const { tab } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userinformation = useSelector((state: any) => state.userInfo);
    const userRole = useSelector((state: any) => state.userRole);
    const [formError, setFormError] = useState<any>({});
    const [journalformError, setJournalFormError] = useState<any>({});
    const [conferenceformError, setConferenceFormError] = useState<any>({});
    const [copyrightformError, setCopyrightFormError] = useState<any>({});
    const [patentformError, setPatentFormError] = useState<any>({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiEndPoint, setApiEndPoint] = useState("/Guide/Books");
    const [books, setBooks] = useState<any>({
        bookid: "",
        teacherName: "",
        bookTitle: "",
        chapterTitle: "",
        publicationYear: "",
        isbNumber: "",
        publisherName: "",
        createdName: "",
        role: userRole,
        userName: userinformation.userName,
        createdBy: userinformation.userId,
        createdOn: new Date(),
    });

    const [journal, setjournal] = useState<any>({
        journalid: "",
        departmentTeacher: "",
        paperTitle: "",
        authorsName: "",
        doi: "",
        publicationYear: null,
        issnNumber: null,
        nameOfJournal: "",
        role: userRole,
        userName: userinformation.userName,
        createdBy: userinformation.userId,
        createdOn: new Date(),
    });

    const [conference, setConference] = useState<any>({
        conferenceid: null,
        teacherName: "",
        titleOfBookPublished: "",
        titlePaper: "",
        proceedingsConference: "",
        conferenceName: "",
        nationalOrInternational: "",
        publicationYear: null,
        isbnOrissnNumber: null,
        affiliatingInstitute: "",
        publisherName: "",
        role: userRole,
        userName: userinformation.userName,
        createdBy: userinformation.userId,
        createdOn: new Date(),
    });

    const [copyright, setCopyright] = useState<any>({
        copyrightid: null,
        copyrightApplication: "",
        status: "",
        inventor: "",
        copyrightTitle: "",
        publicationYear: null,
        role: userRole,
        userName: userinformation.userName,
        createdBy: userinformation.userId,
        createdOn: new Date(),
    });

    const [patent, setPatent] = useState<any>({
        patentid: null,
        patentApplication: "",
        status: "",
        inventor: "",
        patentTitle: "",
        applicantName: "",
        patentFiledDate: "",
        patentPublishedDate: "",
        patentPublicationNumber: null,
        assigneeName: "",
        publicationYear: null,
        role: userRole,
        userName: userinformation.userName,
        createdBy: userinformation.userId,
        createdOn: new Date(),
    });
    useEffect(() => {
        const breadcrumb = [
            { path: `/publication/${tab}`, name: "Publication Management" },
            { path: `/publication_create/${tab}`, name: "Create Publication" },
        ];
        dispatch(update_breadcrumb(breadcrumb));
        if (tab === "Books") {
            setApiEndPoint("/Guide/Books");
        }
        if (tab === "Journal") {
            setApiEndPoint("/Guide/Journal");
        }
        if (tab === "Conference") {
            setApiEndPoint("/Guide/Conference");
        }
        if (tab === "Copyright") {
            setApiEndPoint("/Guide/copyright");
        }
        if (tab === "Patent") {
            setApiEndPoint("/Guide/Patent");
        }
    }, []);

    const handleBookChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setBooks({ ...books, [e.target.name]: e.target.value });
        if (!e.target.value) {
            setFormError({
                ...formError,
                [e.target.name]: `${Capitalization(e.target.name)} required!`,
            });
        } else {
            setFormError({ ...formError, [e.target.name]: undefined });
        }
    };

    const handleJournalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setjournal({ ...journal, [e.target.name]: e.target.value });
        if (!e.target.value) {
            setJournalFormError({
                ...journalformError,
                [e.target.name]: `${Capitalization(e.target.name)} required!`,
            });
        } else {
            setJournalFormError({ ...journalformError, [e.target.name]: undefined });
        }
    };

    const handleConferenceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setConference({ ...conference, [e.target.name]: e.target.value });
        if (!e.target.value) {
            setConferenceFormError({
                ...conferenceformError,
                [e.target.name]: `${Capitalization(e.target.name)} required!`,
            });
        } else {
            setConferenceFormError({
                ...conferenceformError,
                [e.target.name]: undefined,
            });
        }
    };

    const handleCopyrightChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setCopyright({ ...copyright, [e.target.name]: e.target.value });
        if (!e.target.value) {
            setCopyrightFormError({
                ...copyrightformError,
                [e.target.name]: `${Capitalization(e.target.name)} required!`,
            });
        } else {
            setCopyrightFormError({
                ...copyrightformError,
                [e.target.name]: undefined,
            });
        }
    };

    const handlePatentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setPatent({ ...patent, [e.target.name]: e.target.value });
        if (!e.target.value) {
            setPatentFormError({
                ...patentformError,
                [e.target.name]: `${Capitalization(e.target.name)} required!`,
            });
        } else {
            setPatentFormError({ ...patentformError, [e.target.name]: undefined });
        }
    };

    const validate = () => {
        const error: any = {};

        if (!books.teacherName) {
            error.teacherName = `${Capitalization("teacher name")} required!`;
        }
        if (!books.bookTitle) {
            error.bookTitle = `${Capitalization("book title")} required!`;
        }
        if (!books.chapterTitle) {
            error.chapterTitle = `${Capitalization("chapter title")} required!`;
        }
        if (!books.publicationYear) {
            error.publicationYear = `${Capitalization("publication year")} required!`;
        }
        if (!books.isbNumber) {
            error.isbNumber = `${Capitalization("ISBN number")} required!`;
        }
        if (!books.publisherName) {
            error.publisherName = `${Capitalization("publication number")} required!`;
        }
        if (!books.createdName) {
            error.createdName = `${Capitalization("created name")} required!`;
        }
        return error;
    };
    const validateJournal = () => {
        const error: any = {};

        if (!journal.departmentTeacher) {
            error.departmentTeacher = `${Capitalization(
                "department teacher"
            )} required!`;
        }
        if (!journal.paperTitle) {
            error.paperTitle = `${Capitalization("Paper title")} required!`;
        }
        if (!journal.authorsName) {
            error.authorsName = `${Capitalization("authors name")} required!`;
        }
        if (!journal.doi) {
            error.doi = `${Capitalization("dOI")} required!`;
        }
        if (!journal.publicationYear) {
            error.publicationYear = `${Capitalization("publication year")} required!`;
        }
        if (!journal.issnNumber) {
            error.issnNumber = `${Capitalization("iSSN number")} required!`;
        }
        if (!journal.nameOfJournal) {
            error.nameOfJournal = `${Capitalization("name Of Journal")} required!`;
        }
        return error;
    };
    const validateConference = () => {
        const error: any = {};

        if (!conference.teacherName) {
            error.teacherName = `${Capitalization("teacher Name")} required!`;
        }
        if (!conference.titleOfBookPublished) {
            error.titleOfBookPublished = `${Capitalization(
                "title Of Book Published"
            )} required!`;
        }
        if (!conference.titlePaper) {
            error.titlePaper = `${Capitalization("title Paper")} required!`;
        }
        if (!conference.proceedingsConference) {
            error.proceedingsConference = `${Capitalization(
                "proceedings Conference"
            )} required!`;
        }
        if (!conference.conferenceName) {
            error.conferenceName = `${Capitalization("conference Name")} required!`;
        }
        if (!conference.nationalOrInternational) {
            error.nationalOrInternational = `${Capitalization(
                "national Or International"
            )} required!`;
        }
        if (!conference.publicationYear) {
            error.publicationYear = `${Capitalization("publication Year")} required!`;
        }
        if (!conference.isbnOrissnNumber) {
            error.isbnOrissnNumber = `${Capitalization(
                "ISNB Or ISSN Number"
            )} required!`;
        }
        if (!conference.affiliatingInstitute) {
            error.affiliatingInstitute = `${Capitalization(
                "affiliating Institute"
            )} required!`;
        }
        if (!conference.publisherName) {
            error.publisherName = `${Capitalization("publisher Name")} required!`;
        }
        return error;
    };
    const validateCopyright = () => {
        const error: any = {};

        if (!copyright.copyrightApplication) {
            error.copyrightApplication = `${Capitalization(
                "copyright Application"
            )} required!`;
        }
        if (!copyright.status) {
            error.status = `${Capitalization("status")} required!`;
        }
        if (!copyright.inventor) {
            error.inventor = `${Capitalization("inventor")} required!`;
        }
        if (!copyright.copyrightTitle) {
            error.copyrightTitle = `${Capitalization("copyright Title")} required!`;
        }
        if (!copyright.publicationYear) {
            error.publicationYear = `${Capitalization("publication Year")} required!`;
        }
        return error;
    };

    const validatePatent = () => {
        const error: any = {};

        if (!patent.patentApplication) {
            error.patentApplication = `${Capitalization(
                "patent Application"
            )} required!`;
        }
        if (!patent.status) {
            error.status = `${Capitalization("status")} required!`;
        }
        if (!patent.inventor) {
            error.inventor = `${Capitalization("inventor")} required!`;
        }
        if (!patent.patentTitle) {
            error.patentTitle = `${Capitalization("patent Title")} required!`;
        }
        if (!patent.applicantName) {
            error.applicantName = `${Capitalization("applicant Name")} required!`;
        }
        if (!patent.patentFiledDate) {
            error.patentFiledDate = `${Capitalization(
                "patent Filed Date"
            )} required!`;
        }
        if (!patent.patentPublishedDate) {
            error.patentPublishedDate = `${Capitalization(
                "patent Published Date"
            )} required!`;
        }
        if (!patent.patentPublicationNumber) {
            error.patentPublicationNumber = `${Capitalization(
                "patent Publication Number"
            )} required!`;
        }
        if (!patent.assigneeName) {
            error.assigneeName = `${Capitalization("assignee Name")} required!`;
        }
        if (!patent.publicationYear) {
            error.publicationYear = `${Capitalization("publication Year")} required!`;
        }
        return error;
    };
    const handleSubmit = () => {
        if (tab === "Books") {
            setFormError(validate());
            setIsSubmit(true);
        }
        if (tab === "Journal") {
            setJournalFormError(validateJournal());
            setIsSubmit(true);
        }
        if (tab === "Conference") {
            setConferenceFormError(validateConference());
            setIsSubmit(true);
        }
        if (tab === "Copyright") {
            setCopyrightFormError(validateCopyright());
            setIsSubmit(true);
        }
        if (tab === "Patent") {
            setPatentFormError(validatePatent());
            setIsSubmit(true);
        }
    };

    useEffect(() => {
        if (tab === "Books") {
            if (Object.keys(formError).length === 0 && isSubmit) {
                createPublication(books);
            }
        }
        if (tab === "Journal") {
            if (Object.keys(journalformError).length === 0 && isSubmit) {
                createPublication(journal);
            }
        }
        if (tab === "Conference") {
            if (Object.keys(conferenceformError).length === 0 && isSubmit) {
                createPublication(conference);
            }
        }
        if (tab === "Copyright") {
            if (Object.keys(copyrightformError).length === 0 && isSubmit) {
                createPublication(copyright);
            }
        }
        if (tab === "Patent") {
            if (Object.keys(patentformError).length === 0 && isSubmit) {
                createPublication(patent);
            }
        }
    }, [
        formError,
        journalformError,
        conferenceformError,
        copyrightformError,
        patentformError,
    ]);

    const createPublication = async (obj: any) => {
        setIsLoading(true);
        await instance
            .post(apiEndPoint, obj)
            .then((response) => {
                if (response.status === 200) {
                    const obj = {
                        value: true,
                        message: "Record added successfully",
                        type: snackBarColors.success,
                    }
                    dispatch(updateSnackbar(obj))
                    setIsLoading(false);
                    navigate(`/publication/${tab}`);
                }
            })
            .catch((err) => {
                //do nothing
                const obj = {
                    value: true,
                    message: "Something went wrong!",
                    type: snackBarColors.error,
                }
                dispatch(updateSnackbar(obj))
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading ? <ActivityIndicator /> : null}
            <div className="bg-white shadow-lg p-4">
                <div className="font-semibold text-[24px] text-sky-700">
                    Create {tab}
                </div>
                <div className="bg-[gold] h-1 mt-1"></div>
                {tab === "Books" && (
                    <div className="flex justify-start items-center  ">
                        <div className="sm:flex sm:space-x-6">
                            <div>
                                <Input
                                    lable="Teacher Name"
                                    name="teacherName"
                                    value={books.teacherName}
                                    onChange={(e: any) => handleBookChange(e)}
                                    error={formError?.teacherName}
                                />{" "}
                                <Input
                                    lable="Book Title"
                                    name="bookTitle"
                                    value={books.bookTitle}
                                    onChange={(e: any) => handleBookChange(e)}
                                    error={formError?.bookTitle}
                                />{" "}
                                <Input
                                    lable="Chapter Title"
                                    name="chapterTitle"
                                    value={books.chapterTitle}
                                    onChange={(e: any) => handleBookChange(e)}
                                    error={formError?.chapterTitle}
                                />{" "}
                                <Input
                                    lable="Publication Year"
                                    name="publicationYear"
                                    value={books.publicationYear}
                                    onChange={(e: any) => handleBookChange(e)}
                                    error={formError?.publicationYear}
                                />
                            </div>
                            <div>
                                <Input
                                    lable="ISB Number"
                                    name="isbNumber"
                                    value={books.isbNumber}
                                    onChange={(e: any) => handleBookChange(e)}
                                    error={formError?.isbNumber}
                                />{" "}
                                <Input
                                    lable="Publisher Name"
                                    name="publisherName"
                                    value={books.publisherName}
                                    onChange={(e: any) => handleBookChange(e)}
                                    error={formError?.publisherName}
                                />{" "}
                                <Input
                                    lable="Created Name"
                                    name="createdName"
                                    value={books.createdName}
                                    onChange={(e: any) => handleBookChange(e)}
                                    error={formError?.createdName}
                                />{" "}
                            </div>
                        </div>{" "}
                    </div>
                )}
                {tab === "Journal" && (
                    <div className="flex space-x-6">
                        <div>
                            <Input
                                lable="departmentTeacher"
                                name="departmentTeacher"
                                value={journal.departmentTeacher}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleJournalChange(e)}
                                error={journalformError?.departmentTeacher}
                            />{" "}
                            <Input
                                lable="Paper Title"
                                name="paperTitle"
                                value={journal.paperTitle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleJournalChange(e)}
                                error={journalformError?.paperTitle}
                            />{" "}
                            <Input
                                lable="Author Name"
                                name="authorsName"
                                value={journal.authorsName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleJournalChange(e)}
                                error={journalformError?.authorsName}
                            />{" "}
                            <Input
                                lable="doi"
                                name="doi"
                                value={journal.doi}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleJournalChange(e)}
                                error={journalformError?.doi}
                            />{" "}
                        </div>
                        <div>
                            <Input
                                lable="Publication Year"
                                name="publicationYear"
                                value={journal.publicationYear}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleJournalChange(e)}
                                error={journalformError?.publicationYear}
                            />{" "}
                            <Input
                                lable="ISSN Number"
                                name="issnNumber"
                                value={journal.issnNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleJournalChange(e)}
                                error={journalformError?.issnNumber}
                            />{" "}
                            <Input
                                lable="Name Of Journal"
                                name="nameOfJournal"
                                value={journal.nameOfJournal}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleJournalChange(e)}
                                error={journalformError?.nameOfJournal}
                            />{" "}
                        </div>
                    </div>
                )}
                {tab === "Conference" && (
                    <div className="flex space-x-6">
                        <div>
                            <Input
                                lable="TeacherName"
                                name="teacherName"
                                value={conference.teacherName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.teacherName}
                            />{" "}
                            <Input
                                lable="Title Of Book Published"
                                name="titleOfBookPublished"
                                value={conference.titleOfBookPublished}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.titleOfBookPublished}
                            />{" "}
                            <Input
                                lable="Title Paper"
                                name="titlePaper"
                                value={conference.titlePaper}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.titlePaper}
                            />{" "}
                            <Input
                                lable="Proceedings Conference"
                                name="proceedingsConference"
                                value={conference.proceedingsConference}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.proceedingsConference}
                            />{" "}
                            <Input
                                lable="Conference Name"
                                name="conferenceName"
                                value={conference.conferenceName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.conferenceName}
                            />{" "}
                        </div>
                        <div>
                            <Input
                                lable="National Or International"
                                name="nationalOrInternational"
                                value={conference.nationalOrInternational}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.nationalOrInternational}
                            />{" "}
                            <Input
                                lable="Publication Year"
                                name="publicationYear"
                                value={conference.publicationYear}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.publicationYear}
                            />{" "}
                            <Input
                                lable="ISBN Or ISSN Number"
                                name="isbnOrissnNumber"
                                value={conference.isbnOrissnNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.isbnOrissnNumber}
                            />{" "}
                            <Input
                                lable="Affiliating Institute"
                                name="affiliatingInstitute"
                                value={conference.affiliatingInstitute}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.affiliatingInstitute}
                            />{" "}
                            <Input
                                lable="Publisher Name"
                                name="publisherName"
                                value={conference.publisherName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleConferenceChange(e)}
                                error={conferenceformError?.publisherName}
                            />{" "}
                        </div>
                    </div>
                )}

                {tab === "Copyright" && (
                    <div className="flex space-x-6">
                        <div>
                            <Input
                                lable="Copyright Application"
                                name="copyrightApplication"
                                value={copyright.copyrightApplication}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleCopyrightChange(e)}
                                error={copyrightformError?.copyrightApplication}
                            />{" "}
                            <Input
                                lable="Status"
                                name="status"
                                value={copyright.status}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleCopyrightChange(e)}
                                error={copyrightformError?.status}
                            />{" "}
                            <Input
                                lable="Inventor"
                                name="inventor"
                                value={copyright.inventor}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleCopyrightChange(e)}
                                error={copyrightformError?.inventor}
                            />{" "}
                        </div>
                        <div>
                            <Input
                                lable="Copyright Title"
                                name="copyrightTitle"
                                value={copyright.copyrightTitle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleCopyrightChange(e)}
                                error={copyrightformError?.copyrightTitle}
                            />{" "}
                            <Input
                                lable="Publication Year"
                                name="publicationYear"
                                value={copyright.publicationYear}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleCopyrightChange(e)}
                                error={copyrightformError?.publicationYear}
                            />{" "}
                        </div>
                    </div>
                )}
                {tab === "Patent" && (
                    <div className="flex space-x-6">
                        <div>
                            <Input
                                lable="Patent Application"
                                name="patentApplication"
                                value={patent.patentApplication}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.patentApplication}
                            />{" "}
                            <Input
                                lable="Status"
                                name="status"
                                value={patent.status}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.status}
                            />{" "}
                            <Input
                                lable="Inventor"
                                name="inventor"
                                value={patent.inventor}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.inventor}
                            />{" "}
                            <Input
                                lable="Patent Title"
                                name="patentTitle"
                                value={patent.patentTitle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.patentTitle}
                            />{" "}
                            <Input
                                lable="Applicant Name"
                                name="applicantName"
                                value={patent.applicantName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.applicantName}
                            />{" "}
                        </div>
                        <div>
                            <Input
                                type="date"
                                lable="Patent Filed Date"
                                name="patentFiledDate"
                                value={patent.patentFiledDate}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.patentFiledDate}
                            />{" "}
                            <Input
                                type="date"
                                lable="Patent Published Date"
                                name="patentPublishedDate"
                                value={patent.patentPublishedDate}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.patentPublishedDate}
                            />{" "}
                            <Input
                                lable="Patent Publication Number"
                                name="patentPublicationNumber"
                                value={patent.patentPublicationNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.patentPublicationNumber}
                            />{" "}
                            <Input
                                lable="Assignee Name"
                                name="assigneeName"
                                value={patent.assigneeName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.assigneeName}
                            />{" "}
                            <Input
                                lable="Publication Year"
                                name="publicationYear"
                                value={patent.publicationYear}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlePatentChange(e)}
                                error={patentformError?.publicationYear}
                            />{" "}
                        </div>
                    </div>
                )}
                <div className="flex justify-center items-center space-x-6  mt-5">
                    <Button
                        lable="create"
                        type="button"
                        buttonType="primary"
                        onClick={() => handleSubmit()}
                    />
                    <Button
                        lable="cancel"
                        type="button"
                        buttonType="danger"
                        onClick={() => navigate(`/publication/${tab}`)}
                    />
                </div>
            </div>
        </>
    );
};

export default PublicationCreate;
