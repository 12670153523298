import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FaSearch, FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import styles from "./muitable.module.css";
import PopOver from "../Popover/PopOver";
import { colors } from "../../config/constants";
import Popup from "../Popup/Popup";

interface Column {
  id?: any;
  label: string;
  minWidth?: number;
  align?: any;
  format?: (value: number) => string;
}
interface Data {
  id: number;
  name?: string;
  code?: string;
  population?: number;
  title?: string;
  desc?: string;
  viewdetails?: string;
  body?: string;
  assign?: any;
}

interface MuiTableProps {
  columns: Column[];
  rows: Data[];
  onEditHandler?: (arg: number) => void;
  onDeleteHandler?: (arg: number) => void;
  onViewHandler?: (arg: number) => void;
  searchColumn?: any;
  primaryKey?: any;
  onAssignStudent?: (arg: number) => void;
}

const MuiTable = (props: MuiTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const {
    columns,
    rows,
    onEditHandler,
    onDeleteHandler,
    searchColumn,
    onViewHandler,
    primaryKey,
    onAssignStudent,
  } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    return rows;
  };

  const viewAction = (id: number) => {
    return (
      <div
        onClick={() => alert(id)}
        className="bg-sky-700 w-fit px-3 py-1 text-white rounded-sm cursor-pointer"
      >
        View
      </div>
    );
  };
  const content = () => <div></div>;

  const doSubmit = () => {
    alert("calling");
  };

  const showItem = (value: string, columnname: string, id: number) => {
    if (columnname === "action") {
      return (
        <div className="flex items-center">
          {/* <FaPencilAlt
            onClick={() => onEditHandler && onEditHandler(id)}
            color="blue"
            className="border-2 border-sky-500 w-6 h-6 p-1 rounded-sm cursor-pointer mr-1"
          />
          <FaTrashAlt
            onClick={() => onDeleteHandler && onDeleteHandler(id)}
            color="red"
            className="border-2 border-red-500  w-6 h-6 p-1 rounded-sm cursor-pointer"
          /> */}
          <div
            onClick={() => onViewHandler && onViewHandler(id)}
            className="bg-sky-900 px-2 py-1 rounded-sm  text-white cursor-pointer "
          >
            <i className="fa-solid fa-eye"></i>
          </div>
        </div>
      );
    } else if (columnname === "viewdetails") {
      return viewAction(id);
    } else if (
      columnname === "CreatedOn" ||
      columnname === "UpdatedDate" ||
      columnname === "createdOn" ||
      columnname === "patentFiledDate" ||
      columnname === "patentPublishedDate"
    ) {
      return value?.substring(0, 10);
    } else if (columnname === "assign") {
      return (
        <div
          onClick={() => onAssignStudent && onAssignStudent(id)}
          className="bg-[green] px-2 py-1 rounded-sm  text-white cursor-pointer flex justify-center"
        >
          Assign/Block
        </div>
      );
    }
    else if (value === null) {
      return "-";
    } else {
      return <div className="text-sm">{value}</div>;
    }
  };

  return (
    <div>
      <div
        className={`${colors.primary} text-white sm:flex items-center justify-between rounded-sm mb-1 pr-1`}
      >
        <div className="flex items-center p-2  ">
          <div className={`${colors.primary} pl-2 pr-10 text-md`}>
            Records List
          </div>
        </div>
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" align="left">
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow>
                {columns.map((column: Column, index: number) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    classes={{ root: styles.tableHeadCell }}
                  >
                    {column.label.toUpperCase()}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: number) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column: Column, index: number) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              classes={{ root: styles.tablebody }}
                            >
                              {showItem(value, column.id, row[primaryKey])}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <div className="flex items-center justify-center text-[gray]">
                  No records found!
                </div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Popup
        heading={"Are you sure want to delete?"}
        content={content}
        doSubmit={doSubmit}
        isOpen={deleteConfirm}
        onClose={() => setDeleteConfirm(false)}
        submitLable={"YES"}
        cancelLable={"NO"}
      />
    </div>
  );
};
export default MuiTable;
