import React, { useEffect, useState } from "react";
import bg from "../../assets/bgFront.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../service/service";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import { snackBarColors } from "../../config/constants";
import { Capitalization } from "../../config/helper";
import { updateSnackbar } from "../../redux/action";
import { useDispatch } from "react-redux";

const Register = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [state, setState] = useState({
    username: "",
    email: "",
    password: "",
    phonenumber: "",
    confirm: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const { username, email, phonenumber, password, confirm } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState({
    text: "Register Now",
    status: false,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.name === "phonenumber") {
      setState({
        ...state,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
      });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (!e.target.value) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: `${Capitalization(e.target.name)} required!`,
      });
    } else {
      setFormErrors({ ...formErrors, [e.target.name]: undefined });
    }
  };
  const validate = () => {
    const error: any = {};
    const emailvalidation = /\S+@\S+\.\S+/.test(email);
    if (!username) {
      error.username = "Username required!";
    }
    if (!password) {
      error.password = "Password required!";
    } else if (password.length < 6) {
      error.password = "Password must be minimum 6 characters!";
    }
    if (!confirm) {
      error.confirm = "Confirm password required!";
    } else if (password !== confirm) {
      error.confirm = "Password and confirm password is not matching! ";
    }
    if (!email) {
      error.email = "Email required! ";
    } else if (!emailvalidation) {
      error.email = "Email invalid ";
    }
    if (!phonenumber) {
      error.phonenumber = "Please enter your phonenumber";
    } else if (phonenumber.length > 10 || phonenumber.length < 10) {
      error.phonenumber = "Moblie number must be 10 digits";
    }
    return error;
  };
  const handleSubmit = () => {
    setFormErrors(validate());
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(state);
      doRegister();
    }
  }, [formErrors]);

  const doRegister = async () => {
    setIsLoading(true);
    setLoginStatus({ ...loginStatus, text: "Please wait...", status: true });
    const obj = {
      username: username,
      email: email,
      password: password,
      phonenumber: phonenumber,
      Role: "Student"
    };
    await axios
      .post(`${baseUrl}/account/Registration`, obj)
      .then((response) => {
        if (response.status === 200) {
          const obj = {
            value: true,
            message: "Registration successfully. Please Login",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj))
          navigate(`/`);
          setLoginStatus({ ...loginStatus, text: "Register", status: false });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("calling catch", error.response?.data,)
        setLoginStatus({ ...loginStatus, text: "Register", status: false });
        if (error) {
          const obj = {
            value: true,
            message: error.response?.data,
            type: snackBarColors.error,
          }
          dispatch(updateSnackbar(obj))
        }
        setIsLoading(false);
      });
  };
  return (
    <div>
      {isLoading && <ActivityIndicator />}
      <div className="flex items-center justify-center sm:h-screen sm:shadow-lg rounded-lg sm:bg-[#02497f]">
        <div className="h-[510px] w-[900px] sm:flex justify-between bg-white sm:shadow-lg rounded-md">
          <div className="sm:w-1/2 h-full items-center bg-white rounded-md hidden sm:block">
            <img
              src={bg}
              className="rounded-tl-md rounded-bl-md object-cover	"
            />
          </div>
          <div className="sm:w-1/2 flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center">
              <div className="text-[30px] font-bold text-[#200175]">
                Srinivas University
              </div>
              <div className="text-[gold]">Please Register</div>
              <div className="h-18">
                <div className="text-sm text-[gray]">Username</div>
                <input
                  className="focus:border-[#83b8f5] h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="John"
                  value={username}
                  name="username"
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                />
                <div className="text-sm text-[red]">{formErrors?.username}</div>
              </div>
              <div className="h-18 ">
                <div className="text-sm  text-[#1f1f20]">Email</div>
                <input
                  className="focus:border-[#83b8f5] h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="john@gmail.com"
                  value={email}
                  name="email"
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                />
                <div className="text-sm text-[red]">{formErrors?.email}</div>
              </div>

              <div className="h-18 ">
                <div className="text-sm  text-[gray]">Password</div>
                <input
                  className="focus:border-[#83b8f5] h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="*******"
                  value={password}
                  name="password"
                  type="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                />
                <div className="text-sm text-[red]">{formErrors?.password}</div>
              </div>
              <div className="h-18 ">
                <div className="text-sm  text-[gray]">Confirm Password</div>
                <input
                  className="focus:border-[#83b8f5] h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="*******"
                  value={confirm}
                  name="confirm"
                  type="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                />
                <div className="text-sm text-[red]">{formErrors?.confirm}</div>
              </div>

              <div className="h-18 ">
                <div className="text-sm  text-[gray]">Mobile number</div>
                <input
                  className="focus:border-[#83b8f5] h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="9876543210"
                  value={phonenumber}
                  name="phonenumber"
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                />
                <div className="text-sm text-[red]">
                  {formErrors?.phonenumber}
                </div>
              </div>
            </div>
            <div className="flex items-center w-full justify-end mt-2 text-sm pr-12 text-blue-900">
              <div>Forgot Password?</div>
            </div>
            <div className="flex items-center w-full justify-center h-18 text-sm">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="bg-[#200175]  px-4 py-1 rounded-sm text-white"
              >
                {loginStatus.text}
              </button>
            </div>
            <div
              className="text-blue-500 mt-2 underline cursor-pointer"
              onClick={() => navigate("/")}
            >
              Back to Login
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
