import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { update_breadcrumb } from "../../redux/action";
import instance from "../../service/axiosInstane";
import { useDispatch, useSelector } from "react-redux";
import MuiTable from "../../components/MuiTable/MuiTable";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import {
    booksColumn,
    conferenceColumn,
    copyrightColumn,
    journalColumn,
    patentColumn,
} from "../../config/publicationColumns";
import * as XLSX from "xlsx";
import { endPointGenerator } from "../../config/helper";
import Input from "../../components/Input/Input";
import Dropdown from "../../components/Dropdown/Dropdown";

const PublicationView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userinformation = useSelector((state: any) => state.userInfo);
    const userRole = useSelector((state: any) => state.userRole);
    const [tab, setTab] = useState<any>("Books");
    const [column, setColumn] = useState<any>(booksColumn);
    const [apiEndPoint, setApiEndPoint] = useState(
        endPointGenerator(id, userinformation.userId, userRole)
    );
    const [searchParams, setSearchParams] = useState<any>({
        From: null,
        To: null,
        role: "",
    });
    const [state, setState] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const role = useSelector((state: any) => state.userRole);
    const option = [
        {
            id: 1,
            label: "Books",
            onClick: () => {
                setTab("Books");
                setApiEndPoint(
                    userRole === "Admin"
                        ? `/Admin/GetBooks`
                        : `/Guide/GetBooks?userId=${userinformation.userId}`
                );

                setColumn(booksColumn);
                setSearchParams({
                    ...searchParams, From: null,
                    To: null,
                    role: "",
                })
            },
        },
        {
            id: 2,
            label: "Journal",
            onClick: () => {
                setTab("Journal");
                setApiEndPoint(
                    userRole === "Admin"
                        ? `/Admin/GetJournal`
                        : `/Guide/GetJournal?userId=${userinformation.userId}`
                );
                setColumn(journalColumn);
                setSearchParams({
                    ...searchParams, From: null,
                    To: null,
                    role: "",
                })
            },
        },
        {
            id: 3,
            label: "Conference",
            onClick: () => {
                setTab("Conference");
                setApiEndPoint(
                    userRole === "Admin"
                        ? `/Admin/GetConference`
                        : `/Guide/GetConference?userId=${userinformation.userId}`
                );
                setColumn(conferenceColumn);
                setSearchParams({
                    ...searchParams, From: null,
                    To: null,
                    role: "",
                })
            },
        },
        {
            id: 4,
            label: "Copyright",
            onClick: () => {
                setTab("Copyright");
                setApiEndPoint(
                    userRole === "Admin"
                        ? `/Admin/Getcopyright`
                        : `/Guide/Getcopyright?userId=${userinformation.userId}`
                );
                setColumn(copyrightColumn);
                setSearchParams({
                    ...searchParams, From: null,
                    To: null,
                    role: "",
                })
            },
        },
        {
            id: 5,
            label: "Patent",
            onClick: () => {
                setTab("Patent");
                setApiEndPoint(
                    userRole === "Admin"
                        ? `/Admin/GetPatent`
                        : `/Guide/GetPatent?userId=${userinformation.userId}`
                );
                setColumn(patentColumn);
                setSearchParams({
                    ...searchParams, From: null,
                    To: null,
                    role: "",
                })
            },
        },
    ];

    useEffect(() => {
        checkFrom();
    }, []);

    const checkFrom = () => {
        if (id !== "dummy") {
            if (id === "Books") {
                setTab("Books");
                // setApiEndPoint("/Admin/GetBooks");
                setColumn(booksColumn);
            }
            if (id === "Journal") {
                setTab("Journal");
                // setApiEndPoint(`/Guide/GetJournal?userId=${userinformation.userId}`);
                setColumn(journalColumn);
            }
            if (id === "Conference") {
                setTab("Conference");
                // setApiEndPoint(`/Guide/GetConference?userId=${userinformation.userId}`);
                setColumn(conferenceColumn);
            }
            if (id === "Copyright") {
                setTab("Copyright");
                // setApiEndPoint(`/Guide/Getcopyright?userId=${userinformation.userId}`);
                setColumn(copyrightColumn);
            }
            if (id === "Patent") {
                setTab("Patent");
                // setApiEndPoint(`/Guide/GetPatent?userId=${userinformation.userId}`);
                setColumn(patentColumn);
            }
        } else {
            setTab("Books");
            setApiEndPoint(
                userRole === "Admin"
                    ? `/Admin/GetBooks`
                    : `/Guide/GetBooks?userId=${userinformation.userId}`
            );
            setColumn(booksColumn);
        }
    };

    useEffect(() => {
        getPublication();
        const breadcrumb = [
            { path: `/publication/${id}`, name: "Publication Management" },
        ];
        dispatch(update_breadcrumb(breadcrumb));
    }, [tab]);

    const getPublication = async () => {
        const aaaa = {
            "responce.from": searchParams.From,
            "responce.to": searchParams.To,
            "responce.role": searchParams.role,
        };
        setIsLoading(true);
        await instance
            .get(apiEndPoint, {
                params: aaaa,
            })
            .then((response) => {
                if (response.status === 200) {
                    setState(response.data);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("error", err);
                setIsLoading(false);
            });
    };

    const handleExport = () => {
        const wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(state);
        XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
        XLSX.writeFile(wb, `${tab}.xlsx`);
    };
    const handleTextChange = (e: any) => {
        setSearchParams({
            ...searchParams,
            [e.target.name]: e.target.value,
        });
    };
    const searchHandler = () => {
        getPublication();
    };
    return (
        <>
            <div className="bg-white p-4 shadow-lg">
                <div className="px-1">
                    <div className="flex items-center justify-between font-semibold text-[24px] text-sky-700 py-1 border-b-2 border-[gold]">
                        <div>Publication Management</div>
                    </div>
                    <div>
                        <div className="flex items-center justify-between cursor-pointer">
                            <div className="flex items-center cursor-pointer mt-1">
                                {option?.map((item: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`${item.label === tab
                                                ? "bg-[orange] text-white"
                                                : "bg-[gold]"
                                                }  py-1 px-4 border-r rounded-sm`}
                                            onClick={item.onClick}
                                        >
                                            <div className="font-semibold">{item.label}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="flex items-center space-x-4">
                                {role === "Admin" ? (
                                    <Button
                                        lable="Export"
                                        type="button"
                                        buttonType="primary"
                                        onClick={() => handleExport()}
                                    />
                                ) : (
                                    <Button
                                        lable="Create"
                                        type="button"
                                        buttonType="primary"
                                        onClick={() => navigate(`/publication_create/${tab}`)}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="font-semibold text-[20px] my-1">{tab}</div>
                        {userRole === "Admin" && <div className="flex items-center space-x-2">
                            <Input
                                lable="Year From"
                                name="From"
                                value={searchParams.From}
                                placeholder="Publication year from"
                                onChange={(e: any) => handleTextChange(e)}
                            />{" "}
                            <Input
                                lable="Year To"
                                name="To"
                                value={searchParams.To}
                                placeholder="Publication year to"
                                onChange={(e: any) => handleTextChange(e)}
                            />{" "}
                            <Dropdown
                                lable="Role"
                                name="role"
                                value={searchParams.role}
                                onChange={(e: any) => handleTextChange(e)}
                                options={[
                                    { name: "Student", value: "Student" },
                                    { name: "Guide", value: "Guide" },
                                ]}
                            />{" "}
                            <div className="mt-6">
                                <Button
                                    lable="Search"
                                    type="button"
                                    buttonType="primary"
                                    onClick={() => searchHandler()}
                                />
                            </div>
                        </div>}

                        <div className="">
                            {isLoading ? (
                                <ActivityIndicator />
                            ) : (
                                <MuiTable
                                    columns={column}
                                    rows={state}
                                    searchColumn={"userName"}
                                    primaryKey={"userId"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PublicationView;
