import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import instance from "../../../service/axiosInstane";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import { PDF, PHD, DSC } from "../../../config/studentType";
import ProgressBar from "../../../components/ProgreesBar/ProgressBar";
import { snackBarColors } from "../../../config/constants";
import { updateSnackbar } from "../../../redux/action";

const StudentFeeform = () => {
  const action = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const studentID = queryParameters.get('studentId');
  const role = useSelector((state: any) => state.userRole);
  const [formErrors, setFormErrors] = useState<any>({});
  const [phdFormErrors, setPhdFormErrors] = useState<any>({});
  const [dscFormErrors, setDscFormErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const userinformation = useSelector((state: any) => state.userInfo);
  const studentType = useSelector((state: any) => state.studenType);

  const [state, setState] = useState<any>({
    applicationFee: null,
    areaOfResearch: "",
    courseworkFee: null,
    fifthYearFee: null,
    firstDCMFee: null,
    firstYearFee: null,
    forthYearFee: null,
    isSubmitted: true,
    othersFeeOne: null,
    othersFeeSecond: null,
    registrationFee: null,
    secondDCMFee: null,
    secondYearFee: null,
    subject: "",
    thesisSubmissionFee: null,
    thirdYearFee: null,
    userId: role === "Student" ? userinformation.userId : studentID,
    vivaFee: null,
  });

  const {
    applicationFee,
    areaOfResearch,
    courseworkFee,
    fifthYearFee,
    firstDCMFee,
    firstYearFee,
    forthYearFee,
    othersFeeOne,
    othersFeeSecond,
    registrationFee,
    secondDCMFee,
    secondYearFee,
    subject,
    thesisSubmissionFee,
    thirdYearFee,
    userId,
    vivaFee,
  } = state;

  useEffect(() => {
    getFeeDetails();
  }, []);

  const handleChangeText = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const hadleSubmit = () => {
    if (action.action === "edit") {
      feeDetailsUpdate();
    } else {
      onFeeSubmit();
    }
  };

  const onFeeSubmit = () => {
    setIsLoading(true);
    instance
      .post("/Students/FeeDetails", state)
      .then((response) => {
        if (response.status === 200) {
          if (studentType === PHD) {
            const obj = {
              value: true,
              message: "Fee details added succesfully!",
              type: snackBarColors.success,
            }
            dispatch(updateSnackbar(obj))
            navigate("/student/student_coursework_form/create");
          } else {
            const obj = {
              value: true,
              message: "Fee details added succesfully!",
              type: snackBarColors.success,
            }
            dispatch(updateSnackbar(obj))
            navigate("/student/student_report_form/create");
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        const obj = {
          value: true,
          message: "Something went wrong!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj))
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const feeDetailsUpdate = () => {
    setIsLoading(true);
    instance
      .post(`/Students/UpdatedFeeDetails?UserId=${role === "Student" ? userId : studentID}`, state)
      .then((response) => {
        if (response.status === 200) {
          const obj = {
            value: true,
            message: "Fee details updated succesfully!",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj));
          navigate(`/student/student_profile?studentId=${response.data.userId}`);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        const obj = {
          value: true,
          message: "Something went wrong!!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj));
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const getFeeDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Students/GetFeeDetails?UserId=${role === "Student" ? userId : studentID}`)
      .then((response) => {
        if (response.status === 200) {
          if (
            action.action === "create" &&
            response.data.isSubmitted === null
          ) {
            //do nothing
          } else if (
            response.data.isSubmitted === true &&
            action.action === "create" &&
            studentType === PHD
          ) {
            navigate("/student/student_coursework_form/create");
          } else if (
            response.data.isSubmitted === true &&
            action.action === "create" &&
            studentType === PDF
          ) {
            navigate("/student/student_report_form/create");
          } else if (
            response.data.isSubmitted === true &&
            action.action === "create" &&
            studentType === DSC
          ) {
            navigate("/student/student_report_form/create");
          } else {
            setState(response.data);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? <ActivityIndicator /> : null}
      <div className="bg-white shadow-lg p-4">
        <div className="">
          <div className="font-semibold text-[24px] text-sky-700">
            Student Profile Form
          </div>
          <ProgressBar tab={2} />
        </div>
        <div className="my-2 border-b-2 p-2">
          <div className="font-semibold text-lg ">Fee Details</div>
        </div>
        <div className="flex justify-center px-2">
          <div className="sm:flex  sm:space-x-10   ">
            <div className="w-full">
              <div className="my-1 ">
                <Input
                  lable="Subject"
                  type="text"
                  name="subject"
                  value={subject}
                  onChange={handleChangeText}
                  error={formErrors.subject}
                />
              </div>

              <div className="my-1">
                <Input
                  lable="Area Of Research,"
                  type="text"
                  name="areaOfResearch"
                  value={areaOfResearch}
                  onChange={handleChangeText}
                  error={formErrors.areaOfResearch}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Application Fee"
                  type="number"
                  name="applicationFee"
                  value={applicationFee}
                  onChange={handleChangeText}
                  error={formErrors.applicationFee}
                />
              </div>

              <div className="my-1 ">
                <Input
                  lable="Coursework Fee"
                  type="number"
                  name="courseworkFee"
                  value={courseworkFee}
                  onChange={handleChangeText}
                  error={formErrors.courseworkFee}
                />
              </div>
              {studentType === "D.Sc/D.Litt" && (
                <>
                  <div className="my-1 ">
                    <Input
                      lable="Registration Fee"
                      type="number"
                      name="registrationFee"
                      value={registrationFee}
                      onChange={handleChangeText}
                      error={dscFormErrors.registrationFee}
                    />
                  </div>
                  <div className="my-1 ">
                    <Input
                      lable="Thesis Submission Fee"
                      type="number"
                      name="thesisSubmissionFee"
                      value={thesisSubmissionFee}
                      onChange={handleChangeText}
                      error={dscFormErrors.thesisSubmissionFee}
                    />
                  </div>
                </>
              )}
            </div>

            {studentType === "Ph.D" && (
              <div className="w-full">
                <div className="my-1">
                  <Input
                    lable="Registration Fee,"
                    type="number"
                    name="registrationFee"
                    value={registrationFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.RegistrationFee}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="I DCM Fee"
                    type="number"
                    name="firstDCMFee"
                    value={firstDCMFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.firstDCMFee}
                  />
                </div>
                <div className="my-1 ">
                  <Input
                    lable="II DCM Fee"
                    type="number"
                    name="secondDCMFee"
                    value={secondDCMFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.secondDCMFee}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="I Year Fee"
                    type="number"
                    name="firstYearFee"
                    value={firstYearFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.firstYearFee}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="II Year Fee"
                    type="number"
                    name="secondYearFee"
                    value={secondYearFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.secondYearFee}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="III Year Fee"
                    type="number"
                    name="thirdYearFee"
                    value={thirdYearFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.thirdYearFee}
                  />
                </div>
              </div>
            )}
            {studentType === "Ph.D" && (
              <div>
                <div className="my-1">
                  <Input
                    lable="IV Year Fee"
                    type="number"
                    name="forthYearFee"
                    value={forthYearFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.forthYearFee}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="V Year Fee"
                    type="number"
                    name="fifthYearFee"
                    value={fifthYearFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.fifthYearFee}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Thesis Submission Fee"
                    type="number"
                    name="thesisSubmissionFee"
                    value={thesisSubmissionFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.thesisSubmissionFee}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Vive Fee,"
                    type="number"
                    name="vivaFee"
                    value={vivaFee}
                    onChange={handleChangeText}
                    error={phdFormErrors.vivaFee}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Others Fee-I"
                    type="number"
                    name="othersFeeOne"
                    value={othersFeeOne}
                    onChange={handleChangeText}
                    error={phdFormErrors.othersFeeOne}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Others Fee- II"
                    type="number"
                    name="othersFeeSecond"
                    value={othersFeeSecond}
                    onChange={handleChangeText}
                    error={phdFormErrors.othersFeeSecond}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-center items-center px-2 space-x-4 mt-5">
          <Button
            lable="submit"
            type="button"
            buttonType="primary"
            onClick={() => hadleSubmit()}
          />
          <Button
            lable="cancel"
            type="button"
            buttonType="danger"
            onClick={() => navigate(`/student/student_profile?studentId=${studentID}`)}
          />
        </div>
      </div>
    </>
  );
};

export default StudentFeeform;
