import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { updateStudenType, updateUserInformation, update_breadcrumb } from "../../redux/action";
import instance from "../../service/axiosInstane";
import { useDispatch, useSelector } from "react-redux";
import MuiTable from "../../components/MuiTable/MuiTable";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../components/Dropdown/Dropdown";
import Input from "../../components/Input/Input";
import { DSC, PDF, PHD } from "../../config/studentType";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import * as XLSX from "xlsx";

function StudentsView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchState, setSearchState] = useState<any>({
        Type: "all",
        Value: "",
        From: "",
        To: "",
    });
    const [noRecord, setNoRecord] = useState("");
    const [state, setState] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const token = useSelector((state: any) => state.token);
    const columns = [
        { id: "userId", label: "User Id", minWidth: 70, align: "left" },
        {
            id: "candidateName",
            label: "Candidate Name",
            minWidth: 170,
            align: "left",
        },
        {
            id: "emailId",
            label: "Email Id",
            minWidth: 170,
            align: "left",
        },

        {
            id: "guideName",
            label: "Guide Name",
            minWidth: 70,
            align: "left",
        },
        // {
        //     id: "guideId",
        //     label: "guideId",
        //     minWidth: 70,
        //     align: "left",
        // },
        // {
        //     id: "Status",
        //     label: "Status",
        //     minWidth: 70,
        //     align: "left",
        // },
        {
            id: "action",
            label: "action",
            minWidth: 70,
            align: "left",
        },
        {
            id: "assign",
            label: "assign",
            minWidth: 70,
            align: "left",
        },
    ];
    useEffect(() => {
        getStudentList();
        const breadcrumb = [
            { path: "/admin_student_view", name: "Student Management" },
        ];
        dispatch(update_breadcrumb(breadcrumb));
    }, []);
    const getStudentList = async () => {
        setIsLoading(true);
        await instance
            .post(`Admin/FilterStudentDetails`, searchState)
            .then((response) => {
                if (response.status === 200) {
                    setState(response.data);
                    setNoRecord("")
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    setNoRecord("No Records found!")
                    setIsLoading(false);
                }

                setIsLoading(false);
            });
    };
    const viewRecord = (args: any) => {
        navigate(`/student/student_profile?studentId=${args}`);
    };
    const onAssignHandler = (args: any) => {
        navigate(`/assign_student/${args}`);
    };
    const handleExport = () => {
        const wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(state);
        XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
        XLSX.writeFile(wb, `Students.xlsx`);
    }
    return (
        <>
            {isLoading ? (
                <ActivityIndicator />
            ) : null}
            <div className="bg-white p-4 shadow-lg  ">
                <div className="px-1">
                    <div className="flex items-center justify-between font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
                        <div>Student Management</div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <Dropdown
                                lable="SearchType"
                                name="searchType"
                                value={searchState.Type}
                                onChange={(e: any) =>
                                    setSearchState({ ...searchState, Type: e.target.value })
                                }
                                options={[
                                    { name: "All", value: "all" },
                                    { name: "Name", value: "name" },
                                    { name: "ID", value: "id" },
                                    { name: "Category", value: "category" },
                                    { name: "Locality", value: "locality" },
                                    { name: "PHD Type", value: "phdType" },
                                    { name: "Date", value: "date" },
                                ]}
                            />

                            {searchState.Type === "name" && (
                                <Input
                                    lable="Value"
                                    type="text"
                                    name="searchValue"
                                    value={searchState.value}
                                    onChange={(e: any) =>
                                        setSearchState({ ...searchState, Value: e.target.value })
                                    }
                                />
                            )}
                            {searchState.Type === "id" && (
                                <Input
                                    lable="Value"
                                    type="text"
                                    name="searchValue"
                                    value={searchState.value}
                                    onChange={(e: any) =>
                                        setSearchState({ ...searchState, Value: e.target.value })
                                    }
                                />
                            )}
                            {searchState.Type === "locality" && (
                                <Dropdown
                                    lable="Locality"
                                    value={searchState.value}
                                    onChange={(e: any) =>
                                        setSearchState({ ...searchState, Value: e.target.value })
                                    }
                                    options={[
                                        { name: "Karnataka", value: "Karnataka" },
                                        { name: "Non Karnataka", value: "Non Karnataka" },
                                        { name: "NRI", value: "NRI" },
                                    ]}
                                />
                            )}
                            {searchState.Type === "category" && (
                                <Dropdown
                                    lable="Category"
                                    value={searchState.value}
                                    onChange={(e: any) =>
                                        setSearchState({ ...searchState, Value: e.target.value })
                                    }
                                    options={[
                                        { name: PHD, value: PHD },
                                        { name: PDF, value: PDF },
                                        { name: DSC, value: DSC },
                                    ]}
                                />
                            )}
                            {searchState.Type === "phdType" && (
                                <Dropdown
                                    lable="PHD Type"
                                    value={searchState.value}
                                    onChange={(e: any) =>
                                        setSearchState({ ...searchState, Value: e.target.value })
                                    }
                                    options={[
                                        { name: "Full time", value: "fullTime" },
                                        { name: "Part-time", value: "partTime" },
                                        {
                                            name: "Faculty",
                                            value: "faculty",
                                        },
                                    ]}
                                />
                            )}

                            <div className="mt-6">
                                <Button

                                    icon={<i className="fa-solid fa-magnifying-glass"></i>}
                                    onClick={() => getStudentList()}
                                    type="button"
                                    buttonType="primary"
                                />
                            </div>
                        </div>
                        <div className="mt-6"><Button

                            lable="Export"
                            onClick={() => handleExport()}
                            type="button"
                            buttonType="primary"
                        /></div>


                    </div>
                    {searchState.Type === "date" && (
                        <div className="flex items-center space-x-4">
                            <Input
                                lable="From Date"
                                type="date"
                                value={searchState.From}
                                onChange={(e: any) =>
                                    setSearchState({
                                        ...searchState,
                                        From: e.target.value.substring(0, 10),
                                    })
                                }
                            />

                            <Input
                                lable="To Date"
                                type="date"
                                value={searchState.To}
                                onChange={(e: any) =>
                                    setSearchState({
                                        ...searchState,
                                        To: e.target.value.substring(0, 10),
                                    })
                                }
                            />
                        </div>
                    )}

                    <div>
                        <div className="mt-4">
                            {noRecord === "" ? <MuiTable
                                columns={columns}
                                rows={state}
                                searchColumn={"userName"}
                                onViewHandler={viewRecord}
                                primaryKey={"userId"}
                                onAssignStudent={onAssignHandler}


                            /> :
                                <div>No Records Found!</div>}

                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default StudentsView;
