import React, { useEffect, useState } from "react";
import logo from "../../assets/sr_logo.gif";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../service/service";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";

const ResetPassword = () => {
    const { email } = useParams()
    const navigate = useNavigate();
    const [state, setState] = useState({
        newPassword: "",
        confirmPassword: "",
        otp: ""
    });
    const [isSubmit, setIsSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const { newPassword, confirmPassword, otp } = state;
    const [snackBarOpen, setSnackBarOpen] = useState({
        value: false,
        message: "",
        type: "",
    });
    const [sweetAlert, setSweetAlert] = useState({
        value: false,
        messge: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const validate = (state: any) => {
        const error: any = {}
        if (!newPassword) {
            error.newPassword = "Please enter new password";
        } else if (newPassword.length < 6) {
            error.newPassword = "Password length should be minimum 6 characters";
        }
        if (!confirmPassword) {
            error.confirmPassword = "Please enter confirm password";
        } else if (newPassword !== confirmPassword) {
            error.confirmPassword = "new password and confirm password are not matching";
        }
        if (!otp) {
            error.otp = "Please enter OTP";
        }
        return error;
    };
    const handleSubmit = () => {
        setFormErrors(validate(state));
        setIsSubmit(true);
    };
    useEffect(() => {
        let obj = {
            password: newPassword,
            otp: Number(otp),
            emailId: email
        }
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            resettingPassWord(obj);
        }
    }, [formErrors]);

    const resettingPassWord = (data: any) => {
        setIsLoading(true);
        axios
            .post(`${baseUrl}/account/ForgotPassword`, data)
            .then((response) => {
                if (response.status === 200) {
                    setSweetAlert({
                        ...sweetAlert,
                        value: true,
                        messge: "Password Changed successfully!",
                    });
                    setIsSubmit(false);
                    setTimeout(() => {
                        navigate(`/`);
                    }, 2000);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    setSweetAlert({
                        ...sweetAlert,
                        value: true,
                        messge: "Otp is Invalid!",
                    });
                    setIsLoading(false);
                }
            });
    };

    return (
        <div className="h-screen flex items-center justify-center bg-[#02497f]">
            {isLoading ? (
                <ActivityIndicator />
            ) : (
                <div className="border-2 shadow-lg  w-[400px] p-6 bg-white">
                    <SweetAlert
                        message={sweetAlert.messge}
                        isOpen={sweetAlert.value}
                        onClose={() => setSweetAlert({ ...sweetAlert, value: false })}
                    />
                    <div className="flex flex-col items-center justify-center">
                        <img src={logo} className="h-32" />
                        <div className="text-blue-950 font-bold text-[25px] font-mono">
                            Srinivas university
                        </div>

                    </div>
                    <div className="mb-4 mt-2">
                        <div className="text-sm font-semibold  text-blue-800">New Password</div>
                        <input
                            type="text"
                            placeholder="********"
                            value={newPassword}
                            name="newPassword"
                            onChange={(e: any) => handleChange(e)}
                            className="h-8 border outline-none w-full pl-2 text-sm"
                        />
                        <div className="text-sm text-red-500">
                            {formErrors?.newPassword}
                        </div>
                    </div>
                    <div className="mb-4 mt-2">
                        <div className="text-sm font-semibold text-blue-800">Confirm Password</div>
                        <input
                            type="text"
                            placeholder="********"
                            value={confirmPassword}
                            name="confirmPassword"
                            onChange={(e: any) => handleChange(e)}
                            className="h-8 border outline-none w-full pl-2 text-sm"
                        />
                        <div className="text-sm text-red-500">
                            {formErrors?.confirmPassword}
                        </div>
                    </div>
                    <div className="mb-4 mt-2">
                        <div className="text-sm font-semibold  text-blue-800">OTP</div>
                        <input
                            type="text"
                            placeholder="123456"
                            value={otp}
                            name="otp"
                            onChange={(e: any) => handleChange(e)}
                            className="h-8 border outline-none w-full pl-2 text-sm"
                        />
                        <div className="text-sm text-red-500">
                            {formErrors?.otp}
                        </div>
                    </div>

                    <div
                        onClick={() => handleSubmit()}
                        className="bg-[blue] mt-4 shadow-lg text-white font-semibold text-md px-1 flex items-center justify-center py-1 cursor-pointer rounded-sm"
                    >
                        Submit
                    </div>
                    <div onClick={() => navigate("/forgotPassword")} className="text-center mt-2 cursor-pointer text-sm font-semibold mb-2 text-blue-800">Back</div>

                </div>
            )
            }
        </div>
    );
};

export default ResetPassword;
