import React, { useEffect, useState } from "react";
import logo from "../../assets/sr_logo.gif";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../service/service";
import { updateRole, updateSnackbar, updateToken, updateUserInformation } from "../../redux/action";
import { snackBarColors } from "../../config/constants";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";

const AdminLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loginStatus, setLoginStatus] = useState({
        text: "Login Now",
        status: false,
    });
    const [state, setState] = useState({
        username: "",
        password: "",
    });
    const [isSubmit, setIsSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const { username, password } = state;
    const [isLoading, setIsLoading] = useState(false)
    const handleChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const validate = () => {
        const error: any = {};
        const emailvalidation = /\S+@\S+\.\S+/.test(username);

        if (!username) {
            error.username = "Please enter your username";
        } else if (!emailvalidation) {
            error.username = "Please enter valid username";
        }

        if (!password) {
            error.password = "Please enter your password";
        }

        return error;
    };
    const handleSubmit = () => {
        setFormErrors(validate());
        setIsSubmit(true);
    };

    useEffect(() => {
        let obj = {
            UserName: username,
            Password: password,
            LoginAs: "Admin",
            mobileNumber: "9874563210",
        };
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            doLogin(obj);
        }
    }, [formErrors]);

    const doLogin = (data: any) => {
        setIsLoading(true)
        setLoginStatus({ ...loginStatus, text: "Please wait...", status: true });
        axios
            .post(`${baseUrl}/Admin/Login`, data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updateToken(response.data.accessToken));
                    dispatch(updateRole("Admin"));
                    dispatch(updateUserInformation(response.data))
                    navigate("/admin_home");
                    setLoginStatus({
                        ...loginStatus,
                        text: "Login Now",
                        status: false,
                    });
                    const obj = {
                        value: true,
                        message: "Login success!",
                        type: snackBarColors.success,
                    }
                    dispatch(updateSnackbar(obj))
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                const obj = {
                    value: true,
                    message: "Invalid username or password",
                    type: snackBarColors.error,
                }
                dispatch(updateSnackbar(obj))
                setIsLoading(false)

            });
    };

    return (
        <div className="h-screen flex items-center justify-center bg-[#02497f]">
            {isLoading ? <ActivityIndicator /> : null}
            <div className="border-2 shadow-lg  w-[400px] p-6 bg-white">
                <div className="flex flex-col items-center justify-center">
                    <img src={logo} className="h-32" />
                    <div className="text-blue-950 font-bold text-[25px] font-mono">
                        Srinivas university
                    </div>
                    <div className="text-lg font-semibold text-blue-950 bg-[gold] w-full flex items-center justify-center p-2">
                        Admin Login
                    </div>
                </div>
                <div className="mb-4 mt-2 text-sm">
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        name="username"
                        onChange={(e: any) => handleChange(e)}
                        className="h-10 border outline-none w-full pl-2"
                    />
                    <div className="text-sm text-red-500">
                        {formErrors?.username}
                    </div>
                </div>

                <div className="my-4 text-sm">
                    <input
                        type="password"
                        value={password}
                        name="password"
                        onChange={(e: any) => handleChange(e)}
                        placeholder="Password"
                        className="h-10 border outline-none w-full pl-2"
                    />
                    <div className="text-sm text-red-500">
                        {formErrors?.password}
                    </div>
                </div>

                <div
                    onClick={() => handleSubmit()}
                    className="bg-[blue] mt-4 shadow-lg text-white font-semibold text-md px-1 flex items-center justify-center py-2 cursor-pointer rounded-sm"
                >
                    {loginStatus.text}
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
