import React from "react";

export const studentMenu = [
  {
    name: "Dashboard",
    url: "/student/student_home",
    icon: <i className="fa-solid fa-house"></i>,
  },
  {
    name: "Notification",
    url: "/student/student_notification",
    icon: <i className="fa-solid fa-bell"></i>,
  },
  {
    name: "Document Upload",
    url: "/document_upload/student",
    icon: <i className="fa-solid fa-cloud-arrow-up"></i>
  }, {
    name: "Publication",
    url: "/publication/dummy",
    icon: <i className="fa-solid fa-book"></i>,
  },

  {
    name: "Profile",
    url: "/student/student_profile",
    icon: <i className="fa-solid fa-user"></i>,
  },
];

export const guideMenu = [
  {
    name: "Dashboard",
    url: "/guide/guide_home",
    icon: <i className="fa-solid fa-house"></i>,
  },
  {
    name: "Notification",
    url: "/student/student_notification",
    icon: <i className="fa-solid fa-bell"></i>,
  },
  {
    name: "Students",
    url: "/guide/students",
    icon: <i className="fa-solid fa-users"></i>,
  },
  {
    name: "Document Upload",
    url: "/document_upload/guide",
    icon: <i className="fa-solid fa-cloud-arrow-up"></i>
  },
  {
    name: "Publication",
    url: "/publication/dummy",
    icon: <i className="fa-solid fa-book"></i>,
  },

  {
    name: "Profile",
    url: "/guide/guide_profile",
    icon: <i className="fa-solid fa-user"></i>,
  },

];
export const adminMenu = [
  {
    name: "Dashboard",
    url: "/admin_home",
    icon: <i className="fa-solid fa-house"></i>,
  },
  {
    name: "Guide",
    url: "/admin_guide_view",
    icon: <i className="fa-solid fa-chalkboard-user"></i>,
  },
  {
    name: "Students",
    url: "/admin_student_view",
    icon: <i className="fa-solid fa-users"></i>,
  },
  {
    name: "Notification",
    url: "/admin_notification",
    icon: <i className="fa-solid fa-bell"></i>,
  },
  {
    name: "Publication",
    url: "/publication/dummy",
    icon: <i className="fa-solid fa-book"></i>
  },
  {
    name: "Profile",
    url: "/admin_profile",
    icon: <i className="fa-solid fa-user"></i>,
  },

];
