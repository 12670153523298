import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import instance from "../../service/axiosInstane";
import { snackBarColors } from "../../config/constants";
import { updateSnackbar } from "../../redux/action";
import { useDispatch } from "react-redux";

const CreateGuide = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [formError, setFormError] = useState<any>({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        username: "",
        email: "",
        password: "",
        phonenumber: "",
    });
    const { username, email, phonenumber, password } = state;

    const handleChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const validate = () => {
        const error: any = {};
        const emailvalidation = /\S+@\S+\.\S+/.test(email);
        if (!username) {
            error.username = "Please enter your username";
        }
        if (!password) {
            error.password = "Please enter your password";
        } else if (password.length < 6) {
            error.password = "Password must be minimum 6 characters";
        }
        if (!email) {
            error.email = "Please enter your email";
        } else if (!emailvalidation) {
            error.email = "Please enter valid email";
        }
        if (!phonenumber) {
            error.phonenumber = "Please enter your phonenumber";
        }

        return error;
    };
    const handleSubmit = () => {
        setFormError(validate());
        setIsSubmit(true);
    };

    useEffect(() => {
        let obj = {
            UserName: username,
            Email: email,
            Password: password,
            PhoneNumber: phonenumber,
            Role: "Guide",
            GuideId: "",
            Status: true,
            CreatedOn: new Date(),
            UpdatedDate: new Date(),
        };
        if (Object.keys(formError).length === 0 && isSubmit) {
            console.log(state);
            doRegister(obj);
        }
    }, [formError]);
    const doRegister = async (obj: any) => {
        setIsLoading(true);
        await instance
            .post(`/account/Registration`, obj)
            .then((response) => {
                if (response.status === 200) {
                    const obj = {
                        value: true,
                        message: "Guide registered successfully!",
                        type: snackBarColors.success,
                    }
                    dispatch(updateSnackbar(obj))

                    setIsLoading(false);
                    setTimeout(() => {
                        navigate(`/admin_guide_view`);
                    }, 2000);
                }
            })
            .catch((err) => {
                const obj = {
                    value: true,
                    message: "Sorry! Email is already exist!",
                    type: snackBarColors.error,
                }
                dispatch(updateSnackbar(obj))
                setIsLoading(false);

            });
    };

    return (
        <>
            {isLoading ?
                <ActivityIndicator /> : null}
            <div className="bg-white shadow-lg p-4">
                <div className="font-semibold text-[24px] text-sky-700">
                    Create Guide
                </div>

                <div className="bg-[gold] h-1 mt-1"></div>
                <div className="font-semibold text-lg">
                    Please register guide here.
                </div>
                <div>
                    <Input
                        lable="Username"
                        name="username"
                        value={username}
                        onChange={(e: any) => handleChange(e)}
                    />
                    <div className="text-[red] text-sm">{formError?.username}</div>
                    <Input
                        lable="Email"
                        name="email"
                        value={email}
                        onChange={(e: any) => handleChange(e)}
                    />{" "}
                    <div className="text-[red] text-sm">{formError?.email}</div>
                    <Input
                        lable="Password"
                        name="password"
                        value={password}
                        onChange={(e: any) => handleChange(e)}
                    />{" "}
                    <div className="text-[red] text-sm">{formError?.password}</div>
                    <Input
                        lable="Mobile Number"
                        name="phonenumber"
                        value={phonenumber}
                        onChange={(e: any) => handleChange(e)}
                    />{" "}
                    <div className="text-[red] text-sm">{formError?.phonenumber}</div>
                    <div className="flex items-center space-x-6  mt-5">
                        <Button
                            lable="create"
                            type="button"
                            buttonType="primary"
                            onClick={() => handleSubmit()}
                        />
                        <Button
                            lable="cancel"
                            type="button"
                            buttonType="danger"
                            onClick={() => navigate("/admin_guide_view")}
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

export default CreateGuide;
