import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSnackbar, update_breadcrumb } from "../../redux/action";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import Dropdown from "../../components/Dropdown/Dropdown";
import instance from "../../service/axiosInstane";
import { snackBarColors } from "../../config/constants";
import TextArea from "../../components/TextArea/TextArea";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";

const guideOption = [
    { value: "allStudent", name: "All Student" },
    { value: "singleStudent", name: "Single Student" },
];
const adminOption = [
    { value: "allStudent", name: "All Student" },
    { value: "allGuide", name: "All Guide" },
    { value: "allGuideAndStudent", name: "All Guide And Student" },
    { value: "singleStudent", name: "Single Student" },
    { value: "singleGuide", name: "Single Guide" },
];
const SendNotification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const role = useSelector((state: any) => state.userRole);
    const userinformation = useSelector((state: any) => state.userInfo);

    const [state, setState] = useState<any>({
        subject: "",
        body: "",
        date: new Date(),
        createdBy: role === "Guide" ? userinformation.userId : 1,
        Type: "",
        Ids: "",
    });
    const [isLoading, setIsLoading] = useState<any>(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const [isSubmit, setIsSubmit] = useState(false);
    const { subject, body, date, createdBy, Type, Ids } = state;
    const validate = () => {
        const errors: any = {};
        if (!subject) {
            errors.subject = "Please enter subject";
        }
        if (!body) {
            errors.body = "Please enter body";
        }
        if (!Type) {
            errors.Type = "Please enter Type";
        }

        return errors;
    };
    const handleChangeText = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const hadleSubmit = () => {
        setFormErrors(validate());
        setIsSubmit(true);
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            sendNotification();
        }
    }, [formErrors]);
    const sendNotification = async () => {
        setIsLoading(true);
        await instance
            .post(`/Admin/notification`, state)
            .then((response) => {
                if (response.status === 200) {
                    const obj = {
                        value: true,
                        message: "Notification sent successfully!",
                        type: snackBarColors.success,
                    };
                    dispatch(updateSnackbar(obj));

                    setIsLoading(false);
                    if (role === "Guide") {
                        navigate("/student/student_notification");
                    } else {
                        navigate("/admin_notification");
                    }
                }
            })
            .catch((err) => {
                console.log(err, "eoor")
                const obj = {
                    value: true,
                    message: "Something went wrong!",
                    type: snackBarColors.error,
                };
                dispatch(updateSnackbar(obj));
                setIsLoading(false);
            });
    };
    useEffect(() => {
        const breadcrumb = [
            { path: "/student/student_notification", name: "Notification" },
        ];
        dispatch(update_breadcrumb(breadcrumb));
    }, []);
    return (
        <>
            {isLoading ? <ActivityIndicator /> : null}
            <div className="bg-white shadow-lg p-4">
                <div className="flex items-center justify-between font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
                    <div>Create Notification</div>
                </div>
                <div>
                    <div className="my-1">
                        <Input
                            width="w-[700px]"
                            lable="Subject"
                            value={subject}
                            type="text"
                            name="subject"
                            onChange={handleChangeText}
                            error={formErrors.subject}
                        />
                    </div>
                    <div className="my-1">
                        <TextArea
                            width="w-[700px]"
                            height="h-[120px]"
                            lable="Body"
                            type="text"
                            name="body"
                            value={body}
                            onChange={handleChangeText}
                            error={formErrors.body}
                        />
                    </div>

                    <Dropdown
                        lable="Notification To"
                        value={Type}
                        name={"Type"}
                        onChange={handleChangeText}
                        options={role === "Guide" ? guideOption : adminOption}
                        error={formErrors.Type}
                    />
                    <div className="my-1">
                        <Input
                            lable="Student/GuideID"
                            value={Ids}
                            type="text"
                            name="Ids"
                            onChange={handleChangeText}
                            error={formErrors.Ids}
                        />
                    </div>
                    <div className="flex items-center space-x-4 px-2 mt-5">
                        <Button
                            lable="Send"
                            type="button"
                            buttonType="primary"
                            onClick={() => hadleSubmit()}
                        />
                        <Button
                            lable="cancel"
                            type="button"
                            buttonType="danger"
                            onClick={() => navigate(`${role === "Guide" ? "/student/student_notification" : "/admin_notification"}`)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SendNotification;
