import { AnyAction } from "redux";
import {
  INCREMENT,
  DECREMENT,
  UPDATESIDEBARTAB,
  UPDATE_BREADCRUMB,
  UPDATE_IS_LOADER,
  UPDATE_TOKEN,
  UPDATE_USER_INFORMATION,
  UPDATE_TAB,
  UPDATE_USER_ROLE,
  UPDATE_STUDENT_TYPE,
  UPDATE_GUIDE_INFO,
  UPDATE_SNACKBAR,
  PROFILE_PIC_CHANGE
} from "../action";

export interface StateProps {
  counter: number;
  isLoading: boolean;
  defaultSideBarTab: string;
  resposiveSidebar: boolean;
  crumbs: [{ path: string; name: string }];
  token: string;
  userInfo:
  {
    userId: number | string;
    userName: string;
    emailId: string;
    expires_In: string;
    accessToken: string;
    mobilePhone: string;
    Profile: null
  }

  currentTab: number;
  userRole: string;
  studenType: string;
  guideInfo: {
    guideName: string,
    guideId: string
  }
  snackBar: {
    value: boolean,
    message: string,
    type: string,
  }
  profilePicChange: boolean

}

const initialState: StateProps = {
  counter: 1000,
  isLoading: false,
  defaultSideBarTab: "Dashboard",
  resposiveSidebar: false,
  crumbs: [{ path: "", name: "" }],
  token: "",
  userInfo:
  {
    userId: 0,
    userName: "0",
    emailId: "",
    expires_In: "",
    accessToken: "",
    mobilePhone: "",
    Profile: null,
  },

  currentTab: 1,
  userRole: "",
  studenType: "",
  guideInfo: {
    guideName: '',
    guideId: ''
  },
  snackBar: {
    value: false,
    message: "",
    type: "",
  },
  profilePicChange: false
};

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case INCREMENT:
      return { ...state, counter: state.counter + 1 };
    case DECREMENT:
      return { ...state, counter: state.counter - 1 };
    case UPDATE_BREADCRUMB:
      return {
        ...state,
        crumbs: action.payload,
      };

    case UPDATESIDEBARTAB:
      return {
        ...state,
        defaultSideBarTab: action.payload,
      };
    case UPDATE_IS_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case UPDATE_USER_INFORMATION:
      return {
        ...state,
        userInfo: action.payload,
      };
    case UPDATE_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case UPDATE_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };

    case UPDATE_STUDENT_TYPE:
      return {
        ...state,
        studenType: action.payload,
      };

    case UPDATE_GUIDE_INFO:
      return {
        ...state,
        guideInfo: action.payload,
      };

    case UPDATE_SNACKBAR:
      return {
        ...state,
        snackBar: action.payload,
      };
    case PROFILE_PIC_CHANGE:
      return {
        ...state,
        profilePicChange: action.payload,
      }

    default:
      return state;
  }
};
export default reducer;
