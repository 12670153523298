import { error } from "console";
import React from "react";

interface InputProps {
    lable: string;
    placeholder?: string;
    value?: string | number;
    onChange?: any;
    name?: string;
    error?: string;
    options?: any
}

const Dropdown = (props: InputProps) => {
    const { lable, value, onChange, name, error, options } = props;
    return (
        <div className="my-1">
            <div className="text-sky-900 font-semibold  mb-1 text-sm">{lable}</div>
            <select
                className="w-[300px] h-8 outline-none border pl-2  text-sm cursor-pointer focus:border-[#83b8f5] "
                value={value}
                name={name}
                onChange={onChange}>
                <option value={""} >{"Select"}</option>
                {options?.map((item: any, index: any) => {
                    return (
                        <option key={index} value={item.value}>{item.name}</option>
                    )
                })}
            </select>
            <div className="text-[red] text-sm">{error}</div>
        </div>
    );
};

export default Dropdown;