import logo from "../../assets/banner.png";
import user from "../../assets/1.png";
import { FaSignOutAlt } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import Popover from "@mui/material/Popover";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ResponsiveSidebar from "../ResponsiveSidebar/ResponsiveSidebar";
import { colors } from "../../config/constants";
import { useSelector } from "react-redux";
import { persistor } from "../../redux/store";
import instance from "../../service/axiosInstane";
import { stat } from "fs";
import { userInfo } from "os";

const Header = () => {
  const navigate = useNavigate();
  const userinformation = useSelector((state: any) => state.userInfo);
  const role = useSelector((state: any) => state.userRole);
  const imageUrl = "http://api.suresearchsoftware.com/Images/ProfileImages/";
  const profileData = [
    {
      name: "Logout",
      url: role === 'Admin' ? "/admin_login" : "/",
      icon: <FaSignOutAlt className="mr-2" />,
      onClick: () => handleLogOut(),
    },
  ];
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState<any>([])
  const [profileanchorEl, setProfileAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [shortcutanchorEl, setShotCutAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleShortCutClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShotCutAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };
  const handleShortcutClose = () => {
    setShotCutAnchorEl(null);
  };
  const openprofile = Boolean(profileanchorEl);
  const openshortcut = Boolean(shortcutanchorEl);
  const id_profile = openprofile ? "simple-popover" : undefined;
  const id_shortcut = openshortcut ? "simple-popover2" : undefined;

  const onClickProfile = (url: string) => {
    handleClose();
    navigate(url);
  };

  const handleLogOut = async () => {
    await persistor.purge();
    if (role === "Admin") {
      navigate("/admin_login");
    } else {
      navigate("/");
    }

  };
  const handleChangePassword = () => {
    navigate("/changepassword");
    handleClose();
  };


  const userNameHandler = () => {
    const result = userinformation.userName.length < 10 ? userinformation.userName : userinformation.userName.substring(0, 10)
    return result
  }

  return (
    <div className="h-16 w-full flex items-center justify-between shadow-sm bg-[#02497f] z-10 border-b border-[#02134d]">
      <div className="flex items-center  sm:justify-start cursor-pointer ">
        <ResponsiveSidebar />
        <div className="sm:w-60">
          <img src={logo} className="h-[60px]  text-lg sm:ml-6" />
        </div>
        <div className="text-white text-[26px] font-bold ml-4 hidden sm:block">
          Srinivas University
        </div>
      </div>

      <div className="h-16 sm:w-96 flex items-center sm:justify-between justify-end">
        <div className="h-16 w-1/2 sm:flex hidden items-center justify-center text-[#02134d] border-r-2 border-slate-300">
          <button className="flex items-center" onClick={handleShortCutClick}>
            <IoIosNotifications
              color="white"
              size={25}
              className="ml-2 cursor-pointer "
            />
          </button>{" "}
        </div>

        <div>
          <button
            className="flex sm:items-center text-white"
            onClick={handleClick}
          >
            <div className="sm:flex hidden text-sm uppercase">{role !== "Admin" ? userNameHandler() : "Admin"}</div>
            <div>
              <img
                src={(role === "Admin" || userinformation.Profile === null) ? user : imageUrl + userinformation.Profile}
                height={40}
                width={40}
                className="rounded-full mx-4 h-12 w-12"
              />
            </div>
          </button>{" "}
          <div>
            <Popover
              className="mt-[14px] mr-2 ml-0 cursor-pointer"
              id={id_profile}
              open={openprofile}
              anchorEl={profileanchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="w-[180px] text-slate-900">
                {profileData.map((item, index) => {
                  return (
                    <div
                      onClick={item.onClick}
                      key={index}
                      className={`flex items-center p-2 border-b-2 border-slate-100 hover:${colors.primary} hover:text-white`}
                    >
                      {item.icon}
                      <div className="text-sm">{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
