import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";

const SentNotification = () => {
    const [state, setState] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<any>(true);
    const userinformation = useSelector((state: any) => state.userInfo);

    useEffect(() => {
        getNotification();
    }, []);
    const getNotification = async () => {
        setIsLoading(true);
        await instance
            .get(`/Admin/GetallNotification?UserId=${userinformation.userId}`)
            .then((response) => {
                if (response.status === 200) {
                    setState(response.data);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };
    return (
        <>
            {isLoading ? <ActivityIndicator /> : null}
            {state?.length > 0 ? <div>
                {state?.map((item: any, index: number) => {
                    return (
                        <div className="border my-1 p-1" key={index}>
                            <div className="font-semibold text-blue-900">{item.subject}</div>
                            <div className="text-sm my-1">{item.body}</div>
                        </div>
                    );
                })}
            </div> : <div>No records found!</div>}

        </>
    );
};

export default SentNotification;
