import logo from "../../assets/sr_logo.gif";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { StateProps } from "../../redux/reducer";
import { updateSidebarTab } from "../../redux/action";
import { useEffect, useState } from "react";
import { colors } from "../../config/constants";
import { adminMenu, guideMenu, studentMenu } from "../../config/sidebarItems";
import React from "react";
const Sidebar = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state: StateProps) => state.defaultSideBarTab);
  const userRole = useSelector((state: StateProps) => state.userRole);
  const [menuitems, setMenuItems] = useState(studentMenu);

  useEffect(() => {
    const currentpath = window.location.href.split("/");
    if (currentpath[currentpath.length - 1] === "student_home") {
      dispatch(updateSidebarTab("Dashboard"));
    }
    if (currentpath[currentpath.length - 1] === "guide_home") {
      dispatch(updateSidebarTab("Dashboard"));
    }
  }, []);

  useEffect(() => {
    if (userRole === "Student") {
      setMenuItems(studentMenu);
    } else if ((userRole === "Guide")) {
      setMenuItems(guideMenu);
    } else if (userRole === "Admin") {
      setMenuItems(adminMenu);
    }
  }, []);
  const handleOnclick = (item: string) => {
    dispatch(updateSidebarTab(item));
  };

  return (
    <div className="bg-white ">
      <div className="hidden sm:block w-52 shadow-md px-4 min-h-screen mb-2  py-4">
        <div
          className={`flex space-x-2 items-center w-full justify-between border border-[#02497f] text-[#02497f] shadow-lg  rounded-sm `}
        >
          <div className="p-1">
            <img src={logo} width={60} height={60} className="my-1" />
          </div>
          <div>
            <div className="font-bold pb-2">Srinivas University</div>
          </div>
        </div>

        {menuitems.map((item, index) => {
          return (
            <Link
              to={item.url}
              onClick={() => handleOnclick(item.name)}
              key={index}
              className={`${activeTab === item.name
                ? `${colors.primary} text-white`
                : `bg-[#e9eeff]`
                } text-[gray] hover:text-white hover:bg-[#02497f] flex  items-center p-1 my-1 cursor-pointer rounded-sm`}
            >
              <div className="w-8"> {item.icon}</div>
              <div className="text-sm">{item.name}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
