import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MuiTable from "../../../components/MuiTable/MuiTable";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import { update_breadcrumb } from "../../../redux/action";

const DocumentUpload = () => {
    const { user } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userinformation = useSelector((state: any) => state.userInfo);
    const [state, setState] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const columns = [
        { id: "docTitle", label: "docTitle", minWidth: 100, align: "left" },
        {
            id: "docType",
            label: "docType",
            minWidth: 100,
            align: "left",
        },
        {
            id: "createdBy",
            label: "createdBy",
            minWidth: 100,
            align: "left",
        },
        {
            id: "createdOn",
            label: "createdOn",
            minWidth: 100,
            align: "left",
        },
        {
            id: "action",
            label: "View Document",
            minWidth: 100,
            align: "left",
        },
    ];
    useEffect(() => {
        const breadcrumb = [
            { path: `/document_upload/${user}`, name: "Document upload" },
        ];
        dispatch(update_breadcrumb(breadcrumb));
        getDocumentList();
    }, []);
    const getDocumentList = async () => {
        setIsLoading(true);
        await instance
            .get(`Students/GetdocUploadStuden?UserId=${userinformation.userId}`)
            .then((response) => {
                if (response.status === 200) {
                    setState(response.data);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("error", err);
                setIsLoading(false);
            });
    };
    const viewRecord = (arg: string | number) => {
        window.open(`http://api.suresearchsoftware.com/Images/docUpload/${arg}`);
    };
    return (
        <>
            {isLoading ? <ActivityIndicator /> : null}
            <div className="bg-white shadow-lg p-4 ">
                <div className="font-semibold text-[24px] text-sky-700">
                    Document Upload
                </div>
                <div className="bg-[gold] h-1 mt-1"></div>
                <div className="flex items-center justify-between mt-1">
                    <div className="font-semibold text-lg">
                        Please upload document here.
                    </div>
                    <div
                        onClick={() => navigate(`/document_add/${user}`)}
                        className="font-semibold bg-[gold] w-fit px-3 text-sm py-1 cursor-pointer"
                    >
                        Upload
                    </div>
                </div>
                <div className="mt-2">
                    <MuiTable
                        columns={columns}
                        onViewHandler={viewRecord}
                        rows={state}
                        searchColumn={"docTitle"}
                        primaryKey={"docUrl"}
                    />
                </div>
            </div>
        </>
    );
};

export default DocumentUpload;
