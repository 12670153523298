import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import { updateSnackbar, updateStudenType } from "../../../redux/action";
import instance from "../../../service/axiosInstane";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { PDF, PHD, DSC } from "../../../config/studentType";
import ProgressBar from "../../../components/ProgreesBar/ProgressBar";
import { Capitalization } from "../../../config/helper";
import TextArea from "../../../components/TextArea/TextArea";
import { snackBarColors } from "../../../config/constants";
const StudentDetailForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action } = useParams();
  const userinformation = useSelector((state: any) => state.userInfo);
  const queryParameters = new URLSearchParams(window.location.search);
  const role = useSelector((state: any) => state.userRole);
  const studentID = queryParameters.get('studentId');
  const [formErrors, setFormErrors] = useState<any>({});
  const [phdFormErrors, setPhdFormErrors] = useState<any>({});
  const [pdfFormErrors, setPdfFormErrors] = useState<any>({});
  const [dscFormErrors, setDscFormErrors] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [studentDetails, setStudentDetails] = useState<any>({
    aadhaarNo: "",
    applicationDate: "",
    atomicResearchCenter: "",
    candidateName: "",
    category: "",
    cgpa: null,
    communicationAddress: "",
    emailId: "",
    entranceExamDate: new Date(),
    fatherName: "",
    firstAurthor: "",
    googleScholarID: "",
    guideName: "",
    hIndex: "",
    indexValue: "",
    interviewDate: new Date(),
    isSubmitted: true,
    localityType: "",
    mentorName: "",
    microIncubationCenter: "",
    netType: "",
    panNo: "",
    permanentAddress: "",
    phdAwardedSubject: "",
    phdSubject: "",
    phdType: "",
    phoneNo: "",
    profileUrl: "",
    qualifiedExam: "",
    registrationDate: new Date(),
    registrationNo: "",
    studentType: "",
    totalExperience: null,
    totalPublication: null,
    universityName: "",
    vidwanID: "",
    yearOfExam: null,
    userId: action === "admin" ? 143 : userinformation.userId,
  });
  const {
    aadhaarNo,
    applicationDate,
    atomicResearchCenter,
    candidateName,
    category,
    cgpa,
    communicationAddress,
    emailId,
    entranceExamDate,
    fatherName,
    firstAurthor,
    googleScholarID,
    guideName,
    hIndex,
    indexValue,
    interviewDate,
    localityType,
    mentorName,
    microIncubationCenter,
    netType,
    panNo,
    permanentAddress,
    phdAwardedSubject,
    phdSubject,
    phdType,
    phoneNo,
    qualifiedExam,
    registrationDate,
    registrationNo,
    studentType,
    totalExperience,
    totalPublication,
    universityName,
    vidwanID,
    yearOfExam,
    userId,
  } = studentDetails;

  useEffect(() => {
    getBasicDetails();
  }, []);

  useEffect(() => {
    if (studentType === PHD) {
      if (
        Object.keys(formErrors).length === 0 &&
        Object.keys(phdFormErrors).length === 0 &&
        isSubmit
      ) {
        if (action === "edit") {
          studentBasicDetailsUpdate();
        } else {
          submitBasicinformation();
        }
      }
    }
    if (studentType === PDF) {
      if (
        Object.keys(formErrors).length === 0 &&
        Object.keys(pdfFormErrors).length === 0 &&
        isSubmit
      ) {
        if (action === "edit") {
          studentBasicDetailsUpdate();
        } else {
          submitBasicinformation();
        }
      }
    }
    if (studentType === DSC) {
      if (
        Object.keys(formErrors).length === 0 &&
        Object.keys(dscFormErrors).length === 0 &&
        isSubmit
      ) {
        if (action === "edit") {
          studentBasicDetailsUpdate();
        } else {
          submitBasicinformation();
        }
      }
    }
  }, [formErrors, phdFormErrors, pdfFormErrors, dscFormErrors]);

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
    if (!e.target.value) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: `${Capitalization(e.target.name)} required!`,
      });
    } else {
      setFormErrors({ ...formErrors, [e.target.name]: undefined });
    }

  };

  const validateStudentDetails = () => {
    const errors: any = {};
    if (!studentType) {
      errors.studentType = `${Capitalization("student type")} required!`;
    }
    if (!candidateName) {
      errors.candidateName = `${Capitalization("candidate name")} required!`;
    }
    if (!permanentAddress) {
      errors.permanentAddress = `${Capitalization("permanent address")} required!`;
    }
    if (!communicationAddress) {
      errors.communicationAddress = `${Capitalization("communication address")} required!`;
    }
    if (!aadhaarNo) {
      errors.aadhaarNo = `${Capitalization("aadhaar number")} required!`;
    }
    if (!panNo) {
      errors.panNo = `${Capitalization("pan no")} required!`;
    }
    if (!fatherName) {
      errors.fatherName = `${Capitalization("Father name")} required!`;
    }
    if (!category) {
      errors.category = `${Capitalization("category")} required!`;
    }
    if (!phoneNo) {
      errors.phoneNo = `${Capitalization("phone number")} required!`;
    }
    if (!emailId) {
      errors.emailId = `${Capitalization("email")} required!`;
    }
    if (!universityName) {
      errors.universityName = `${Capitalization("University name")} required!`;
    }
    if (!yearOfExam) {
      errors.yearOfExam = `${Capitalization("year of exam")} required!`;
    }
    if (!netType) {
      errors.netType = `${Capitalization("net type")} required!`;
    }
    if (!atomicResearchCenter) {
      errors.atomicResearchCenter = `${Capitalization("atomic research center")} required!`;
    }
    if (!microIncubationCenter) {
      errors.microIncubationCenter = `${Capitalization("micro incubation center")} required!`;
    }
    if (!entranceExamDate) {
      errors.entranceExamDate = `${Capitalization("entrance exam date")} required!`;
    }
    if (!registrationNo) {
      errors.registrationNo = `${Capitalization("registration number")} required!`;
    }
    if (!registrationDate) {
      errors.registrationDate = `${Capitalization("registration date")} required!`;
    }
    if (!localityType) {
      errors.localityType = `${Capitalization("locality type")} required!`;
    }
    return errors;
  };
  const validatePhdFields = () => {
    const errors: any = {};
    if (!qualifiedExam) {
      errors.qualifiedExam = `${Capitalization("qualified exam")} required!`;
    }
    if (!cgpa) {
      errors.cgpa = `${Capitalization("cGPA")} required!`;
    }
    if (!phdType) {
      errors.phdType = `${Capitalization("phd type")} required!`;
    }
    return errors;
  };
  const validatePDFFields = () => {
    const errors: any = {};

    if (!googleScholarID) {
      errors.googleScholarID = `${Capitalization("google scholarID")} required!`;
    }
    if (!vidwanID) {
      errors.vidwanID = `${Capitalization("vidwan ID")} required!`;
    }
    if (!phdAwardedSubject) {
      errors.phdAwardedSubject = `${Capitalization("phd awarded subject")} required!`;
    }
    if (!guideName) {
      errors.guideName = `${Capitalization("guide name")} required!`;
    }
    if (!mentorName) {
      errors.mentorName = `${Capitalization("mentor name")} required!`;
    }
    return errors;
  };
  const validateDscFields = () => {
    const errors: any = {};
    if (!googleScholarID) {
      errors.googleScholarID = `${Capitalization("google scholar ID")} required!`;
    }
    if (!vidwanID) {
      errors.vidwanID = `${Capitalization("vidwan ID")} required!`;
    }
    if (!phdSubject) {
      errors.phdSubject = `${Capitalization("phd subject")} required!`;
    }
    if (!mentorName) {
      errors.mentorName = `${Capitalization("mentor name")} required!`;
    }
    if (!applicationDate) {
      errors.applicationDate = `${Capitalization("application date")} required!`;
    }
    if (!interviewDate) {
      errors.interviewDate = `${Capitalization("interview date")} required!`;
    }
    if (!firstAurthor) {
      errors.firstAurthor = `${Capitalization("first aurthor")} required!`;
    }
    if (!totalExperience) {
      errors.totalExperience = `${Capitalization("total experience")} required!`;
    }
    if (!hIndex) {
      errors.hIndex = `${Capitalization("h Index")} required!`;
    }
    if (!indexValue) {
      errors.indexValue = `${Capitalization("index value")} required!`;
    }
    return errors;
  };



  const hadleSubmit = () => {
    if (studentType === PHD) {
      setFormErrors(validateStudentDetails());
      setPhdFormErrors(validatePhdFields());
      setIsSubmit(true);
    } else if (studentType === PDF) {
      setFormErrors(validateStudentDetails());
      setPdfFormErrors(validatePDFFields());
      setIsSubmit(true);
    } else if (studentType === DSC) {
      setFormErrors(validateStudentDetails());
      setDscFormErrors(validateDscFields());
      setIsSubmit(true);
    } else {
      setFormErrors({ ...formErrors, studentType: "Student type required!" });
    }
  };

  const getBasicDetails = async () => {
    setIsLoading(true)
    await instance
      .get(`/Students/GetStudentDetails?UserId=${role === "Student" ? userId : studentID}`)
      .then((response) => {
        if (response.status === 200) {
          if (
            action === "create" &&
            response.data.isSubmitted === null
          ) {
            profileInformation();
          } else if (
            response.data.isSubmitted === true &&
            action === "create"
          ) {
            navigate("/student/student_fee_form/create");
          } else {
            setStudentDetails(response.data);
          }
          setIsLoading(false)
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false)
      });
  };

  const profileInformation = async () => {
    setIsSubmit(true)
    await instance
      .post("/account/GetUserProfile", { ID: role === "Student" ? userId : studentID })
      .then((response) => {
        setStudentDetails({
          ...studentDetails,
          candidateName: response.data.userName,
          emailId: response.data.emailId,
          phoneNo: response.data.phoneNumber,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const submitBasicinformation = async () => {
    setIsLoading(true)
    await instance
      .post(`/Students/StudentDetails`, studentDetails)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateStudenType(studentType));
          const obj = {
            value: true,
            message: "Student information added succesfully!",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj))
          navigate('/student/student_fee_form/create')
          setIsLoading(false)
        }
      })
      .catch((err) => {
        const obj = {
          value: true,
          message: "Something went wrong!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj))
        setIsLoading(false)
      });
  };

  const studentBasicDetailsUpdate = async () => {
    setIsLoading(true)
    await instance
      .post(`/Students/StudentDetailsUpdated?UserId=${role === "Student" ? userId : studentID}`, studentDetails)
      .then((response) => {
        if (response.status === 200) {
          const obj = {
            value: true,
            message: "Student information updated succesfully!",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj))
          dispatch(updateStudenType(studentType));
          navigate(`/student/student_profile?studentId=${response.data.userId}`);
          setIsLoading(false)
        }
      })
      .catch((err) => {
        const obj = {
          value: true,
          message: "Something went wrong!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj))
        console.log("error", err);
        setIsLoading(false)

      });
  };

  return (
    <>
      {isLoading ? <ActivityIndicator /> : null}
      <div className="bg-white shadow-lg p-4">
        <div className="font-semibold text-[24px] text-sky-700">
          Student Profile Form
        </div>
        <ProgressBar tab={1} />
        <div>
          <div className="my-1 border-b p-1">
            <div className="font-semibold text-md">Student Details</div>
          </div>
          <div className="flex justify-center px-2">
            <div className="sm:flex  sm:space-x-10">
              <div className="w-full">
                <div className="my-1">
                  <Input
                    lable="Candidate Name"
                    type="text"
                    name="candidateName"
                    value={candidateName}
                    onChange={handleChangeText}
                    error={formErrors.candidateName}
                  />
                </div>
                <div className="my-1">
                  <Dropdown
                    lable="Student Type"
                    name="studentType"
                    value={studentType}
                    onChange={handleChangeText}
                    error={formErrors.studentType}
                    options={[
                      { name: PHD, value: PHD },
                      { name: PDF, value: PDF },
                      { name: DSC, value: DSC },
                    ]}
                  />
                </div>
                <div className="my-1">
                  <TextArea
                    lable="Permanent Address"
                    type="text"
                    name="permanentAddress"
                    value={permanentAddress}
                    onChange={handleChangeText}
                    error={formErrors.permanentAddress}
                  />

                </div>
                <div className="my-1">
                  <TextArea
                    lable="Address for Communication"
                    type="text"
                    name="communicationAddress"
                    value={communicationAddress}
                    onChange={handleChangeText}
                    error={formErrors.communicationAddress}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Aadhaar Number"
                    type="text"
                    name="aadhaarNo"
                    value={aadhaarNo}
                    onChange={handleChangeText}
                    error={formErrors.aadhaarNo}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="PAN Number"
                    type="text"
                    name="panNo"
                    value={panNo}
                    onChange={handleChangeText}
                    error={formErrors.panNo}
                  />
                </div>
                <div className="my-1 ">
                  <Input
                    lable="Father’s/Husband’s Name"
                    type="text"
                    name="fatherName"
                    value={fatherName}
                    onChange={handleChangeText}
                    error={formErrors.fatherName}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Category"
                    type="text"
                    name="category"
                    value={category}
                    onChange={handleChangeText}
                    error={formErrors.category}
                  />
                </div>
                <div className="my-1 ">
                  <Input
                    lable="Phone Number"
                    type="text"
                    name="phoneNo"
                    value={phoneNo}
                    onChange={handleChangeText}
                    error={formErrors.phoneNo}
                  />
                </div>

              </div>
              <div className="w-full">
                <div className="my-1">
                  <Input
                    lable="E Mail Id"
                    type="text"
                    name="emailId"
                    value={emailId}
                    onChange={handleChangeText}
                    error={formErrors.emailId}
                    disabled={true}
                  />
                </div>

                <div className="my-1">
                  <Input
                    lable="Year Of Exam"
                    type="text"
                    name="yearOfExam"
                    value={yearOfExam}
                    onChange={handleChangeText}
                    error={formErrors.yearOfExam}
                  />
                </div>
                <div className="my-1">
                  <Dropdown
                    lable="NET/SLET/Other"
                    name="netType"
                    value={netType}
                    onChange={handleChangeText}
                    error={formErrors.netType}
                    options={[
                      { name: "NET", value: "NET" },
                      { name: "SLET", value: "SLET" },
                      { name: "GATE", value: "GATE" },
                      { name: "Other", value: "Other" },
                    ]}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Atomic Research Center"
                    type="text"
                    name="atomicResearchCenter"
                    value={atomicResearchCenter}
                    onChange={handleChangeText}
                    error={formErrors.atomicResearchCenter}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Micro Incubation center"
                    type="text"
                    name="microIncubationCenter"
                    value={microIncubationCenter}
                    onChange={handleChangeText}
                    error={formErrors.microIncubationCenter}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Date of Entrance Examination"
                    type="date"
                    name="entranceExamDate"
                    value={entranceExamDate.toString().substring(0, 10)}
                    onChange={handleChangeText}
                    error={formErrors.entranceExamDate}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Registration Number"
                    type="text"
                    name="registrationNo"
                    value={registrationNo}
                    onChange={handleChangeText}
                    error={formErrors.registrationNo}
                  />
                </div>

                <div className="my-1">
                  <Input
                    lable="Date of Registration"
                    type="date"
                    name="registrationDate"
                    value={registrationDate.toString().substring(0, 10)}
                    onChange={handleChangeText}
                    error={formErrors.registrationDate}
                  />
                </div>
                <div className="my-1">
                  <Dropdown
                    lable="Locality"
                    name="localityType"
                    value={localityType}
                    onChange={handleChangeText}
                    error={formErrors.localityType}
                    options={[
                      { name: "Karnataka", value: "Karnataka" },
                      { name: "Non Karnataka", value: "Non Karnataka" },
                      { name: "NRI", value: "NRI" },
                    ]}
                  />
                </div>
                <div className="my-1">
                  <Input
                    lable="Name of the University"
                    type="text"
                    name="universityName"
                    value={universityName}
                    onChange={handleChangeText}
                    error={formErrors.universityName}
                  />
                </div>
              </div>

              <div className="sm:flex sm:space-x-10">
                {studentType === PHD && (
                  <div className="w-full">
                    <div className="my-1">
                      <Input
                        lable="Name of Qualified Exam"
                        type="text"
                        name="qualifiedExam"
                        value={qualifiedExam}
                        onChange={handleChangeText}
                        error={phdFormErrors.qualifiedExam}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="CGPA / %"
                        type="number"
                        name="cgpa"
                        value={cgpa}
                        onChange={handleChangeText}
                        error={phdFormErrors.cgpa}
                      />
                    </div>
                    <div className="my-1">
                      <Dropdown
                        lable="Phd Type"
                        name="phdType"
                        value={phdType}
                        onChange={handleChangeText}
                        error={phdFormErrors.phdType}
                        options={[
                          { name: "Ph.D Full time", value: "fullTime" },
                          { name: "Part-time ", value: "partTime" },
                          {
                            name: "Faculty of SU/SGC",
                            value: "faculty",
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
                {studentType === PDF && (
                  <div className="w-full">
                    <div className="my-1">
                      <Input
                        lable="Google Scholar ID"
                        type="text"
                        name="googleScholarID"
                        value={googleScholarID}
                        onChange={handleChangeText}
                        error={pdfFormErrors.googleScholarID}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Vidwan ID"
                        type="text"
                        name="vidwanID"
                        value={vidwanID}
                        onChange={handleChangeText}
                        error={pdfFormErrors.vidwanID}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Ph.D. Awarded Subject"
                        type="text"
                        name="phdAwardedSubject"
                        value={phdAwardedSubject}
                        onChange={handleChangeText}
                        error={pdfFormErrors.phdAwardedSubject}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Name of the Guide"
                        type="text"
                        name="guideName"
                        value={guideName}
                        onChange={handleChangeText}
                        error={pdfFormErrors.guideName}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Name of the mentor"
                        type="text"
                        name="mentorName"
                        value={mentorName}
                        onChange={handleChangeText}
                        error={pdfFormErrors.mentorName}
                      />
                    </div>
                  </div>
                )}
                {studentType === DSC && (
                  <div className="w-full">
                    <div className="my-1">
                      <Input
                        lable="Google Scholar ID"
                        type="text"
                        name="googleScholarID"
                        value={googleScholarID}
                        onChange={handleChangeText}
                        error={dscFormErrors.googleScholarID}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Vidwan ID"
                        type="text"
                        name="vidwanID"
                        value={vidwanID}
                        onChange={handleChangeText}
                        error={dscFormErrors.vidwanID}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Subject of Ph.D. Degree"
                        type="text"
                        name="phdSubject"
                        value={phdSubject}
                        onChange={handleChangeText}
                        error={dscFormErrors.phdSubject}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Name of the mentor"
                        type="text"
                        name="mentorName"
                        value={mentorName}
                        onChange={handleChangeText}
                        error={dscFormErrors.mentorName}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Date of Application"
                        type="date"
                        name="applicationDate"
                        value={applicationDate.toString().substring(0, 10)}
                        onChange={handleChangeText}
                        error={dscFormErrors.applicationDate}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Date Of Interview"
                        type="date"
                        name="interviewDate"
                        value={interviewDate}
                        onChange={handleChangeText}
                        error={dscFormErrors.interviewDate}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Total Publications"
                        type="text"
                        name="totalPublication"
                        value={totalPublication}
                        onChange={handleChangeText}
                        error={dscFormErrors.totalPublication}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="First Author Publications"
                        type="text"
                        name="firstAurthor"
                        value={firstAurthor}
                        onChange={handleChangeText}
                        error={dscFormErrors.firstAurthor}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="Total Experience"
                        type="text"
                        name="totalExperience"
                        value={totalExperience}
                        onChange={handleChangeText}
                        error={dscFormErrors.totalExperience}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="H index"
                        type="text"
                        name="hIndex"
                        value={hIndex}
                        onChange={handleChangeText}
                        error={dscFormErrors.hIndex}
                      />
                    </div>
                    <div className="my-1">
                      <Input
                        lable="I – 10 Index"
                        type="text"
                        name="indexValue"
                        value={indexValue}
                        onChange={handleChangeText}
                        error={dscFormErrors.indexValue}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center space-x-4 px-2 mt-5">
            <Button
              lable="submit"
              type="button"
              buttonType="primary"
              onClick={() => hadleSubmit()}
            />
            <Button
              lable="cancel"
              type="button"
              buttonType="danger"
              onClick={() => navigate(`/student/student_profile?studentId=${studentID}`)}
            />
          </div>
        </div>{" "}
      </div></>

  );
};

export default StudentDetailForm;
