import { error } from "console";
import React from "react";

interface InputProps {
  lable: string;
  placeholder?: string;
  value?: string | number;
  type?: string;
  onChange?: any;
  name?: string;
  error?: string;
  width?: any;
  disabled?: boolean
}

const Input = (props: InputProps) => {
  const { lable, placeholder, value, type, onChange, name, error, width, disabled } = props;
  return (
    <div className="my-1">
      <div className="text-sky-900  mb-1 text-sm font-semibold">{lable}</div>
      <input
        required
        type={type}
        className={`${width ? width : 'w-[300px]'} h-8 outline-none border pl-2 text-sm focus:border-[#83b8f5]`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        disabled={disabled}
      />
      <div className="text-[red] text-sm">{error}</div>
    </div>
  );
};

export default Input;
