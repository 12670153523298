import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import Button from "../../components/Button/Button";
import instance from "../../service/axiosInstane";
import { snackBarColors } from "../../config/constants";
import { updateSnackbar } from "../../redux/action";
import { useDispatch } from "react-redux";

const AssignStudent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formError, setFormError] = useState<any>({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        guideId: 0,
        studentId: id,
        studentName: "",
    });
    const { guideId, studentId, studentName } = state;
    const [guideList, setGuideList] = useState<any>([]);

    const handleChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const validate = () => {
        const error: any = {};
        if (!guideId) {
            error.guideId = "Please select guide";
        }
        return error;
    };
    useEffect(() => {
        getguideList();
        getStudentDetails();
    }, []);

    const handleSubmit = () => {
        setFormError(validate());
        setIsSubmit(true);
    };

    useEffect(() => {
        if (Object.keys(formError).length === 0 && isSubmit) {
            console.log(state);
            doAssign();
        }
    }, [formError]);

    const doAssign = async () => {
        setIsLoading(true);
        const obj = {
            userId: studentId,
            guideId: guideId,
        };
        await instance
            .post(`/Admin/AdminAssianStudnetGuide`, obj)
            .then((response) => {
                if (response.status === 200) {
                    const obj = {
                        value: true,
                        message: "Guide assigned successfully!",
                        type: snackBarColors.success,
                    };
                    dispatch(updateSnackbar(obj));
                    setIsLoading(false);
                    navigate(`/admin_student_view`);
                }
            })
            .catch((err) => {
                const obj = {
                    value: true,
                    message: "Something went wrong!",
                    type: snackBarColors.success,
                };
                dispatch(updateSnackbar(obj));
                setIsLoading(false);
            });
    };
    const getguideList = async () => {
        setIsLoading(true);
        await instance
            .get(`Admin/GetStudentAndGuide?role=Guide`)
            .then((response) => {
                if (response.status === 200) {
                    setGuideList(response.data);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("error", err);
                setIsLoading(false);
            });
    };

    const getStudentDetails = async () => {
        setIsLoading(true);
        await instance
            .get(`Students/GetStudentDetails?userId=${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setState({ ...state, studentName: response.data.candidateName });
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("error", err);
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading ? <ActivityIndicator /> : null}
            <div className="bg-white shadow-lg p-4 ">
                <div className="font-semibold text-[24px] text-sky-700">
                    Assign Guide
                </div>

                <div className="bg-[gold] h-1 mt-1"></div>
                <div className="font-semibold text-lg">{/* Assign student. */}</div>
                <div className="my-1">
                    <div className="text-sky-900  mb-1 text-sm font-semibold">
                        {"Stuent Id"}
                    </div>
                    <div className="w-[300px] h-8 outline-none border pl-2 text-sm">
                        {id}
                    </div>
                    <div className="text-sky-900  mb-1 text-sm font-semibold">
                        {"Stuent Name"}
                    </div>
                    <div className="w-[300px] h-8 outline-none border pl-2 text-sm">
                        {studentName}
                    </div>

                    <div>
                        <div className="text-sky-900  mb-1 text-sm font-semibold">
                            Guide{" "}
                        </div>
                        <select
                            name="guideId"
                            className="w-[300px] h-8 outline-none border pl-2 text-sm cursor-pointer"
                            onChange={handleChange}
                        >
                            {guideList.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.userId}>
                                        {" "}
                                        {item.candidateName}
                                    </option>
                                );
                            })}
                        </select>
                        <div>{formError?.guideId}</div>
                    </div>
                    <div></div>
                    <div className="flex items-center space-x-6  mt-5">
                        <Button
                            lable="Assign"
                            type="button"
                            buttonType="primary"
                            onClick={() => handleSubmit()}
                        />
                        <Button
                            lable="cancel"
                            type="button"
                            buttonType="danger"
                            onClick={() => navigate("/admin_guide_view")}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AssignStudent;
