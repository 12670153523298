import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import instance from "../../../service/axiosInstane";
import { update_breadcrumb } from "../../../redux/action";
import Button from "../../../components/Button/Button";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import SentNotification from "./SentNotification";

const StudentNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(true);
  const userinformation = useSelector((state: any) => state.userInfo);
  const role = useSelector((state: any) => state.userRole);
  const [sentNotify, setSentNotify] = useState(false);
  const [recieveNotify, setReciveNotify] = useState(true);

  useEffect(() => {
    const breadcrumb = [
      { path: "/student/student_notification", name: "Notification" },
    ];
    dispatch(update_breadcrumb(breadcrumb));
    getNotification();
  }, []);

  const getNotification = async () => {
    setIsLoading(true);
    await instance
      .get(`/Admin/GetNotification?UserId=${userinformation.userId}`)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading ? <ActivityIndicator /> : null}
      <div className="bg-white shadow-lg p-4">
        <div className="flex items-center justify-between font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
          <div>Notification</div>
          {role === "Guide" && (
            <Button
              lable="Create"
              type="button"
              buttonType="primary"
              onClick={() => navigate("/admin_send_notification")}
            />
          )}
        </div>

        <div className="w-full h-10 flex items-center cursor-pointer">
          <div
            onClick={() => {
              setSentNotify(false);
              setReciveNotify(true);
            }}
            className={`${recieveNotify && "bg-sky-500 text-white"
              } w-full font-semibold h-10  items-center flex pl-2 border`}
          >
            Received Notification
          </div>
          {role === "Guide" && (
            <div
              onClick={() => {
                setSentNotify(true);
                setReciveNotify(false);
              }}
              className={`${sentNotify && "bg-sky-500 text-white"
                } w-full   font-semibold h-10 pl-2 items-center flex border`}
            >
              Sent Notification
            </div>
          )}
        </div>

        {recieveNotify && (
          <>
            {state?.length > 0 ? <div>
              {state?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="px-2 border my-1 cursor-pointer shadow-sm"
                    onClick={() =>
                      navigate(`/student/student_notification_details/${index}`)
                    }
                  >
                    <div className="font-semibold text-blue-900">
                      {item.subject}
                    </div>
                    <div className="text-sm my-1">
                      {item.body.slice(0, 120)}......
                    </div>
                  </div>
                );
              })}{" "}
            </div> : <div>No records found!</div>}

          </>
        )}
        {sentNotify && role === "Guide" && <SentNotification />}
      </div>
    </>
  );
};

export default StudentNotification;
