import React, { useEffect, useState } from "react";
import logo from "../../assets/sr_logo.gif";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../service/service";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        username: "",
    });
    const [isSubmit, setIsSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const { username } = state;
    const [snackBarOpen, setSnackBarOpen] = useState({
        value: true,
        message: "",
        type: "",
    });
    const [sweetAlert, setSweetAlert] = useState({
        value: false,
        messge: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const validate = () => {
        const error: any = {};
        const emailvalidation = /\S+@\S+\.\S+/.test(username);

        if (!username) {
            error.username = "Please enter your email";
        } else if (!emailvalidation) {
            error.username = "Please enter valid email";
        }

        return error;
    };
    const handleSubmit = () => {
        setFormErrors(validate());
        setIsSubmit(true);
    };
    console.log("isSubmit", isSubmit)
    useEffect(() => {
        let obj = {
            emailId: username,
        };
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            sendEmail(obj);
        }
    }, [formErrors]);

    const sendEmail = (data: any) => {
        setIsLoading(true);
        axios
            .post(`${baseUrl}/account/SendOTPForForgotPassword`, data)
            .then((response) => {
                if (response.status === 200) {
                    setSweetAlert({
                        ...sweetAlert,
                        value: true,
                        messge: "OTP sent successfully!",
                    });
                    setIsSubmit(false);
                    setTimeout(() => {
                        navigate(`/resetPassword/${username}`);
                    }, 2000);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (error) {
                    setSweetAlert({
                        ...sweetAlert,
                        value: true,
                        messge: "Something went wrong!",
                    });
                }
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className="h-screen flex items-center justify-center bg-[#02497f]">

                {isLoading ? (
                    <ActivityIndicator />
                ) : (
                    <div className="border-2 shadow-lg  w-[400px] p-6 bg-white">
                        <SweetAlert
                            message={sweetAlert.messge}
                            isOpen={sweetAlert.value}
                            onClose={() => setSweetAlert({ ...sweetAlert, value: false })}
                        />

                        <div className="flex flex-col items-center justify-center">
                            <img src={logo} className="h-32" />
                            <div className="text-blue-950 font-bold text-[25px] font-mono">
                                Srinivas university
                            </div>
                        </div>
                        <div className="mb-4 mt-2">
                            <div className="text-sm font-semibold mb-2 text-blue-800">
                                Enter your email to send OTP
                            </div>
                            <input
                                type="text"
                                placeholder="Bob@example.com"
                                value={username}
                                name="username"
                                onChange={(e: any) => handleChange(e)}
                                className="h-8 border outline-none w-full pl-2 text-sm"
                            />
                            <div className="text-sm text-red-500">{formErrors?.username}</div>
                        </div>

                        <div
                            onClick={() => handleSubmit()}
                            className="bg-[blue] mt-4 shadow-lg text-white font-semibold text-md px-1 flex items-center justify-center py-1 cursor-pointer rounded-sm"
                        >
                            Send
                        </div>
                        <div
                            onClick={() => navigate("/")}
                            className="text-center mt-2 cursor-pointer text-sm font-semibold mb-2 text-blue-800"
                        >
                            Back to Login
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ForgotPassword;
