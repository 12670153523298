import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import studentPhoto from "../../../assets/student.png";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { baseURL, snackBarColors } from "../../../config/constants";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import { profilePicChange, updateSnackbar } from "../../../redux/action";

const ProfilePicUpload = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [profile, setProfile] = useState<any>(null);
    const [profilePreview, setProfilePreview] = useState("");
    const userinformation = useSelector((state: any) => state.userInfo);
    const [apiUrl, setApiUrl] = useState("/Students/StudentProfile");
    const [isLoading, setIsLoading] = useState(false);
    const queryParameters = new URLSearchParams(window.location.search);
    const studentID = queryParameters.get("studentId");
    const guideId = queryParameters.get("guideId");
    const role = useSelector((state: any) => state.userRole);
    const uploadImage = (e: any) => {
        setProfile(e.target.files[0]);
        setProfilePreview(URL.createObjectURL(e.target.files[0]));
    };
    useEffect(() => {
        if (id === "guide") {
            setApiUrl("/Guide/GuideProfile");
        } else {
            setApiUrl("/Students/StudentProfile");
        }
    }, []);

    const roleChanger = () => {
        if (role === "Student") {
            return userinformation.userId
        } else if (role === "Guide" && studentID !== null) {
            return studentID
        } else if (role === "Guide" && studentID === null) {
            return userinformation.userId
        } else if (role === "Admin")
            return studentID || guideId

        else {
            return studentID
        }
    }
    const OnUpload = () => {
        if (!profile) {
            const obj = {
                value: true,
                message: "Please select Profile Photo!",
                type: snackBarColors.error,
            };
            dispatch(updateSnackbar(obj));
        }
        {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("file", profile);
            formData.append(
                "userId",
                roleChanger()
            );
            axios
                .post(`${baseURL + apiUrl}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userinformation.accessToken}`,
                        charset: "utf-8",
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (id === "student") {
                            const obj = {
                                value: true,
                                message: "Profile picture updated successfully!",
                                type: snackBarColors.success,
                            }
                            dispatch(updateSnackbar(obj));
                            navigate(`/student/student_profile?studentId=${studentID}`);
                        } else if (id === "guide") {
                            const obj = {
                                value: true,
                                message: "Profile picture updated successfully!",
                                type: snackBarColors.success,
                            }
                            dispatch(updateSnackbar(obj));
                            navigate(`/guide/guide_profile?guideId=${guideId}`);
                        }
                        setIsLoading(false);
                        dispatch(profilePicChange(true))
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    };
    return (
        <div>
            {isLoading ? <ActivityIndicator /> : null}
            <div className="bg-white shadow-lg p-4 ">
                <div className="font-semibold text-[24px] text-sky-700">
                    Profle Picture Upload
                </div>

                <div className="bg-[gold] h-1 mt-1"></div>
                <div className="font-semibold text-lg">
                    Please upload profle picture here.
                </div>
                <div className="flex flex-col h-[400px]  items-center justify-center bg-grey-lighter">
                    {profile == null ? (
                        <img
                            src={studentPhoto}
                            className="h-[200px] mb-2 shadow-lg rounded-sm border p-1"
                        />
                    ) : (
                        <img
                            src={profilePreview}
                            className="h-[200px] mb-2 shadow-lg rounded-sm border p-1"
                        />
                    )}

                    <label className="w-40 flex flex-col items-center p-1 bg-[gold] text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer">
                        <svg
                            className="w-8 h-8"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <span className="mt-2 font-semibold">Select a file</span>
                        <input
                            type="file"
                            className="hidden"
                            onChange={(e: any) => uploadImage(e)}
                        />
                    </label>
                    <div>
                        <div className="flex items-center space-x-4 px-2 mt-5">
                            <Button
                                lable="upload"
                                type="button"
                                buttonType="primary"
                                onClick={() => OnUpload()}
                            />
                            <Button
                                lable="cancel"
                                type="button"
                                buttonType="danger"
                                onClick={() =>
                                    navigate(
                                        `${role === "Student"
                                            ? "/student/student_profile"
                                            : "/guide/guide_profile"
                                        }`
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePicUpload;
