export const booksColumn = [
    { id: "bookid", label: "bookid", minWidth: 70, align: "left" },
    {
        id: "bookTitle",
        label: "bookTitle",
        minWidth: 170,
        align: "left",
    },
    {
        id: "chapterTitle",
        label: "chapterTitle",
        minWidth: 170,
        align: "left",
    },

    {
        id: "userName",
        label: "createdName",
        minWidth: 70,
        align: "left",
    },
    {
        id: "role",
        label: "role",
        minWidth: 70,
        align: "left",
    },

    {
        id: "createdOn",
        label: "createdOn",
        minWidth: 70,
        align: "left",
    },
    {
        id: "isbNumber",
        label: "isbNumber",
        minWidth: 70,
        align: "left",
    },
    {
        id: "publicationYear",
        label: "publicationYear",
        minWidth: 70,
        align: "left",
    },
    {
        id: "publisherName",
        label: "publisherName",
        minWidth: 70,
        align: "left",
    },
    {
        id: "teacherName",
        label: "teacherName",
        minWidth: 70,
        align: "left",
    },
];
export const journalColumn = [
    {
        id: "departmentTeacher",
        label: "department Teacher",
        minWidth: 170,
        align: "left",
    },
    {
        id: "paperTitle",
        label: "paperTitle",
        minWidth: 300,
        align: "left",
    },

    {
        id: "authorsName",
        label: "authorsName",
        minWidth: 300,
        align: "left",
    },
    {
        id: "doi",
        label: "doi",
        minWidth: 170,
        align: "left",
    },
    {
        id: "publicationYear",
        label: "publication Year",
        minWidth: 150,
        align: "left",
    },
    {
        id: "role",
        label: "role",
        minWidth: 70,
        align: "left",
    },
    {
        id: "issnNumber",
        label: "issnNumber",
        minWidth: 70,
        align: "left",
    },
    {
        id: "nameOfJournal",
        label: "nameOfJournal",
        minWidth: 400,
        align: "left",
    },
    {
        id: "userName",
        label: "user Name",
        minWidth: 100,
        align: "left",
    },

    {
        id: "createdOn",
        label: "createdOn",
        minWidth: 150,
        align: "left",
    },
];
export const conferenceColumn = [
    {
        id: "teacherName",
        label: "teacher Name",
        minWidth: 170,
        align: "left",
    },

    {
        id: "titleOfBookPublished",
        label: "title Of Book Published",
        minWidth: 170,
        align: "left",
    },
    {
        id: "titlePaper",
        label: "Paper Title",
        minWidth: 170,
        align: "left",
    },
    {
        id: "role",
        label: "role",
        minWidth: 70,
        align: "left",
    },
    {
        id: "proceedingsConference",
        label: "proceedings Conference",
        minWidth: 150,
        align: "left",
    },
    {
        id: "conferenceName",
        label: "conference Name",
        minWidth: 70,
        align: "left",
    },
    {
        id: "nationalOrInternational",
        label: "national Or International",
        minWidth: 70,
        align: "left",
    },
    {
        id: "publicationYear",
        label: "publication Year",
        minWidth: 150,
        align: "left",
    },
    {
        id: "isbnOrissnNumber",
        label: "isbn Or issn Number",
        minWidth: 150,
        align: "left",
    },
    {
        id: "affiliatingInstitute",
        label: "Organized By    ",
        minWidth: 200,
        align: "left",
    },
    {
        id: "publisherName",
        label: "publisher Name",
        minWidth: 150,
        align: "left",
    },

    {
        id: "createdOn",
        label: "created On",
        minWidth: 150,
        align: "left",
    },
];
export const copyrightColumn = [
    {
        id: "copyrightApplication",
        label: "copyrightApplication",
        minWidth: 170,
        align: "left",
    },

    {
        id: "status",
        label: "status",
        minWidth: 170,
        align: "left",
    },
    {
        id: "inventor",
        label: "inventor",
        minWidth: 170,
        align: "left",
    },
    {
        id: "copyrightTitle",
        label: "copyright Title",
        minWidth: 150,
        align: "left",
    },
    {
        id: "publicationYear",
        label: "publication Year",
        minWidth: 70,
        align: "left",
    },
    {
        id: "role",
        label: "role",
        minWidth: 70,
        align: "left",
    },
    {
        id: "createdOn",
        label: "createdOn",
        minWidth: 70,
        align: "left",
    },
];
export const patentColumn = [
    {
        id: "patentApplication",
        label: "patent Application",
        minWidth: 170,
        align: "left",
    },

    {
        id: "status",
        label: "status",
        minWidth: 170,
        align: "left",
    },
    {
        id: "inventor",
        label: "inventor",
        minWidth: 170,
        align: "left",
    },
    {
        id: "patentTitle",
        label: "patent Title",
        minWidth: 150,
        align: "left",
    },
    {
        id: "applicantName",
        label: "applicant",
        minWidth: 70,
        align: "left",
    },
    {
        id: "patentFiledDate",
        label: "patent Filed Date",
        minWidth: 170,
        align: "left",
    },
    {
        id: "role",
        label: "role",
        minWidth: 70,
        align: "left",
    },
    {
        id: "patentPublishedDate",
        label: "patent Published Date",
        minWidth: 170,
        align: "left",
    },
    {
        id: "patentPublicationNumber",
        label: "patent Publication Number",
        minWidth: 200,
        align: "left",
    },
    {
        id: "assigneeName",
        label: "assignee Name",
        minWidth: 120,
        align: "left",
    },
    {
        id: "publicationYear",
        label: "publication Year",
        minWidth: 150,
        align: "left",
    },

    {
        id: "createdOn",
        label: "createdOn",
        minWidth: 70,
        align: "left",
    },
];
