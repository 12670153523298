import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { update_breadcrumb } from "../../redux/action";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import Dropdown from "../../components/Dropdown/Dropdown";
import instance from "../../service/axiosInstane";
import { sweetAlertStatus } from "../../config/constants";
import TextArea from "../../components/TextArea/TextArea";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";

const AdminNotification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<any>(true);

    useEffect(() => {
        const breadcrumb = [
            { path: "/student/student_notification", name: "Notification" },
        ];
        dispatch(update_breadcrumb(breadcrumb));
        getNotification();
    }, []);
    const getNotification = async () => {
        setIsLoading(true);
        await instance
            .get(`/Admin/GetallNotification?UserId=1`)
            .then((response) => {
                if (response.status === 200) {
                    setState(response.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)

            });
    };
    return (
        <>{isLoading ? <ActivityIndicator /> : null}
            <div className="bg-white shadow-lg p-4">
                <div className="flex items-center justify-between font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
                    <div>Notification</div>
                    <Button
                        lable="Create"
                        type="button"
                        buttonType="primary"
                        onClick={() => navigate("/admin_send_notification")}
                    />
                </div>
                {state?.map((item: any, index: number) => {
                    return (
                        <div key={index}
                            className="px-2 border my-1 cursor-pointer shadow-sm"
                            onClick={() => navigate(`/notification_details/${item.subject}`)}
                        >
                            <div className="font-semibold text-blue-900">{item.subject}</div>
                            <div className="text-sm my-1">{item.body.slice(0, 120)}......</div>
                        </div>
                    )
                })}

            </div></>

    );
};

export default AdminNotification;
