import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import instance from "../../../service/axiosInstane";
import Button from "../../../components/Button/Button";
import studentPhoto from "../../../assets/guide.png";
import { updateIsLoading, updateUserInformation, update_breadcrumb } from "../../../redux/action";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";

const GuideProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const guideID = queryParameters.get("guideId");
  const role = useSelector((state: any) => state.userRole);
  const userinformation = useSelector((state: any) => state.userInfo);
  const imageUrl = "http://api.suresearchsoftware.com/Images/ProfileImages/";
  const [isLoding, setIsLoding] = useState(false);
  const [state, setState] = useState<any>({
    guideId: 0,
    name: "",
    qualification: "",
    address: "",
    pan: "",
    aadhaar: "",
    doj: "",
    dor: "",
    numberOfpublications: 0,
    yearOfaward: "0",
    googleScholarId: "",
    vidwanId: "",
    mobileNumber: "",
    totalExperience: 0,
    orchidId: "",
    areaOfresearch: "",
    emailId: "",
    candId: 0,
    profileUrl: "",
  });

  useEffect(() => {
    const breadcrumb = [
      { path: "/guide/guide_profile", name: "Guide Profile" },
    ];
    dispatch(update_breadcrumb(breadcrumb));
    GetGuideDetails();
    dispatch(updateIsLoading(false));
  }, []);
  const GetGuideDetails = async () => {
    setIsLoding(true);
    await instance
      .get(
        `/Guide/GetGuideDetails?UserId=${role === "Guide" ? userinformation.userId : guideID
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
          setIsLoding(false);

          dispatch(updateUserInformation({
            ...userinformation, userName: response.data.name,
            mobilePhone: response.data.mobileNumber,
            Profile: response.data.profileUrl
          }));
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoding(false);
      });
  };

  return (
    <>
      {isLoding ? <ActivityIndicator /> : null}
      <div className="bg-white shadow-lg p-4">
        <div className="px-1">
          <div className="font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
            Guide Profile
          </div>
        </div>

        {!state.aadhaar && !state.qualification ? (
          <div>Sorry! Profile is not Updated!</div>
        ) : (
          <div>
            <div className="mt-2 sm:flex sm:space-x-2 text-[#02497f]">
              <div>
                {state.profileUrl === null ? (
                  <img src={studentPhoto} className="h-44 w-44 border-2 p-2" />
                ) : (
                  <img
                    src={imageUrl + state.profileUrl}
                    className="h-44 w-44 border-2 p-2"
                  />
                )}

                <div
                  onClick={() => navigate(`/student/student_profile_pic/guide?guideId=${guideID}`)}
                  className="w-44 flex items-center text-black cursor-pointer justify-center font-semibold uppercase bg-[gold] h-8"
                >
                  Change Photo
                </div>
              </div>
              <div>
                <div className="flex space-x-2">
                  <div className="font-semibold  w-48">Name</div>
                  <div className="text-[#2b2929]">: {state.name}</div>
                </div>
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Email </div>
                  <div className="text-[#2b2929]">: {state.emailId}</div>
                </div>{" "}
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Qualification</div>
                  <div className="text-[#2b2929]">: {state.qualification}</div>
                </div>
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">PAN</div>
                  <div className="text-[#2b2929]">: {state.pan}</div>
                </div>
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Aadhaar</div>
                  <div className="text-[#2b2929]">: {state.aadhaar}</div>
                </div>
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Adress</div>
                  <div className="text-[#2b2929]">: {state.address}</div>
                </div>
              </div>
              <div>
                <div >
                  <div className="font-semibold  w-48">Date of Joining</div>
                  <div className="text-[#2b2929]">
                    {state.doj.substring(0, 10)}
                  </div>
                </div>
                <div className="mt-1">
                  <div className="font-semibold  w-48">Date of Recognition as Supervisor</div>
                  <div className="text-[#2b2929]">
                    {state.dor.substring(0, 10)}
                  </div>
                </div>
                <div className="mt-1">
                  <div className="font-semibold  w-52">
                    {` Number Of Publications (Journal & Conference)`}
                  </div>
                  <div className="text-[#2b2929]">
                    {state.numberOfpublications}
                  </div>
                </div>
                <div className="mt-1">
                  <div className="font-semibold w-48">Google Scholar Id</div>
                  <div className="text-[#2b2929]">
                    {state.googleScholarId}
                  </div>
                </div>
                <div className="mt-1">
                  <div className="font-semibold  w-48">Vidwan Id</div>
                  <div className="text-[#2b2929]">{state.vidwanId}</div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="my-1 p-1 border-b-2">
              <div className="font-semibold text-md">More Information</div>
            </div>
            <div className="sm:flex">
              <div className="text-[#02497f] px-1 sm:w-1/2">
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Mobile Number</div>
                  <div className="text-[#2b2929]">: {state.mobileNumber}</div>
                </div>
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Total Experience</div>
                  <div className="text-[#2b2929]">
                    : {state.totalExperience}
                  </div>
                </div>
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Orchid Id</div>
                  <div className="text-[#2b2929]">: {state.orchidId}</div>
                </div>
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Area of Reasearch</div>
                  <div className="text-[#2b2929]">: {state.areaOfresearch}</div>
                </div>
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Email </div>
                  <div className="text-[#2b2929]">: {state.emailId}</div>
                </div>{" "}
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Year of Award of Doctorate</div>
                  <div className="text-[#2b2929]">: {state.yearOfaward}</div>
                </div>
                <div className="flex space-x-2 mt-1">
                  <div className="font-semibold  w-48">Vidwan Id</div>
                  <div className="text-[#2b2929]">: {state.vidwanId}</div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <Button
                lable="Edit details"
                type="button"
                buttonType="primary"
                onClick={() =>
                  navigate(`/guide/guide_details_form/edit?guideId=${guideID}`)
                }
              />
            </div>

          </div>
        )}
      </div>
    </>
  );
};

export default GuideProfile;
