import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateGuideInfo, update_breadcrumb } from "../../../redux/action";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import axios from "axios";

const GuideHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userinformation = useSelector((state: any) => state.userInfo);
  const [completedProfile, setCompletedProfile] = useState(false);
  const username = useSelector((state: any) => state.userInfo.userName);

  const [isLoading, setIsLoading] = useState(true);
  const [banner, setBanner] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToNext = () => {
    const isLastSlide = currentIndex === banner.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? banner.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      goToNext();
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    getBannerImage();
    GetGuideDetails();

    const breadcrumb = [{ path: "/guide/guide_home", name: "Dashboard" }];
    dispatch(update_breadcrumb(breadcrumb));
    const guideInfo = {
      guideName: userinformation.userName,
      guideId: userinformation.userId,
    };
    dispatch(updateGuideInfo(guideInfo));

  }, []);
  const GetGuideDetails = async () => {
    setIsLoading(true);
    await instance
      .get(`/Guide/GetGuideDetails?UserId=${userinformation.userId}`)
      .then((response) => {
        if (response.status === 200) {
          if (
            response.data.aadhaar &&
            response.data.qualification &&
            response.data.mobileNumber
          ) {
            setCompletedProfile(true);
            setIsLoading(false);
          }
          if (
            response.data.aadhaar === null &&
            response.data.qualification === null &&
            response.data.mobileNumber === null
          ) {
            setCompletedProfile(false);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const getBannerImage = async () => {
    setIsLoading(true);
    await instance
      .get(`Admin/BannerImage`)
      .then((response) => {
        if (response.status === 200) {
          //do noting
          setBanner(response.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? <ActivityIndicator /> : null}

      <div className="bg-white shadow-lg p-4">
        <div className="sm:flex items-center justify-between">
          <div className="font-semibold text-[24px] text-sky-700 uppercase">
            Welcome {username},
          </div>

          {completedProfile == false && (
            <div
              onClick={() => navigate("/guide/guide_details_form/new")}
              className="bg-[gold] w-fit p-2 rounded-md font-semibold cursor-pointer"
            >
              Complete your Profile
            </div>
          )}
        </div>
        <div className="flex items-center justify-center relative mt-4">
          <div
            onClick={() => goToNext()}
            className="absolute right-44 text-[50px] font-mono text-white cursor-pointer"
          >
            {">"}
          </div>
          <div
            onClick={() => goToPrevious()}
            className="absolute left-44 text-[50px] font-mono text-white cursor-pointer"
          >
            {"<"}
          </div>
          <img
            src={banner[currentIndex]?.URL}
            className="shadow-lg h-60 w-full"
          />
        </div>
        <div className="mt-4">
          <div className="bg-[#02497f] p-2 font-semibold text-white text-lg">
            Notification
          </div>
          <div>
            <li className="font-semibold my-1">
              International Conference on Pervasive Computing and Social
              Networking on June 20
            </li>
            <li className="font-semibold my-1">
              International Yoga Day on June 23
            </li>
            <li className="font-semibold my-1">
              Recruit popular speakers or host TED Talks on July 03
            </li>
            <li className="font-semibold my-1">
              Throw an event to kick off a mentorship program on July 13
            </li>
            <li className="font-semibold my-1">
              Help students relax with free de-stressing activities on Aug 09
            </li>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuideHome;
