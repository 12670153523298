import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import instance from "../../../service/axiosInstane";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import ProgressBar from "../../../components/ProgreesBar/ProgressBar";
import { snackBarColors } from "../../../config/constants";
import { updateSnackbar } from "../../../redux/action";

const StudenAcademicform = () => {
  const { action } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const userinformation = useSelector((ffff: any) => ffff.userInfo);
  const queryParameters = new URLSearchParams(window.location.search);
  const studentID = queryParameters.get('studentId');
  const role = useSelector((state: any) => state.userRole);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState<any>({
    firstDCMExaminers: "",
    firstDCMMeeting: new Date(),
    firstDCMRemark: "",
    isSubmitted: true,
    resultCourseworkDate: new Date(),
    secondDCMExaminers: "",
    secondDCMMeeting: new Date(),
    secondDCMRemark: "",
    subjectForth: "",
    subjectOne: "",
    subjectThree: "",
    subjectTwo: "",
    userId: role === "Student" ? userinformation.userId : studentID,
  });
  const {
    firstDCMExaminers,
    firstDCMMeeting,
    firstDCMRemark,
    resultCourseworkDate,
    secondDCMExaminers,
    secondDCMMeeting,
    secondDCMRemark,
    subjectForth,
    subjectOne,
    subjectThree,
    subjectTwo,
    isSubmitted,
    userId,
  } = state;

  useEffect(() => {
    getResearchDetails();
  }, []);

  const handleChangeText = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const hadleSubmit = () => {
    if (action === "edit") {
      onAcademicUpdate();
    } else {
      onAcademicSubmit();
    }
  };

  const getResearchDetails = async () => {
    setIsLoading(true);
    await instance
      .get(`/Students/GetCourseWork?UserId=${role === "Student" ? userinformation.userId : studentID}`)
      .then((response) => {
        if (response.status === 200) {
          if (
            action === "create" &&
            response.data.isSubmitted === null
          ) {
            // do nothing
          } else if (
            response.data.isSubmitted === true &&
            action === "create"
          ) {
            navigate("/student/student_report_form/create");
          } else if (
            action === "edit" &&
            response.data.isSubmitted === true
          ) {
            setState(response.data);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const onAcademicUpdate = async () => {
    setIsLoading(true);
    await instance
      .post(
        `/Students/UpdatedCourseWork?UserId=${role === "Student" ? userinformation.userId : studentID}`,
        state
      )
      .then((response) => {
        if (response.status === 200) {
          const obj = {
            value: true,
            message: "Academic details updated successfully!!",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj));
          navigate(`/student/student_profile?studentId=${response.data.userId}`);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        const obj = {
          value: true,
          message: "Something went wrong!!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj));
        setIsLoading(false);
      });
  };

  const onAcademicSubmit = async () => {
    setIsLoading(true);
    await instance
      .post("/Students/CourseWork", state)
      .then((response) => {
        if (response.status === 200) {
          const obj = {
            value: true,
            message: "Academic details added successfully!",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj));
          navigate("/student/student_report_form/create");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        const obj = {
          value: true,
          message: "Something went wrong!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj));
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading ? <ActivityIndicator /> : null}
      <div className="bg-white shadow-lg p-4">
        <div className="">
          <div className="font-semibold text-[24px] text-sky-700">
            Student Profile Form
          </div>
          <ProgressBar tab={3} />
        </div>
        <div className="my-2 border-b-2 p-2 bg-white">
          <div className="font-semibold text-lg">Academic Details</div>
        </div>
        <div className="flex justify-center px-2 bg-white ">
          <div className="sm:flex  sm:space-x-10   ">
            <div className="w-full">
              <div className="my-1 ">
                <Input
                  lable="Subject I"
                  type="text"
                  name="subjectOne"
                  value={subjectOne}
                  onChange={handleChangeText}
                  error={formErrors.subjectOne}
                />
              </div>

              <div className="my-1">
                <Input
                  lable="Subject II"
                  type="text"
                  name="subjectTwo"
                  value={subjectTwo}
                  onChange={handleChangeText}
                  error={formErrors.subjectTwo}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Subject III"
                  type="text"
                  name="subjectThree"
                  value={subjectThree}
                  onChange={handleChangeText}
                  error={formErrors.subjectThree}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Subject IV"
                  type="text"
                  name="subjectForth"
                  value={subjectForth}
                  onChange={handleChangeText}
                  error={formErrors.subjectForth}
                />
              </div>
              <div className="my-1 ">
                <Input
                  lable="Results of Coursework with Month and Year"
                  type="date"
                  name="resultCourseworkDate"
                  value={resultCourseworkDate.toString().substring(0, 10)}
                  onChange={handleChangeText}
                  error={formErrors.resultCourseworkDate}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="my-1">
                <Input
                  lable="IDCM Meeting Date"
                  type="date"
                  name="firstDCMMeeting"
                  value={firstDCMMeeting}
                  onChange={handleChangeText}
                  error={formErrors.firstDCMMeeting}
                />
              </div>

              <div className="my-1">
                <Input
                  lable="I - DCM Examiners"
                  type="text"
                  name="firstDCMExaminers"
                  value={firstDCMExaminers}
                  onChange={handleChangeText}
                  error={formErrors.firstDCMExaminers}
                />
              </div>
              <div className="my-1 ">
                <Input
                  lable="I - DCM Remarks with Title"
                  type="text"
                  name="firstDCMRemark"
                  value={firstDCMRemark}
                  onChange={handleChangeText}
                  error={formErrors.firstDCMRemark}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="II - DCM Meeting Date"
                  type="date"
                  name="secondDCMMeeting"
                  value={secondDCMMeeting.toString().substring(0, 10)}
                  onChange={handleChangeText}
                  error={formErrors.secondDCMMeeting}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="II - DCM Examiners"
                  type="text"
                  name="secondDCMExaminers"
                  value={secondDCMExaminers}
                  onChange={handleChangeText}
                  error={formErrors.secondDCMExaminers}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="II - DCM Remarks"
                  type="text"
                  name="secondDCMRemark"
                  value={secondDCMRemark}
                  onChange={handleChangeText}
                  error={formErrors.secondDCMRemark}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center space-x-4 mt-5 px-2">
          <Button
            lable="submit"
            type="button"
            buttonType="primary"
            onClick={() => hadleSubmit()}
          />
          <Button
            lable="cancel"
            type="button"
            buttonType="danger"
            onClick={() => navigate(`/student/student_profile?studentId=${studentID}`)}
          />
        </div>
      </div>
    </>
  );
};

export default StudenAcademicform;
