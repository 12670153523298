import React, { useEffect, useState } from "react";
import studentPhoto from "../../../assets/student.png";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInformation, update_breadcrumb } from "../../../redux/action";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { PHD, DSC, PDF } from "../../../config/studentType";

const StudentProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const studentID = queryParameters.get('studentId');
  const role = useSelector((state: any) => state.userRole);
  const studentType = useSelector((state: any) => state.studenType);
  const imageUrl = "http://api.suresearchsoftware.com/Images/ProfileImages/";
  const option = [
    { id: 1, label: "Profile", onClick: () => setTab(1) },
    { id: 2, label: "Fee", onClick: () => setTab(2) },
    { id: 3, label: "Reasearch", onClick: () => setTab(3) },
    { id: 4, label: "Report", onClick: () => setTab(4) },
  ];

  const option2 = [
    { id: 1, label: "Profile", onClick: () => setTab(1) },
    { id: 2, label: "Fee", onClick: () => setTab(2) },
    { id: 3, label: "Report", onClick: () => setTab(4) },
  ];
  const [tab, setTab] = useState(1);
  const userinformation = useSelector((state: any) => state.userInfo);
  const [basicDetails, setBasicDetails] = useState<any>({});
  const [researchDetails, setReSearchDetails] = useState<any>({});
  const [reportDetails, setReportDetails] = useState<any>({});
  const [feeDetails, setFeeDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [profileStatus, setProfileStatus] = useState<any>();
  const [options, setOptions] = useState<any>();
  useEffect(() => {
    getBasicDetails();
    getResearchDetails();
    getReportDetails();
    getFeeDetails();
    const breadcrumb = [
      { path: "/student/student_profile", name: "Student Profile" },
    ];
    dispatch(update_breadcrumb(breadcrumb));
  }, []);

  const getBasicDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Students/GetStudentDetails?UserId=${role === "Student" ? userinformation.userId : studentID}`)
      .then((response) => {
        if (response.status === 200) {

          setBasicDetails(response.data);
          if (studentType === PHD || response.data.studentType === PHD) {
            setOptions(option);
          } else {
            setOptions(option2);
          }

          if (role === "Student") {
            dispatch(updateUserInformation({
              ...userinformation, userName: response.data.candidateName,
              mobilePhone: response.data.phoneNo,
              Profile: response.data.profileUrl
            }));
          }

          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };
  const getResearchDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Students/GetCourseWork?UserId=${role === "Student" ? userinformation.userId : studentID}`)
      .then((response) => {
        if (response.status === 200) {
          console.log("academy", response.data);
          setReSearchDetails(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const getReportDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Students/GetReportDetails?UserId=${role === "Student" ? userinformation.userId : studentID}`)
      .then((response) => {
        if (response.status === 200) {
          setReportDetails(response.data);
          setProfileStatus(response.data.isSubmitted);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const getFeeDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Students/GetFeeDetails?UserId=${role === "Student" ? userinformation.userId : studentID}`)
      .then((response) => {
        if (response.status === 200) {
          setFeeDetails(response.data);
          console.log("Feee", response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const basicInformation = () => (
    <div className="text-sm">
      <div className="mt-2 sm:flex sm:space-x-6 text-[#02497f] ">
        <div>
          {basicDetails.profileUrl === null ? (
            <img src={studentPhoto} className="h-44 w-44 border-2 p-2" />
          ) : (
            <img
              src={imageUrl + basicDetails.profileUrl}
              className="h-44 w-44 border-2 p-2"
            />
          )}

          <div onClick={() => navigate(`/student/student_profile_pic/student?studentId=${studentID}`)} className="w-44 flex items-center text-black cursor-pointer justify-center font-semibold uppercase bg-[gold] h-8">Change Photo</div>
        </div>
        <div>
          <div className="flex space-x-2">
            <div className="font-semibold  w-48">Candidate Name</div>
            <div className="text-[#2b2929]">: {basicDetails.candidateName}</div>
          </div>
          <div className=" space-x-2 mt-1">
            <div className="font-semibold w-48">Permanent Address</div>
            <div className="text-sm text-[#2b2929]"> {basicDetails.permanentAddress}</div>
          </div>
          <div className="space-x-2 mt-1">
            <div className="font-semibold w-52">Address for Communication</div>
            <div className="text-sm text-[#2b2929]">{basicDetails.communicationAddress}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Aadhaar Number</div>
            <div className="text-[#2b2929]">: {basicDetails.aadhaarNo}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">PAN No</div>
            <div className="text-[#2b2929]">: {basicDetails.panNo}</div>
          </div>
        </div>
        <div>
          <div className="flex space-x-2 ">
            <div className="font-semibold  w-48">Father’s/Husband’s Name</div>
            <div className="text-[#2b2929]">: {basicDetails.fatherName}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Category</div>
            <div className="text-[#2b2929]">: {basicDetails.category}</div>
          </div>

          <div className="flex space-x-2 mt-1">
            <div className="font-semibold w-48">Phone Number</div>
            <div className="text-[#2b2929]">: {basicDetails.phoneNo}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">E Mail Id</div>
            <div className="text-[#0f0f0f]">: {basicDetails.emailId}</div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="my-1 p-1 border-b-2">
        <div className="font-semibold text-md">More Information</div>
      </div>
      <div className="sm:flex">
        <div className="text-[#02497f] px-1 sm:w-1/2">
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Name of the University</div>
            <div className="text-[#0f0f0f]">: {basicDetails.universityName}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Year Of Exam</div>
            <div className="text-[#0f0f0f]">: {basicDetails.yearOfExam}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">NET/SLET/Other</div>
            <div className="text-[#0f0f0f]">: {basicDetails.netType}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Atomic Research Center</div>
            <div className="text-[#0f0f0f]">: {basicDetails.atomicResearchCenter}</div>
          </div>{" "}
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Micro Incubation center</div>
            <div className="text-[#0f0f0f]">: {basicDetails.microIncubationCenter}</div>
          </div>
        </div>
        <div className="text-[#02497f] px-1">
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">
              Date of Entrance Examination{" "}
            </div>
            <div className="text-[#0f0f0f]">: {basicDetails.entranceExamDate?.substring(0, 10)}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Registration No</div>
            <div className="text-[#0f0f0f]">: {basicDetails.registrationNo}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Date of Registration </div>
            <div className="text-[#0f0f0f]">: {basicDetails.registrationDate?.substring(0, 10)}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Locality Type</div>
            <div className="text-[#0f0f0f]">: {basicDetails.localityType}</div>
          </div>
        </div>
      </div>

      {(studentType === DSC || basicDetails.studentType === DSC) && (
        <>
          <div className="my-1 p-1 border-b-2">
            <div className="font-semibold text-md">D.Sc/D.Litt Information</div>
          </div>
          <div className="sm:flex">
            <div className="text-[#02497f] px-1 sm:w-1/2">
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Google Scholar ID</div>
                <div className="text-[#0f0f0f]">: {basicDetails.googleScholarID}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Vidwan ID</div>
                <div className="text-[#0f0f0f]">:{basicDetails.vidwanID}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">
                  Subject of Ph.D. Degree
                </div>
                <div className="text-[#0f0f0f]">: {basicDetails.phdSubject}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Name of the mentor</div>
                <div className="text-[#0f0f0f]">:{basicDetails.mentorName}</div>
              </div>{" "}
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Date of Application </div>
                <div className="text-[#0f0f0f]">:{basicDetails.ApplicationDate}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Date Of Interview </div>
                <div className="text-[#0f0f0f]">: {basicDetails.InterviewDate}</div>
              </div>
            </div>
            <div className="text-[#02497f] px-1">
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Total Publications</div>
                <div className="text-[#0f0f0f]">:{basicDetails.totalPublication}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">
                  First Author Publications
                </div>
                <div className="text-[#0f0f0f]">: {basicDetails.firstAurthor}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Total Experience</div>
                <div className="text-[#0f0f0f]">: {basicDetails.totalExperience}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">H index</div>
                <div className="text-[#0f0f0f]">:{basicDetails.hIndex}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">I – 10 Index</div>
                <div className="text-[#0f0f0f]">: {basicDetails.indexValue}</div>
              </div>
            </div>
          </div>
        </>
      )}
      {(studentType === PHD || basicDetails.studentType === PHD) && (
        <>
          <div className="my-1 p-1 border-b-2">
            <div className="font-semibold text-md">PHD Information</div>
          </div>
          <div className="sm:flex">
            <div className="text-[#02497f] px-1 sm:w-1/2">
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">
                  Name of Qualified Exam
                </div>
                <div className="text-[#0f0f0f]">: {basicDetails.qualifiedExam}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">CGPA / %</div>
                <div className="text-[#0f0f0f]">: {basicDetails.cgpa}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">phdType</div>
                <div className="text-[#0f0f0f]">: {basicDetails.phdType}</div>
              </div>
            </div>
          </div>
        </>
      )}
      {(studentType === PDF || basicDetails.studentType === PDF) && (
        <>
          <div className="my-1 p-1 border-b-2">
            <div className="font-semibold text-md">PDF Information</div>
          </div>
          <div className="sm:flex">
            <div className="text-[#02497f] px-1 sm:w-1/2">
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">googleScholarID</div>
                <div className="text-[#0f0f0f]">: {basicDetails.googleScholarID}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Vidwan ID</div>
                <div className="text-[#0f0f0f]">: {basicDetails.vidwanID}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Name of the mentor</div>
                <div className="text-[#0f0f0f]">: {basicDetails.mentorName}</div>
              </div>
            </div>
            <div className="text-[#02497f] px-1 sm:w-1/2">
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Ph.D. Awarded Subject</div>
                <div className="text-[#0f0f0f]">: {basicDetails.phdAwardedSubject}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="font-semibold  w-48">Name of the Guide</div>
                <div className="text-[#0f0f0f]">:{basicDetails.guideName}</div>
              </div>
            </div>
          </div>
        </>
      )}
      {role !== "Guide" &&
        <div className="flex items-center justify-end">
          <Button
            lable="Edit details"
            type="button"
            buttonType="primary"
            onClick={() => navigate(`/student/student_profile_form/edit?studentId=${studentID}`)}
          />
        </div>}
    </div>
  );

  const academickDetails = () => (
    <div className="mt-2 text-[#02497f] text-sm">
      <div className="sm:flex w-full items-center justify-between p-2 border-b-2">
        <div className="flex space-x-2">
          <div className="font-semibold ">Subject </div>
          <div className="text-[#0f0f0f]">: {feeDetails.subject}</div>
        </div>
        <div className="flex space-x-2 mt-1">
          <div className="font-semibold ">Area of Reasearch</div>
          <div className="text-[#0f0f0f]">:{feeDetails.areaOfResearch}</div>
        </div>
      </div>
      <div className="sm:flex sm:space-x-10 ">
        <div className="">
          <div className="flex space-x-2 mt-2 ">
            <div className="font-semibold  w-48">Application Fee</div>
            <div className="text-[#0f0f0f]">: {feeDetails.applicationFee}</div>
          </div>

          <div className="flex space-x-2 mt-2">
            <div className="font-semibold  w-48">Coursework fee</div>
            <div className="text-[#0f0f0f]">: {feeDetails.courseworkFee}</div>
          </div>
        </div>
        <div>
          {(studentType === PHD || basicDetails.studentType === PHD) && (
            <>
              <div className="font-semibold text-md text-black">
                PHD Information
              </div>

              <div className="sm:flex justify-between w-full sm:space-x-20">
                <div>
                  <div className="flex space-x-2 mt-2">
                    <div className="font-semibold  w-48">Registration Fee</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.registrationFee}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">I DCM Fee</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.firstDCMFee}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">II DCM Fee</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.secondDCMFee}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">I Year Fee</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.firstYearFee}</div>
                  </div>
                  <div className="flex space-x-2 mt-2">
                    <div className="font-semibold  w-48">II Year Fee</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.secondYearFee}</div>
                  </div>
                  <div className="flex space-x-2 mt-2">
                    <div className="font-semibold  w-48">III Year Fee</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.thirdYearFee}</div>
                  </div>
                </div>
                <div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">IV Year Fee</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.forthYearFee}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">V Year Fee</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.fifthYearFee}</div>
                  </div>

                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">
                      Thesis Submission Fee
                    </div>
                    <div className="text-[#0f0f0f]">: {feeDetails.thesisSubmissionFee}</div>
                  </div>
                  <div className="flex space-x-2 mt-2">
                    <div className="font-semibold  w-48">Viva Fee</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.vivaFee}</div>
                  </div>
                  <div className="flex space-x-2 mt-2">
                    <div className="font-semibold  w-48">Others Fee -I</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.othersFeeOne}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">Others Fee- II</div>
                    <div className="text-[#0f0f0f]">: {feeDetails.othersFeeSecond}</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {(studentType === DSC || basicDetails.studentType === DSC) && (
          <>
            <div>
              <div className="my-1 p-1 border-b-2">
                <div className="font-semibold text-md text-black">
                  DSC Information
                </div>
              </div>
              <div className="flex space-x-2 mt-2 ">
                <div className="font-semibold  w-48">Registration Fee</div>
                <div className="text-[#0f0f0f]">: {feeDetails.registrationFee}</div>
              </div>
              <div className="flex space-x-2 mt-2">
                <div className="font-semibold  w-48">Thesis Submission Fee</div>
                <div className="text-[#0f0f0f]">: {feeDetails.thesisSubmissionFee}</div>
              </div>
            </div>
          </>
        )}
      </div>{role !== "Guide" &&
        <div className="flex items-center justify-end">
          <Button
            lable="Edit details"
            type="button"
            buttonType="primary"
            onClick={() => navigate(`/student/student_fee_form/edit?studentId=${studentID}`)}
          />
        </div>}
    </div>
  );

  const reasearchInformation = () => (
    <div className="mt-2 text-[#02497f] text-sm">
      <div className=" w-full  p-2">
        <div className="flex space-x-2">
          <div className="font-semibold w-1/2 ">Subject - I </div>
          <div className="text-[#0f0f0f]">: {researchDetails.subjectOne}</div>
        </div>
        <div className="flex space-x-2 mt-1">
          <div className="font-semibold w-1/2">Subject - II</div>
          <div className="text-[#0f0f0f]">: {researchDetails.subjectTwo}</div>
        </div>
        <div className="flex space-x-2 mt-1">
          <div className="font-semibold w-1/2">Subject - III</div>
          <div className="text-[#0f0f0f]">: {researchDetails.subjectThree}</div>
        </div>
        <div className="flex space-x-2 mt-1">
          <div className="font-semibold w-1/2">Subject - IV</div>
          <div className="text-[#0f0f0f]">: {researchDetails.subjectForth}</div>
        </div>
        <div className="flex space-x-2 mt-2 ">
          <div className="font-semibold  w-1/2">
            Results of Coursework with Month and Year
          </div>
          <div className="text-[#0f0f0f]">: {researchDetails.resultCourseworkDate?.substring(0, 10)}</div>
        </div>
        <div className="flex space-x-2 mt-2 ">
          <div className="font-semibold  w-1/2">I - DCM Meeting Date</div>
          <div className="text-[#0f0f0f]">: {researchDetails.firstDCMMeeting?.substring(0, 10)}</div>
        </div>
        <div className="flex space-x-2 mt-2 ">
          <div className="font-semibold  w-1/2">I - DCM Examiners</div>
          <div className="text-[#0f0f0f]">: {researchDetails.firstDCMExaminers}</div>
        </div>
        <div className="flex space-x-2 mt-2 ">
          <div className="font-semibold w-1/2">I - DCM Remarks with Title</div>
          <div className="text-[#0f0f0f]">: {researchDetails.firstDCMRemark}</div>
        </div>
        <div className="flex space-x-2 mt-2 ">
          <div className="font-semibold  w-1/2">II - DCM Meeting Date</div>
          <div className="text-[#0f0f0f]">: {researchDetails.secondDCMMeeting?.substring(0, 10)}</div>
        </div>
        <div className="flex space-x-2 mt-2 ">
          <div className="font-semibold  w-1/2">II - DCM Examiners</div>
          <div className="text-[#0f0f0f]">: {researchDetails.secondDCMExaminers}</div>
        </div>
        <div className="flex space-x-2 mt-2 ">
          <div className="font-semibold  w-1/2">II - DCM Remarks</div>
          <div className="text-[#0f0f0f]">: {researchDetails.secondDCMRemark}</div>
        </div>
      </div>
      {role !== "Guide" &&
        <div className="flex items-center justify-end">
          <Button
            lable="Edit details"
            type="button"
            buttonType="primary"
            onClick={() => navigate(`/student/student_coursework_form/edit?studentId=${studentID}`)}
          />
        </div>}
    </div>
  );

  const reportInformation = () => (
    <div>
      <div className="mt-2 text-[#02497f] text-sm">
        {(studentType === PHD || basicDetails.studentType === PHD) && (
          <div className="sm:flex items-center  justify-between border-b-2 P-2">
            <div>
              <div className="flex space-x-2 mt-2 ">
                <div className="font-semibold  w-48">I Report</div>
                <div className="text-[#0f0f0f]">: {reportDetails.firstReport}</div>
              </div>
              <div className="flex space-x-2 mt-2 ">
                <div className="font-semibold  w-48">II Report</div>
                <div className="text-[#0f0f0f]">: {reportDetails.secondReport}</div>
              </div>
              <div className="flex space-x-2 mt-2 ">
                <div className="font-semibold  w-48">III Report</div>
                <div className="text-[#0f0f0f]">: {reportDetails.thirdReport}</div>
              </div>
            </div>
            <div>
              <div className="flex space-x-2 mt-2 ">
                <div className="font-semibold  w-48">IV Report</div>
                <div className="text-[#0f0f0f]">: {reportDetails.forthReport}</div>
              </div>
              <div className="flex space-x-2 mt-2 ">
                <div className="font-semibold  w-48">V Report</div>
                <div className="text-[#0f0f0f]">: {reportDetails.fifthReport}</div>
              </div>
              <div className="flex space-x-2 mt-2 ">
                <div className="font-semibold  w-48">VI Report</div>
                <div className="text-[#0f0f0f]">: {reportDetails.sixthReport}</div>
              </div>
            </div>
          </div>
        )}

        <div className="sm:flex sm:space-x-10 ">
          {(studentType === PHD || basicDetails.studentType === PHD) && (
            <>
              <div className="sm:flex justify-between w-full ">
                <div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">
                      Date of Thesis submission
                    </div>
                    <div className="text-[#0f0f0f]">: {reportDetails.thesisSubmissionDate?.substring(0, 10)}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">Examiner - I</div>
                    <div className="text-[#0f0f0f]">: {reportDetails.examinerOne}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">Examiner - II</div>
                    <div className="text-[#0f0f0f]">: {reportDetails.examinerTwo}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">Examiner - III</div>
                    <div className="text-[#0f0f0f]">: {reportDetails.examinerThree}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">
                      Is it Resubmitted, If yes then Details
                    </div>
                    <div className="text-[#0f0f0f]">: {reportDetails.resubmittedDetail}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">Date of Viva</div>
                    <div className="text-[#0f0f0f]">: {reportDetails.vivaDate?.substring(0, 10)}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">
                      Date of Notification
                    </div>
                    <div className="text-[#0f0f0f]">: {reportDetails.notificationDate}</div>
                  </div>
                </div>
                <div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">Final Result</div>
                    <div className="text-[#0f0f0f]">: {reportDetails.finalResult}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">
                      Date of Convocation
                    </div>
                    <div className="text-[#0f0f0f]">: {reportDetails.convocationDate?.substring(0, 10)}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">
                      Examiner Details and Its Recommendations
                    </div>
                    <div className="text-[#0f0f0f]">: {reportDetails.examinerDetails}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">
                      Paper Publications
                    </div>
                    <div className="text-[#0f0f0f]">: {reportDetails.paperPublications}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">National Journal</div>
                    <div className="text-[#0f0f0f]">: {reportDetails.nationalJournal}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">
                      International Journals
                    </div>
                    <div className="text-[#0f0f0f]">: {reportDetails.internationalJournals}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">
                      Conference Proceedings
                    </div>
                    <div className="text-[#0f0f0f]">: {reportDetails.conferenceProceedings}</div>
                  </div>
                  <div className="flex space-x-2 mt-2 ">
                    <div className="font-semibold  w-48">Patents</div>
                    <div className="text-[#0f0f0f]">: {reportDetails.patents}</div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div>
            {(studentType === PDF || basicDetails.studentType === PDF) && (
              <>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">
                    Date of Submission of report
                  </div>
                  <div className="text-[#0f0f0f]">: {reportDetails.submissionDate}</div>
                </div>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">
                    Certificate Issued Date
                  </div>
                  <div className="text-[#0f0f0f]">: {reportDetails.certificateIssuedDate}</div>
                </div>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">
                    Publications and Patents List
                  </div>
                  <div className="text-[#0f0f0f]">: {reportDetails.publicationList}</div>
                </div>
              </>
            )}
            {(studentType === DSC || basicDetails.studentType === DSC) && (
              <>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">Examiner - I</div>
                  <div className="text-[#0f0f0f]">: {reportDetails.examinerOne}</div>
                </div>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">Examiner - II</div>
                  <div className="text-[#0f0f0f]">: {reportDetails.examinerTwo}</div>
                </div>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">Examiner - III</div>
                  <div className="text-[#0f0f0f]">: {reportDetails.examinerThree}</div>
                </div>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">
                    Is it Resubmitted, If yes then Details
                  </div>
                  <div className="text-[#0f0f0f]">: {reportDetails.resubmittedDetail}</div>
                </div>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">Date of Viva</div>
                  <div className="text-[#0f0f0f]">: {reportDetails.vivaDate}</div>
                </div>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">
                    Date of Notification
                  </div>
                  <div className="text-[#0f0f0f]">: {reportDetails.notificationDate}</div>
                </div>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">Final Result</div>
                  <div className="text-[#0f0f0f]">: {reportDetails.finalResult}</div>
                </div>
                <div className="flex space-x-2 mt-2 ">
                  <div className="font-semibold  w-48">Date of Convocation</div>
                  <div className="text-[#0f0f0f]">: {reportDetails.convocationDate}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {role !== "Guide" && <div className="flex justify-center items-center">
        <Button
          lable="Edit details"
          type="button"
          buttonType="primary"
          onClick={() => navigate(`/student/student_report_form/edit?studentId=${studentID}`)}
        />
      </div>}

    </div>
  );
  const subHeading = () => {
    switch (tab) {
      case 1:
        return "Profile";
      case 2:
        return "Fee";
      case 3:
        return "Reasearch";
      case 4:
        return "Report";
      default:
        return "No data";
    }
  };
  return (
    <>
      {isLoading ?
        <ActivityIndicator /> : null}

      <div className="bg-white shadow-lg p-4 min-h-[500px]">
        <div className="px-1">
          <div className="font-semibold text-[24px] text-sky-700">
            Student Profile
          </div>
        </div>
        {profileStatus === null ? (
          <div>Sorry! Profile is not Updated!</div>
        ) : (
          <>
            <div className="flex items-center justify-between cursor-pointer">
              <div className="hidden sm:block">
                <div className="font-semibold text-lg">
                  {subHeading()} Information
                </div>
              </div>
              <div className="flex items-center cursor-pointer">
                {options?.map((item: any, index: number) => {
                  return (
                    <div
                      className={`${item.id === tab
                        ? "bg-[orange] text-white"
                        : "bg-[gold]"
                        }  py-1 px-4 border-r rounded-tl-md rounded-tr-md`}
                      onClick={item.onClick}
                    >
                      <div className="font-semibold">{item.label}</div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div>
              <div className="bg-[gold] w-full h-1 rounded-lg"></div>
            </div>
            {tab === 1 && basicInformation()}
            {tab === 2 && academickDetails()}
            {tab === 3 && reasearchInformation()}
            {tab === 4 && reportInformation()}
          </>
        )}
      </div>

    </>
  );
};

export default StudentProfile;
