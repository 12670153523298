import React, { useEffect, useState } from "react";
import MuiTable from "../../../components/MuiTable/MuiTable";
import { data } from "../../../service/json";
import instance from "../../../service/axiosInstane";
import { useDispatch, useSelector } from "react-redux";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import {
  update_breadcrumb,
} from "../../../redux/action";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import Button from "../../../components/Button/Button";

const Students = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userinformation = useSelector((state: any) => state.userInfo);
  const [state, setState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const columns = [
    { id: "userName", label: "userName", minWidth: 70, align: "left" },
    {
      id: "email",
      label: "Email",
      minWidth: 170,
      align: "left",
    },
    {
      id: "phoneNumber",
      label: "PhoneNumber",
      minWidth: 170,
      align: "left",
    },

    {
      id: "CreatedOn",
      label: "Registerd Date",
      minWidth: 70,
      align: "left",
    },
    {
      id: "UpdatedDate",
      label: "Updated Date",
      minWidth: 70,
      align: "left",
    },
    {
      id: "action",
      label: "action",
      minWidth: 70,
      align: "left",
    },
  ];
  useEffect(() => {
    const breadcrumb = [{ path: "/guide/students", name: "Student List" }];
    dispatch(update_breadcrumb(breadcrumb));
    getStudentList();
  }, []);

  const getStudentList = async () => {
    setIsLoading(true);
    await instance
      .get(`Guide/GuideGetStudent?guide=${userinformation.userId}`)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };
  const viewRecord = (args: any) => {
    navigate(`/student/student_profile?studentId=${args}`);
  };
  const handleExport = () => {
    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(state);
    XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    XLSX.writeFile(wb, `Students.xlsx`);
  };
  return (
    <>
      {isLoading ? <ActivityIndicator /> : null}
      <div className="bg-white p-4 shadow-lg">
        <div className="flex items-center justify-between  px-1 mb-2">
          <div className="font-semibold text-[24px] text-sky-700">
            Students List
          </div>
          {state.length > 0 && (
            <div>
              {" "}
              <Button
                lable="Export"
                type="button"
                buttonType="primary"
                onClick={() => handleExport()}
              />
            </div>
          )}
        </div>
        <MuiTable
          columns={columns}
          rows={state}
          searchColumn={"userName"}
          onViewHandler={viewRecord}
          primaryKey={"userId"}
        />
      </div>
    </>
  );
};

export default Students;
