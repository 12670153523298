import React, { useEffect, useState } from "react";
import bg from "../../assets/bgFront.jpg";
import logo from "../../assets/sr_logo.gif";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SnackBar from "../../components/SnackBar/SnackBar";
import { baseUrl } from "../../service/service";
import { useDispatch } from "react-redux";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import {
  updateRole,
  updateSnackbar,
  updateToken,
  updateUserInformation,
} from "../../redux/action";
import { Capitalization } from "../../config/helper";
import { snackBarColors } from "../../config/constants";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginStatus, setLoginStatus] = useState({
    text: "Login Now",
    status: false,
  });
  const [state, setState] = useState({
    username: "",
    password: "",
    role: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const { username, password, role } = state;
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (!e.target.value) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: `${Capitalization(e.target.name)} required!`,
      });
    } else {
      setFormErrors({ ...formErrors, [e.target.name]: undefined });
    }
  };

  const validate = () => {
    const error: any = {};
    const emailvalidation = /\S+@\S+\.\S+/.test(username);
    if (!username) {
      error.username = `${Capitalization("username")} required!`;
    } else if (!emailvalidation) {
      error.username = `${Capitalization("username")} Invalid`;
    }
    if (!password) {
      error.password = `${Capitalization("password")} required!`;
    }
    if (!role) {
      error.role = `${Capitalization("role")} required!`;
    }
    return error;
  };
  const handleSubmit = () => {
    setFormErrors(validate());
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      doLogin();
    }
  }, [formErrors]);

  const doLogin = () => {
    setIsLoading(true)
    let obj = {
      UserName: username,
      Password: password,
      LoginAs: role,
      mobileNumber: "9874563210",
    };
    setLoginStatus({ ...loginStatus, text: "Please wait...", status: true });
    axios
      .post(`${baseUrl}/account/Login`, obj)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateToken(response.data.accessToken));
          dispatch(updateUserInformation(response.data));
          dispatch(updateRole(role));
          if (role === "Student") {
            navigate("student/student_home");
          } else {
            navigate("guide/guide_home");
          }
          setLoginStatus({
            ...loginStatus,
            text: "Login Now",
            status: false,
          });
          setIsLoading(false)

        }
      })
      .catch((error) => {
        setLoginStatus({
          ...loginStatus,
          text: "Login Now",
          status: false,
        });
        if (error) {
          const obj = {
            value: true,
            message: "Sorry! User is not found!",
            type: snackBarColors.error,
          }
          dispatch(updateSnackbar(obj))
        }
        setIsLoading(false)
      });
  };

  return (
    <div>
      {isLoading ? <ActivityIndicator /> : null}
      <div className="flex items-center justify-center sm:h-screen sm:shadow-lg rounded-lg sm:bg-[#02497f]">
        <div className="h-[510px] w-[900px] sm:flex justify-between bg-white sm:shadow-lg rounded-md">
          <div className="sm:w-1/2 h-full items-center bg-white rounded-md hidden sm:block">
            <img src={bg} className="rounded-tl-md rounded-bl-md object-cover	" />
          </div>
          <div className="sm:w-1/2">
            <div className="flex justify-end mt-4 mx-2">
              <div
                onClick={() => navigate("/register")}
                className="p-1 border border-[gold] text-gold-500 w-fit text-sm cursor-pointer rounded-md shadow-sm"
              >
                Create Account
              </div>
            </div>
            <div className="flex flex-col items-center  ">
              <img src={logo} height={80} width={80} />
              <div className="text-[30px] font-bold text-[#200175]">
                Srinivas University
              </div>
              <div className="text-[20px] text-yellow-400 uppercase font-semibold">Welcome Back</div>
              <div className="h-18">
                <div className="text-sm text-[gray]">Email</div>
                <input
                  className="focus:border-[#83b8f5] h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="John@gmail.com"
                  value={username}
                  name="username"
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                />
                <div className="text-sm text-[red]">
                  {formErrors?.username}
                </div>
              </div>

              <div className="h-18 ">
                <div className="text-sm  text-[gray]">Password</div>
                <input
                  className="focus:border-[#83b8f5] h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="*******"
                  value={password}
                  name="password"
                  type="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                />
                <div className="text-sm text-[red]">
                  {formErrors?.password}
                </div>
              </div>
              <div className="h-18">
                <div className="text-sm text-[gray]">Role</div>
                <select
                  name="role"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange(e)}
                  className="focus:border-[#83b8f5] h-8 text-sm w-[250px] sm:w-[300px] border text-blue-900 cursor-pointer rounded-sm pl-2 outline-none"
                >
                  <option value={""}>Select</option>
                  <option value={"Student"}>Student</option>
                  <option value={"Guide"}>Guide</option>
                </select>

                <div className="text-sm text-[red]">{formErrors?.role}</div>
              </div>
            </div>
            <div onClick={() => navigate("/forgotPassword")} className="font-semibold cursor-pointer flex items-center w-full justify-end mt-2 text-sm pr-12 text-blue-900">
              <div>Forgot Password?</div>
            </div>
            <div className="flex items-center w-full justify-center h-18">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="bg-[#200175]  px-4 py-1 rounded-sm text-white"
              >
                {loginStatus.text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
