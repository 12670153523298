import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import { updateUserInformation, update_breadcrumb } from '../../redux/action';
import instance from '../../service/axiosInstane';
import { useDispatch, useSelector } from 'react-redux';
import MuiTable from '../../components/MuiTable/MuiTable';
import { useNavigate } from 'react-router-dom';
import ActivityIndicator from '../../components/ActivityIndicator/ActivityIndicator';

function GuideView() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [state, setState] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const token = useSelector((state: any) => state.token)

    const columns = [
        { id: "userId", label: "user Id", minWidth: 70, align: "left" },
        {
            id: "candidateName",
            label: "userName",
            minWidth: 170,
            align: "left",
        },
        {
            id: "emailId",
            label: "emailId",
            minWidth: 170,
            align: "left",
        },

        {
            id: "phoneNo",
            label: "phoneNo",
            minWidth: 70,
            align: "left",
        },
        // {
        //     id: "userId",
        //     label: "guideId",
        //     minWidth: 70,
        //     align: "left",
        // },
        // {
        //     id: "Status",
        //     label: "Status",
        //     minWidth: 70,
        //     align: "left",
        // },
        {
            id: "action",
            label: "action",
            minWidth: 70,
            align: "left",
        },
    ];
    useEffect(() => {
        getStudentList();
        const breadcrumb = [{ path: "/admin_guide_view", name: "Guide Management" }];
        dispatch(update_breadcrumb(breadcrumb));
    }, []);
    const getStudentList = async () => {
        setIsLoading(true);
        await instance
            .get(`Admin/GetStudentAndGuide?role=Guide`)
            .then((response) => {
                if (response.status === 200) {
                    setState(response.data);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("error", err);
                setIsLoading(false);
            });
    };
    const viewRecord = async (args: any) => {
        navigate(`/guide/guide_profile?guideId=${args}`);

    }
    return (
        <>{isLoading ? <ActivityIndicator /> : <div className='bg-white p-4 shadow-lg  '>
            <div className="px-1">
                <div className="flex items-center justify-between font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
                    <div>Guide Management</div>
                    <Button
                        lable="Create"
                        type="button"
                        buttonType="primary"
                        onClick={() => navigate("/create_guide")}

                    />
                </div>
                <div>
                    <div className='mt-4'>
                        <MuiTable columns={columns} rows={state} searchColumn={"userName"} onViewHandler={viewRecord} primaryKey={"userId"} />

                    </div>

                </div>
            </div>
        </div>} </>

    )
}

export default GuideView