import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { PDF, PHD, DSC } from "../../../config/studentType";
import ProgressBar from "../../../components/ProgreesBar/ProgressBar";
import { snackBarColors } from "../../../config/constants";
import { updateSnackbar } from "../../../redux/action";

const StudentReportfrom = () => {
  const dispatch = useDispatch();
  const { action } = useParams();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const studentID = queryParameters.get('studentId');
  const role = useSelector((state: any) => state.userRole);
  const userinformation = useSelector((state: any) => state.userInfo);
  const studentType = useSelector((state: any) => state.studenType);
  const [formErrors, setFormErrors] = useState<any>({});
  const [pdfFormErrors, setPdfFormErrors] = useState<any>({});
  const [dscFormErrors, setDscFormErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState<any>({
    certificateIssuedDate: "",
    conferenceProceedings: "",
    convocationDate: new Date(),
    eighthReport: "",
    examinerDetails: "",
    examinerOne: "",
    examinerThree: "",
    examinerTwo: "",
    fifthReport: "",
    finalResult: "",
    firstReport: "",
    forthReport: "",
    internationalJournals: "",
    isSubmitted: true,
    nationalJournal: "",
    notificationDate: "",
    paperPublications: "",
    patents: "",
    publicationList: "",
    resubmittedDetail: "",
    secondReport: "",
    seventhReport: "",
    sixthReport: "",
    submissionDate: new Date(),
    thesisSubmissionDate: new Date(),
    thirdReport: "",
    userId: role === "Student" ? userinformation.userId : studentID,
    vivaDate: new Date(),
  });

  const {
    certificateIssuedDate,
    conferenceProceedings,
    convocationDate,
    eighthReport,
    examinerDetails,
    examinerOne,
    examinerThree,
    examinerTwo,
    fifthReport,
    finalResult,
    firstReport,
    forthReport,
    internationalJournals,
    isSubmitted,
    nationalJournal,
    notificationDate,
    paperPublications,
    patents,
    publicationList,
    resubmittedDetail,
    secondReport,
    seventhReport,
    sixthReport,
    submissionDate,
    thesisSubmissionDate,
    thirdReport,
    userId,
    vivaDate,
  } = state;

  useEffect(() => {
    getReportDetails();
  }, []);

  const handleChangeText = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const hadleSubmit = () => {
    if (action === "edit") {
      onReportUpdate();
    } else {
      onReportSubmit();
    }
  };

  const getReportDetails = async () => {
    setIsLoading(true);
    await instance
      .get(`/Students/GetReportDetails?UserId=${role === "Student" ? userinformation.userId : studentID}`)
      .then((response) => {
        if (response.status === 200) {
          if (action === "create" && response.data.isSubmitted === null) {
            // do nothing
          } else if (
            response.data.isSubmitted === true &&
            action === "create"
          ) {
            navigate("/student/student_home");
          } else {
            setState(response.data);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const onReportUpdate = async () => {
    setIsLoading(true);
    await instance
      .post(`/Students/UpdatedReportDetails?UserId=${role === "Student" ? userinformation.userId : studentID}`, state)
      .then((response) => {
        if (response.status === 200) {
          const obj = {
            value: true,
            message: "Report details updated successfully!",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj));
          navigate(`/student/student_profile?studentId=${response.data.userId}`);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        const obj = {
          value: true,
          message: "Something went wrong!!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj));
        setIsLoading(false);
      });
  };

  const onReportSubmit = async () => {
    setIsLoading(true);
    await instance
      .post("/Students/ReportDetails", state)
      .then((response) => {
        if (response.status === 200) {
          const obj = {
            value: true,
            message: "Report details added successfully!",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj));
          navigate("/student/student_home");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        const obj = {
          value: true,
          message: "Something went wrong!!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj));
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading ?
        <ActivityIndicator /> : null}
      <div className="bg-white shadow-lg p-4">
        <div className="font-semibold text-[24px] text-sky-700">
          Student Profile Form
        </div>
        <ProgressBar tab={4} />
        <div className="my-2 border-b-2 p-2">
          <div className="font-semibold text-lg">Report Details</div>
        </div>
        <div className="flex px-2 justify-center">
          <div className="sm:flex sm:space-x-10">
            {studentType === PHD && (
              <div className="sm:flex sm:space-x-6">
                <div>
                  <div className="my-1 ">
                    <Input
                      lable="I Report"
                      type="text"
                      name="firstReport"
                      value={firstReport}
                      onChange={handleChangeText}
                      error={formErrors.firstReport}
                    />
                  </div>

                  <div className="my-1">
                    <Input
                      lable="II Report"
                      type="text"
                      name="secondReport"
                      value={secondReport}
                      onChange={handleChangeText}
                      error={formErrors.secondReport}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="III Report"
                      type="text"
                      name="thirdReport"
                      value={thirdReport}
                      onChange={handleChangeText}
                      error={formErrors.thirdReport}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="IV Report"
                      type="text"
                      name="forthReport"
                      value={forthReport}
                      onChange={handleChangeText}
                      error={formErrors.forthReport}
                    />
                  </div>
                  <div className="my-1 ">
                    <Input
                      lable="V Report"
                      type="text"
                      name="fifthReport"
                      value={fifthReport}
                      onChange={handleChangeText}
                      error={formErrors.fifthReport}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="VI Report"
                      type="text"
                      name="sixthReport"
                      value={sixthReport}
                      onChange={handleChangeText}
                      error={formErrors.sixthReport}
                    />
                  </div>
                  <div className="my-1 ">
                    <Input
                      lable="VII Report"
                      type="text"
                      name="seventhReport"
                      value={seventhReport}
                      onChange={handleChangeText}
                      error={formErrors.seventhReport}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="VIII Report"
                      type="text"
                      name="eighthReport"
                      value={eighthReport}
                      onChange={handleChangeText}
                      error={formErrors.eighthReport}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Date of Thesis Submission"
                      type="date"
                      name="thesisSubmissionDate"
                      value={thesisSubmissionDate.toString().substring(0, 10)}
                      onChange={handleChangeText}
                      error={formErrors.thesisSubmissionDate}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Examiner - I"
                      type="text"
                      name="examinerOne"
                      value={examinerOne}
                      onChange={handleChangeText}
                      error={formErrors.examinerOne}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Examiner - II"
                      type="text"
                      name="examinerTwo"
                      value={examinerTwo}
                      onChange={handleChangeText}
                      error={formErrors.examinerTwo}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Examiner - III"
                      type="text"
                      name="examinerThree"
                      value={examinerThree}
                      onChange={handleChangeText}
                      error={formErrors.examinerThree}
                    />
                  </div>
                </div>
                <div>

                  <div className="my-1">
                    <Dropdown
                      lable="Is it Resubmitted, If yes then Details"
                      name="resubmittedDetail"
                      value={resubmittedDetail}
                      onChange={handleChangeText}
                      error={formErrors.resubmittedDetail}
                      options={[
                        { name: "YES", value: "YES" },
                        { name: "NO", value: "NO" },
                      ]}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Date of Viva"
                      type="date"
                      name="vivaDate"
                      value={vivaDate.toString().substring(0, 10)}
                      onChange={handleChangeText}
                      error={formErrors.vivaDate}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Date of Notification"
                      type="date"
                      name="notificationDate"
                      value={notificationDate.toString().substring(0, 10)}
                      onChange={handleChangeText}
                      error={formErrors.notificationDate}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Final Result"
                      type="text"
                      name="finalResult"
                      value={finalResult}
                      onChange={handleChangeText}
                      error={formErrors.finalResult}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Date of Convocation"
                      type="date"
                      name="convocationDate"
                      value={convocationDate.toString().substring(0, 10)}
                      onChange={handleChangeText}
                      error={formErrors.convocationDate}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Examiner Details and Its Recommendations"
                      type="text"
                      name="examinerDetails"
                      value={examinerDetails}
                      onChange={handleChangeText}
                      error={formErrors.examinerDetails}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Paper Publications"
                      type="text"
                      name="paperPublications"
                      value={paperPublications}
                      onChange={handleChangeText}
                      error={formErrors.paperPublications}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="National Journal"
                      type="text"
                      name="nationalJournal"
                      value={nationalJournal}
                      onChange={handleChangeText}
                      error={formErrors.nationalJournal}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="International Journals"
                      type="text"
                      name="internationalJournals"
                      value={internationalJournals}
                      onChange={handleChangeText}
                      error={formErrors.internationalJournals}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Conference Proceedings"
                      type="text"
                      name="conferenceProceedings"
                      value={conferenceProceedings}
                      onChange={handleChangeText}
                      error={formErrors.conferenceProceedings}
                    />
                  </div>
                  <div className="my-1">
                    <Dropdown
                      lable="Patents"
                      name="patents"
                      value={patents}
                      onChange={handleChangeText}
                      error={formErrors.patents}
                      options={[
                        { name: "YES", value: "YES" },
                        { name: "NO", value: "NO" },
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="w-full">
              {studentType === PDF && (
                <>
                  <div className="my-1">
                    <Input
                      lable="Date of Submission of report"
                      type="date"
                      name="submissionDate"
                      value={submissionDate.toString().substring(0, 10)}
                      onChange={handleChangeText}
                      error={pdfFormErrors.submissionDate}
                    />
                  </div>{" "}
                  <div className="my-1">
                    <Input
                      lable="Certificate Issued Date"
                      type="text"
                      name="certificateIssuedDate"
                      value={certificateIssuedDate}
                      onChange={handleChangeText}
                      error={pdfFormErrors.certificateIssuedDate}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Publications and Patents List"
                      type="text"
                      name="publicationList"
                      value={publicationList}
                      onChange={handleChangeText}
                      error={pdfFormErrors.publicationList}
                    />
                  </div>
                </>
              )}
              {studentType === DSC && (
                <>
                  <div className="my-1">
                    <Input
                      lable="Examiner - I"
                      type="text"
                      name="examinerOne"
                      value={examinerOne}
                      onChange={handleChangeText}
                      error={dscFormErrors.examinerOne}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Examiner - II"
                      type="text"
                      name="examinerTwo"
                      value={examinerTwo}
                      onChange={handleChangeText}
                      error={dscFormErrors.examinerTwo}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Examiner - III"
                      type="text"
                      name="examinerThree"
                      value={examinerThree}
                      onChange={handleChangeText}
                      error={dscFormErrors.examinerThree}
                    />
                  </div>
                  <div className="my-1">
                    <Dropdown
                      lable="Is it Resubmitted, If yes then Details"
                      name="resubmittedDetail"
                      value={resubmittedDetail}
                      onChange={handleChangeText}
                      error={dscFormErrors.resubmittedDetail}
                      options={[
                        { name: "YES", value: true },
                        { name: "NO", value: false },
                      ]}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Date of Viva"
                      type="date"
                      name="vivaDate"
                      value={vivaDate.toString().substring(0, 10)}
                      onChange={handleChangeText}
                      error={dscFormErrors.vivaDate}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Date of Notification"
                      type="date"
                      name="notificationDate"
                      value={notificationDate.toString().substring(0, 10)}
                      onChange={handleChangeText}
                      error={dscFormErrors.notificationDate}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Final Result"
                      type="text"
                      name="finalResult"
                      value={finalResult}
                      onChange={handleChangeText}
                      error={dscFormErrors.finalResult}
                    />
                  </div>
                  <div className="my-1">
                    <Input
                      lable="Date of Convocation"
                      type="date"
                      name="convocationDate"
                      value={convocationDate.toString().substring(0, 10)}
                      onChange={handleChangeText}
                      error={dscFormErrors.convocationDate}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center space-x-4 mt-5 px-2">
          <Button
            lable="submit"
            type="button"
            buttonType="primary"
            onClick={hadleSubmit}
          />
          <Button
            lable="cancel"
            type="button"
            buttonType="danger"
            onClick={() => navigate(`/student/student_profile?studentId=${studentID}`)}
          />
        </div>
      </div>

    </>
  );
};

export default StudentReportfrom;
