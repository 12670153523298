import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Dashboard from "./screens/Dashboard/Dashboard";
import Login from "./screens/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import Profile from "./screens/Profile/Profile";
import Changepassword from "./screens/ChangePassword/ChangePassword";
import BreadCrumb from "./components/BreadCrumb/BreadCrumb";
import ActivityIndicator from "./components/ActivityIndicator/ActivityIndicator";
import Register from "./screens/Register/Register";
import { Suspense } from "react";
import Message from "./screens/Message/Message";
import StudentHome from "./screens/Students/StudentHome/StudentHome";
import StudentNotification from "./screens/Students/StudentNotification/StudentNotification";
import StudentDetailForm from "./screens/Students/StudentDetailForm/StudentDetailForm";
import StudentProfile from "./screens/Students/StudentProfile/StudentProfile";
import GuideNotification from "./screens/Guides/GuideNotification/GuideNotification";
import GuideHome from "./screens/Guides/GuideHome/GuideHome";
import GuideProfile from "./screens/Guides/GuideProfile/GuideProfile";
import Students from "./screens/Guides/Students/Students";
import GuideDetailsForm from "./screens/Guides/GuideDetailsForm/GuideDetailsForm";
import StudentFeeform from "./screens/Students/StudentDetailForm/StudentFeeform";
import StudenAcademicform from "./screens/Students/StudentDetailForm/StudenAcademicform";
import StudentReportfrom from "./screens/Students/StudentDetailForm/StudentReportfrom";
import DocumentUpload from "./screens/Students/DocumentUpload/DocumentUpload";
import ProfilePicUpload from "./screens/Students/StudentProfile/ProfilePicUpload";
import DocumentAdd from "./screens/Students/DocumentUpload/DocumentAdd";
import { useDispatch, useSelector } from "react-redux";
import SnackBar from "./components/SnackBar/SnackBar";
import AdminLogin from "./screens/Admin/AdminLogin";
import AdminHome from "./screens/Admin/AdminHome";
import StudentsView from "./screens/Admin/StudentsView";
import GuideView from "./screens/Admin/GuidesView";
import AdminProfile from "./screens/Admin/AdminProfile";
import CreateGuide from "./screens/Admin/CreateGuide";
import AssignStudent from "./screens/Admin/AssignStudent";
import AdminNotification from "./screens/Admin/AdminNotification";
import SendNotification from "./screens/Admin/SendNotification";
import NotificationDetails from "./screens/Admin/NotificationDetails";
import StudentNotificationDetails from "./screens/Students/StudentNotification/StudentNotificationDetails";
import SentNotification from "./screens/Students/StudentNotification/SentNotification";
import PublicationView from "./screens/Publication/PublicationView";
import PublicationCreate from "./screens/Publication/PublicationCreate";
import ForgotPassword from "./screens/ChangePassword/ForgotPassword";
import ResetPassword from "./screens/ChangePassword/ResetPassword";
import { StateProps } from "./redux/reducer";
import { updateSnackbar } from "./redux/action";



const Router = () => {
  const dispatch = useDispatch()
  const snackBar = useSelector((state: StateProps) => state.snackBar)
  console.log(snackBar, "snackBar")

  const handleClose = () => {
    const obj = {
      value: false,
      message: "",
      type: ""
    }
    dispatch(updateSnackbar(obj))
  }
  const Layout = () => (
    <div className="bg-slate-100 relative min-h-screen">

      <div className="sticky top-0 z-50">
        <Header />
      </div>
      <div className="fixed top-16">
        <Sidebar />
      </div>
      <div className="sm:ml-52 sm:m-4">
        <div className="sm:m-4">
          <BreadCrumb />
        </div>
        <div className="sm:m-4">
          <Outlet />
        </div>
      </div>
      <Footer />

    </div>
  );

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<ActivityIndicator />}>
          <Routes>
            <Route element={<Layout />}>
              <Route path="student/student_home" element={<StudentHome />} />
              <Route
                path="student/student_profile_form/:action"
                element={<StudentDetailForm />}
              />
              <Route
                path="student/student_fee_form/:action"
                element={<StudentFeeform />}
              />{" "}
              <Route
                path="student/student_coursework_form/:action"
                element={<StudenAcademicform />}
              />
              <Route
                path="student/student_report_form/:action"
                element={<StudentReportfrom />}
              />
              <Route
                path="student/student_profile"
                element={<StudentProfile />}
              />
              <Route
                path="student/student_profile_pic/:id"
                element={<ProfilePicUpload />}
              />
              <Route
                path="document_add/:user"
                element={<DocumentAdd />}
              />
              <Route
                path="student/student_notification"
                element={<StudentNotification />}
              />
              <Route
                path="sent_notification"
                element={<SentNotification />}
              />
              <Route
                path="student/student_notification_details/:id"
                element={<StudentNotificationDetails />}
              />
              <Route
                path="document_upload/:user"
                element={<DocumentUpload />}
              />
              <Route
                path="guide/guide_notification"
                element={<GuideNotification />}
              />
              <Route path="guide/guide_home" element={<GuideHome />} />
              <Route path="guide/guide_profile" element={<GuideProfile />} />
              <Route path="guide/students" element={<Students />} />
              <Route
                path="guide/guide_details_form/:action"
                element={<GuideDetailsForm />}
              />
              <Route
                path="admin_home"
                element={<AdminHome />}
              />
              <Route
                path="admin_student_view"
                element={<StudentsView />}
              />
              <Route
                path="admin_guide_view"
                element={<GuideView />}
              /> <Route
                path="admin_profile"
                element={<AdminProfile />}
              />
              <Route
                path="admin_notification"
                element={<AdminNotification />}
              />
              <Route
                path="admin_send_notification"
                element={<SendNotification />}
              />
              <Route
                path="notification_details/:id"
                element={<NotificationDetails />}
              />
              <Route
                path="create_guide"
                element={<CreateGuide />}
              />
              <Route
                path="assign_student/:id"
                element={<AssignStudent />}
              />
              <Route
                path="publication/:id"
                element={<PublicationView />}
              />
              <Route
                path="publication_create/:tab"
                element={<PublicationCreate />}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/admin_login" element={<AdminLogin />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword/:email" element={<ResetPassword />} />


          </Routes>
        </Suspense>
      </BrowserRouter>
      <SnackBar
        isOpen={snackBar.value}
        handleClose={() => handleClose()}
        message={snackBar.message}
        type={snackBar.type}
      />
    </>
  );
};

export default Router;
