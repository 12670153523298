import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { update_breadcrumb } from "../../redux/action";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import Dropdown from "../../components/Dropdown/Dropdown";
import instance from "../../service/axiosInstane";
import { sweetAlertStatus } from "../../config/constants";
import TextArea from "../../components/TextArea/TextArea";

const NotificationDetails = () => {
    const text =
        "     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."; const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState<any>({
        subject: "",
        body: "",
        date: "2023-07-17T15:30:25.618Z",
        createdBy: 1,
        Type: "",
        Ids: "",
    });
    const [isLoading, setIsLoading] = useState<any>(true);
    const [sweetAlert, setSweetAlert] = useState<any>({
        value: false,
        messge: "",
        status: "",
    });
    const [formErrors, setFormErrors] = useState<any>({});
    const [isSubmit, setIsSubmit] = useState(false);
    const { subject, body, date, createdBy, Type, Ids } = state;

    useEffect(() => {
        const breadcrumb = [
            { path: "/student/student_notification", name: "Notification" },
        ];
        dispatch(update_breadcrumb(breadcrumb));
        getNotification()
    }, []);
    const getNotification = async () => {
        setIsLoading(true);
        await instance
            .get(`/Admin/GetNotification?UserId=1`)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)

                }
            })
            .catch((err) => {
                console.log(err)
            });
    };

    return (
        <div className="bg-white shadow-lg p-4">
            <div className="flex items-center justify-between font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
                <div>Notification Details</div>
                <Button
                    lable="Create"
                    type="button"
                    buttonType="primary"
                    onClick={() => navigate("/admin_send_notification")}
                />
            </div>

            <div className="p-1 border my-1 cursor-pointer">
                <div className="font-semibold text-blue-900 text-lg">Definition and Usage</div>
                <div className="text-[15px] font-sans p-2">{text}</div>
            </div>

        </div>
    );
};

export default NotificationDetails;
