import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/Input/Input";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { baseURL, snackBarColors } from "../../../config/constants";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import { updateSnackbar, update_breadcrumb } from "../../../redux/action";
import { Capitalization } from "../../../config/helper";

const guideDoctype = [
    { name: "Research papers", value: "Reasearch papers" },
    { name: "Answer papers", value: "Answer papers" },
];
const studentDoctype = [
    { name: "Assignment", value: "Assignment" },
    { name: "Fees details", value: "Fees details" },
];

const DocumentAdd = () => {
    const { user } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [document, setDocument] = useState<any>();
    const [formError, setFormError] = useState<any>({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        documentType: "",
        documentTitle: "",
    });
    const { documentType, documentTitle } = state;
    const userinformation = useSelector((state: any) => state.userInfo);

    useEffect(() => {
        const breadcrumb = [
            { path: `/document_upload/${user}`, name: "Document upload" },
            { path: `/document_add/${user}`, name: "Document add" },
        ];
        dispatch(update_breadcrumb(breadcrumb));
    }, []);
    useEffect(() => {
        if (Object.keys(formError).length === 0 && isSubmit) {
            OnUpload();
        }
    }, [formError]);

    const uploadImage = (e: any) => {
        setDocument(e.target.files[0]);
        if (!e.target.files[0]) {
            setFormError({
                ...formError,
                document: `${Capitalization(e.target.name)} required!`,
            });
        } else {
            setFormError({ ...formError, document: undefined });
        }
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        setState({ ...state, [e.target.name]: e.target.value });
        if (!e.target.value) {
            setFormError({
                ...formError,
                [e.target.name]: `${Capitalization(e.target.name)} required!`,
            });
        } else {
            setFormError({ ...formError, [e.target.name]: undefined });
        }
    };

    const handleSubmit = () => {
        setFormError(validate());
        setIsSubmit(true);
    };

    const validate = () => {
        const error: any = {};
        if (!documentTitle) {
            error.documentTitle = `${Capitalization("document title")} required!`;
        }
        if (!documentType) {
            error.documentType = `${Capitalization("document type")} required!`;
        }
        if (!document) {
            error.document = `${Capitalization("document")} required!`;
        }
        return error;
    };

    const OnUpload = () => {
        setIsLoading(true);
        const formData = new FormData();
        const docUpload: any = {
            userId: userinformation.userId,
            userType: user === "student" ? "Student" : "Guide",
            createdBy: userinformation.userName,
            docType: documentType,
            docTitle: documentTitle,
        };
        formData.append("file", document);
        formData.append(`docUpload`, JSON.stringify(docUpload));
        axios
            .post(`${baseURL}/Students/docUploadStuden`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${userinformation.accessToken}`,
                    charset: "utf-8",
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    const obj = {
                        value: true,
                        message: "Document uploaded successfully!",
                        type: snackBarColors.success,
                    }
                    dispatch(updateSnackbar(obj));
                    navigate(`/document_upload/${user}`);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };
    return (
        <>
            {isLoading ? <ActivityIndicator /> : null}
            <div className="bg-white shadow-lg p-4">
                <div className="font-semibold text-[24px] text-sky-700">
                    Document upload here
                </div>

                <div className="bg-[gold] h-1 mt-1"></div>
                <div className="font-semibold text-lg">
                    Please upload document here.
                </div>
                <div>
                    <Input
                        lable="Document Title"
                        name="documentTitle"
                        value={documentTitle}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                        }
                    />
                    <div className="text-[red] text-sm">{formError?.documentTitle}</div>

                    <Dropdown
                        lable="documentType"
                        value={documentType}
                        name="documentType"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                        }
                        options={user === "guide" ? guideDoctype : studentDoctype}
                    />
                    <div className="text-[red] text-sm">{formError?.documentType}</div>

                    <div className="text-sm mb-2 text-sky-700">Selct Document</div>
                    <input type="file" onChange={(e: any) => uploadImage(e)} />
                    <div className="text-[red] text-sm">{formError?.document}</div>

                    <div className="flex items-center space-x-6  mt-5">
                        <Button
                            lable="upload"
                            type="button"
                            buttonType="primary"
                            onClick={() => handleSubmit()}
                        />
                        <Button
                            lable="cancel"
                            type="button"
                            buttonType="danger"
                            onClick={() => navigate(`/document_upload/${user}`)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DocumentAdd;
