export const Capitalization = (arg: string) => {
    return arg.charAt(0).toUpperCase() + arg.slice(1)

}

export const endPointGenerator = (arg: any, userId: any, userRole: string) => {
    if (arg === "dummy") {
        return userRole === "Admin"
            ? `/Admin/GetBooks`
            : `/Guide/GetBooks?userId=${userId}`
    }
    if (arg === "Books") {
        return userRole === "Admin"
            ? `/Admin/GetBooks`
            : `/Guide/GetBooks?userId=${userId}`
    }
    if (arg === "Journal") {
        return userRole === "Admin"
            ? `/Admin/GetJournal`
            : `/Guide/GetJournal?userId=${userId}`
    }
    if (arg === "Conference") {
        return userRole === "Admin"
            ? `/Admin/GetConference`
            : `/Guide/GetConference?userId=${userId}`
    }
    if (arg === "Copyright") {
        return userRole === "Admin"
            ? `/Admin/Getcopyright`
            : `/Guide/Getcopyright?userId=${userId}`
    }
    if (arg === "Patent") {
        return userRole === "Admin"
            ? `/Admin/GetPatent`
            : `/Guide/GetPatent?userId=${userId}`
    }

    return arg

}

