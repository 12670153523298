import React, { useEffect } from "react";
import { update_breadcrumb } from "../../../redux/action";
import { useDispatch } from "react-redux";

const GuideNotification = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const breadcrumb = [
      { path: "/student/student_notification", name: "Notification" },
    ];
    dispatch(update_breadcrumb(breadcrumb));
  }, []);
  return (
    <div className="bg-white shadow-lg p-4">
      <div className="flex flex-col py-20 items-center justify-center">
        <div className={`my-4 text-2xl font-semibold text-[#02497f]`}>
          {"Sorry! This page is under construction!"}
        </div>
      </div>
    </div>
  );
};

export default GuideNotification;
