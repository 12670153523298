import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import { snackBarColors } from "../../../config/constants";
import { updateSnackbar } from "../../../redux/action";
import TextArea from "../../../components/TextArea/TextArea";
const GuideDetailsForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const guideID = queryParameters.get('guideId');
  const role = useSelector((state: any) => state.userRole);
  const userinformation = useSelector((state: any) => state.userInfo);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [state, setState] = useState<any>({
    guideId: null,
    name: "",
    qualification: "",
    address: "",
    pan: "",
    aadhaar: "",
    doj: "",
    dor: "",
    numberOfpublications: null,
    yearOfaward: "",
    googleScholarId: "",
    vidwanId: "",
    mobileNumber: "",
    totalExperience: null,
    orchidId: "",
    areaOfresearch: "",
    emailId: "",
    candId: role === "Guide" ? userinformation.userId : guideID,
  });
  const {
    guideId,
    name,
    qualification,
    address,
    pan,
    aadhaar,
    doj,
    dor,
    numberOfpublications,
    yearOfaward,
    googleScholarId,
    vidwanId,
    mobileNumber,
    totalExperience,
    orchidId,
    areaOfresearch,
    emailId,
    candId,
  } = state;

  const validateGuideForm = () => {
    const error: any = {};
    if (!aadhaar) {
      error.aadhaar = "Please enter Aadhaar";
    }
    if (!address) {
      error.address = "Please enter Address";
    }
    if (!doj) {
      error.doj = "Please enter Date of Joining";
    }
    if (!dor) {
      error.DOR = "Please enter Date of Recognition";
    }
    if (!name) {
      error.name = "Please enter Name";
    }
    if (!pan) {
      error.pan = "Please enter PAN";
    }
    if (!qualification) {
      error.qualification = "Please enter Qualification";
    }
    if (!areaOfresearch) {
      error.areaOfresearch = "Please enter areaOfresearch";
    }
    if (!emailId) {
      error.emailId = "Please enter emailId";
    }
    if (!googleScholarId) {
      error.googleScholarId = "Please enter googleScholarId";
    }
    if (!mobileNumber) {
      error.mobileNumber = "Please enter mobileNumber";
    }
    if (!numberOfpublications) {
      error.numberOfpublications = "Please enter numberOfpublications";
    }
    if (!orchidId) {
      error.orchidId = "Please enter orchidId";
    }
    if (!totalExperience) {
      error.totalExperience = "Please enter totalExperience";
    }
    if (!vidwanId) {
      error.vidwanId = "Please enter vidwanId";
    }
    if (!yearOfaward) {
      error.yearOfaward = "Please enter yearOfaward";
    }
    return error;
  };
  const handleChangeText = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    setFormErrors(validateGuideForm());
    setIsSubmit(true);
  };
  useEffect(() => {
    if (action === "edit") {
      GetGuideDetails();
    } else {
      profileInformation();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (action === "edit") {
        guideDetailsUpdate();
      } else {
        guideDetailsSubmit();
      }
    }
  }, [formErrors]);

  const profileInformation = async () => {
    setIsLoading(true);
    await instance
      .post("/account/GetUserProfile", { ID: role === "Guide" ? userinformation.userId : guideID })
      .then((response) => {
        setState({
          ...state,
          name: response.data.userName,
          emailId: response.data.emailId,
          mobileNumber: response.data.phoneNumber,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const guideDetailsSubmit = async () => {
    setIsLoading(true);
    await instance
      .post(`/Guide/GuideDetails`, state)
      .then((response: any) => {
        if (response.status === 200) {
          const obj = {
            value: true,
            message: "Profile details added successfully!",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj))
          setIsLoading(false);
          navigate("/guide/guide_profile");
        }
      })
      .catch((err: any) => {
        const obj = {
          value: true,
          message: "Something went wrong!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj))
        setIsLoading(false);
      });
  };
  const GetGuideDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Guide/GetGuideDetails?UserId=${role === "Guide" ? userinformation.userId : guideID}`)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const guideDetailsUpdate = async () => {
    setIsLoading(true);

    await instance
      .post(
        `/Guide/GuideDetailsUpdated?UserId=${role === "Guide" ? userinformation.userId : guideID}`,
        state
      )
      .then((response) => {
        if (response.status === 200) {
          const obj = {
            value: true,
            message: "Profile details updated successfully!",
            type: snackBarColors.success,
          }
          dispatch(updateSnackbar(obj))
          setIsLoading(false);
          navigate(`/guide/guide_profile?guideId=${guideID}`);

        }
      })
      .catch((err) => {
        const obj = {
          value: true,
          message: "Something went Wrong!",
          type: snackBarColors.error,
        }
        dispatch(updateSnackbar(obj))
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading ? (
        <ActivityIndicator />
      ) : null}
      <div className="bg-white shadow-lg p-4">
        <div className="px-1">
          <div className="font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
            Guide Details
          </div>
        </div>
        <div className="my-1 border-b p-2">
          <div className="font-semibold text-lg">Guide Details</div>
        </div>
        <div className="flex justify-center ju px-2">
          <div className="sm:flex sm:space-x-10   ">
            <div className="w-full">
              <div className="my-1">
                <Input

                  lable="Name"
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChangeText}
                  error={formErrors.name}
                />
              </div>
              <div className="my-1 ">
                <Input
                  lable="PAN"
                  type="text"
                  name="pan"
                  value={pan}
                  onChange={handleChangeText}
                  error={formErrors.pan}
                />
              </div>
              <div className="my-1 ">
                <Input
                  lable="Aadhaar Number"
                  type="text"
                  name="aadhaar"
                  value={aadhaar}
                  onChange={handleChangeText}
                  error={formErrors.aadhaar}
                />
              </div>
              <div className="my-1">
                <TextArea
                  lable="Address"
                  type="text"
                  name="address"
                  value={address}
                  onChange={handleChangeText}
                  error={formErrors.address}
                />

              </div>

              <div className="my-1">
                <Input
                  lable="Qualification"
                  type="text"
                  name="qualification"
                  value={qualification}
                  onChange={handleChangeText}
                  error={formErrors.qualification}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Email"
                  type="text"
                  name="emailId"
                  value={emailId}
                  onChange={handleChangeText}
                  error={formErrors.emailId}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Date of Joining"
                  type="date"
                  name="doj"
                  value={doj.toString().substring(0, 10)}
                  onChange={handleChangeText}
                  error={formErrors.doj}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Date of Recognition"
                  type="date"
                  name="dor"
                  value={dor.toString().substring(0, 10)}
                  onChange={handleChangeText}
                  error={formErrors.dor}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="my-1">
                <Input
                  lable="Area of Reasearch"
                  type="text"
                  name="areaOfresearch"
                  value={areaOfresearch}
                  onChange={handleChangeText}
                  error={formErrors.areaOfresearch}
                />
              </div>

              <div className="my-1">
                <Input
                  lable="Google Scholar Id"
                  type="text"
                  name="googleScholarId"
                  value={googleScholarId}
                  onChange={handleChangeText}
                  error={formErrors.googleScholarId}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Mobile number"
                  type="text"
                  name="mobileNumber"
                  value={mobileNumber}
                  onChange={handleChangeText}
                  error={formErrors.mobileNumber}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Number of Publication"
                  type="text"
                  name="numberOfpublications"
                  value={numberOfpublications}
                  onChange={handleChangeText}
                  error={formErrors.numberOfpublications}
                />
              </div>

              <div className="my-1">
                <Input
                  lable="Orchid Id"
                  type="text"
                  name="orchidId"
                  value={orchidId}
                  onChange={handleChangeText}
                  error={formErrors.orchidId}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Total Experience"
                  type="text"
                  name="totalExperience"
                  value={totalExperience}
                  onChange={handleChangeText}
                  error={formErrors.totalExperience}
                />
              </div>

              <div className="my-1">
                <Input
                  lable="Vidwan Id"
                  type="text"
                  name="vidwanId"
                  value={vidwanId}
                  onChange={handleChangeText}
                  error={formErrors.vidwanId}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Year of Award"
                  type="text"
                  name="yearOfaward"
                  value={yearOfaward}
                  onChange={handleChangeText}
                  error={formErrors.yearOfaward}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center space-x-4 px-2 mt-5">
          <Button
            lable={action === "edit" ? "update" : "submit"}
            type="button"
            buttonType="primary"
            onClick={() => handleSubmit()}
          />
          <Button
            lable="cancel"
            type="button"
            buttonType="danger"
            onClick={() => navigate("/guide/guide_profile")}
          />
        </div>
      </div>

    </>
  );
};

export default GuideDetailsForm;
