import React from "react";
import "./activityIndicator.css";
import {

  Oval,

} from "react-loading-icons";

const ActivityIndicator = () => {
  return (
    <div className="flex items-center justify-center backdrop">
      <Oval />
    </div>
  );
};

export default ActivityIndicator;
