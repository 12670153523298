import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import instance from "../../../service/axiosInstane";
import { update_breadcrumb } from "../../../redux/action";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";

const StudentNotificationDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<any>(true);
    const userinformation = useSelector((state: any) => state.userInfo);

    useEffect(() => {
        console.log(id, "id");
        const breadcrumb = [
            { path: "/student/student_notification", name: "Notification" },
            { path: `student_notification_details/${id}`, name: "Notification Details" },
        ];
        dispatch(update_breadcrumb(breadcrumb));
        getNotification();
    }, []);
    const getNotification = async () => {
        setIsLoading(true);
        await instance
            .get(`/Admin/GetNotification?UserId=${userinformation.userId}`)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data[`${id}`].body);
                    setState(response.data);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };
    return (
        <>
            {isLoading ? <ActivityIndicator /> : null}
            <div className="bg-white shadow-lg p-4">
                <div className="flex items-center justify-between font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
                    <div>Notification Details</div>
                </div>

                <div className="p-1 my-1 cursor-pointer">
                    <div className="font-semibold text-blue-900 text-lg">
                        {state[`${id}`]?.subject}
                    </div>
                    <div className="text-[15px] font-sans ">{state[`${id}`]?.body}</div>
                </div>
            </div>
        </>
    );
};

export default StudentNotificationDetails;
