
const ProgressBar = (props: any) => {
    const { tab } = props
    return (
        <div className="flex items-center justify-between">
            <div className={`bg-[#02497f] w-[80px] h-6 rounded-full `}></div>

            <div
                className={`${tab === 2 || tab === 3 || tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
                    } w-full h-1  `}
            ></div>
            <div
                className={`${tab === 2 || tab === 3 || tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
                    } w-[80px] h-6 rounded-full `}
            ></div>

            <div
                className={`${tab === 3 || tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
                    } w-full h-1  `}
            ></div>
            <div
                className={`${tab === 3 || tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
                    } w-[80px] h-6 rounded-full `}
            ></div>

            <div
                className={`${tab === 4 ? "bg-[#02497f]" : "bg-[gold]"} w-full h-1  `}
            ></div>

            <div
                className={`${tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
                    }  w-[80px] h-6 rounded-full `}
            ></div>
        </div>
    );
};

export default ProgressBar;

